import { faICursor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddOutlined } from '@mui/icons-material'
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import HeightIcon from '@mui/icons-material/Height'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  Theme,
} from '@mui/material'
import { Box } from '@mui/system'
import clsx from 'clsx'
import _ from 'lodash'
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks'
import { useCallback, useMemo } from 'react'
import 'react-resizable/css/styles.css'
import {
  FormTemplateAnnotationImageType,
  FormTemplateAnnotationMetadataFieldType,
  SignatureAnnotationType,
  isRestrictedKey,
  templateVariablesDocumentation,
} from 'siteline-common-all'
import { SitelineText, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { IdentifierButton } from '../../../common/components/IdentifierRow'
import {
  AnnotationOverrideProperties,
  FormTemplateAnnotationProperties,
  FormTemplateAnnotationType,
  FormTemplateProperties,
  UpdateFormAnnotationInput,
} from '../../../common/graphql/apollo-operations'
import { AnnotationWithOverrides, applyOverride } from '../../../common/util/FormTemplate'
import { AnnotationOverride } from './AnnotationOverride'
import {
  ColorField,
  CopyDefaultValueField,
  DoNotRetainField,
  DynamicTagField,
  FieldTypeField,
  FontFamilyField,
  GeometryField,
  ImageTypeField,
  IsOptionalField,
  PrefixField,
  SignatureTypeField,
  SuffixField,
  SyncTagField,
  TemplateVariableAutocomplete,
  TextAlignmentField,
  UpdateSingleValueFunc,
  UserVisibleNameField,
  WrapTextField,
} from './FormTemplateAnnotationFields'
import { AnnotationGeometry } from './FormTemplateAnnotationLayer'
import { TemplateVariantSelector } from './TemplateVariantSelector'

const useStyles = makeStylesFast((theme: Theme) => ({
  fieldGroup: {
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  actions: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  removeTopSpacing: {
    paddingTop: '0 !important',
  },
  identifier: {
    marginBottom: theme.spacing(1),
  },
  batchAlignButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&.withUserEnteredFieldOptions': {
      marginTop: theme.spacing(2),
    },
  },
  variantSelector: {
    width: '100%',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'block',
  },
}))

type FormTemplateAnnotationEditorProps = {
  formTemplate: FormTemplateProperties
  annotations: FormTemplateAnnotationProperties[]
  override: AnnotationOverrideProperties | null
  isDefaultVariant: boolean
  variantId: string
  onVariantIdChange: (variantId: string) => void
  onUpdate: (inputs: UpdateFormAnnotationInput[]) => void
  onClone: (annotationIds: string[]) => void
  onDelete: (annotations: FormTemplateAnnotationProperties[]) => void
  onMatchGeometry: (matchKey: keyof AnnotationGeometry) => void
  templateVariables: (typeof templateVariablesDocumentation)[keyof typeof templateVariablesDocumentation]
  isDynamic?: boolean
}

export function FormTemplateAnnotationEditor({
  formTemplate: template,
  annotations,
  override,
  isDefaultVariant,
  onUpdate,
  onClone,
  onDelete,
  onMatchGeometry,
  templateVariables,
  isDynamic = false,
  variantId,
  onVariantIdChange,
}: FormTemplateAnnotationEditorProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const popupState = usePopupState({ variant: 'popover', popupId: 'addOverride' })

  const updateSingleValue: UpdateSingleValueFunc = useCallback(
    (field, value) => {
      if (annotations.length === 0) {
        return
      }
      onUpdate(
        annotations.map((annotation) => ({
          id: annotation.id,
          [field]: value,
        }))
      )
    },
    [annotations, onUpdate]
  )

  const convertToUserEnteredField = useCallback(() => {
    if (annotations.length !== 1) {
      return
    }
    const annotation = annotations[0]
    if (!window.confirm('Are you sure you want to convert this annotation?')) {
      return
    }
    onUpdate([
      {
        id: annotation.id,
        type: FormTemplateAnnotationType.USER_ENTERED_FIELD,
        selectedKey: null,
        prefix: null,
        suffix: null,
        defaultValueKey: annotation.selectedKey,
      },
    ])
  }, [annotations, onUpdate])

  const convertToTemplateVariable = useCallback(() => {
    if (annotations.length !== 1) {
      return
    }
    const annotation = annotations[0]
    if (!window.confirm('Are you sure you want to convert this annotation?')) {
      return
    }

    const defaultKey = 'project.name'

    // Make sure selected key can be used on template variable, reset to `project.name` if not
    let selectedKey = annotation.defaultValueKey ?? defaultKey
    if (selectedKey && isRestrictedKey(selectedKey, template.type)) {
      snackbar.showInfo(
        `'${selectedKey}' cannot be used on a template variable, changed to '${defaultKey}'`
      )
      selectedKey = defaultKey
    }

    onUpdate([
      {
        id: annotation.id,
        type: FormTemplateAnnotationType.TEMPLATE_VARIABLE,
        userVisibleName: selectedKey,
        selectedKey,
        defaultValueKey: null,
        fieldType: null,
        doNotRetainOnReset: false,
        isOptional: false,
        copyDefaultValueFromPreviousAnnotationValue: false,
      },
    ])
  }, [annotations, template.type, onUpdate, snackbar])

  const allAnnotationsType = useMemo(() => {
    const annotationTypes = _.uniq(annotations.map((annotation) => annotation.type))
    return annotationTypes.length === 1 ? annotationTypes[0] : undefined
  }, [annotations])

  const title = useMemo(() => {
    switch (allAnnotationsType) {
      case FormTemplateAnnotationType.TEMPLATE_VARIABLE:
        return annotations.length > 1
          ? `${annotations.length} template variables`
          : 'Template variable'
      case FormTemplateAnnotationType.USER_ENTERED_FIELD:
        return annotations.length > 1
          ? `${annotations.length} user-entered fields`
          : 'User-entered field'
      case FormTemplateAnnotationType.SIGNATURE:
        return annotations.length > 1 ? `${annotations.length} signatures` : 'Signature'
      case FormTemplateAnnotationType.IMAGE:
        return annotations.length > 1 ? `${annotations.length} images` : 'Image'
      case undefined:
        return annotations.length > 1 ? `${annotations.length} annotations` : 'Annotation'
    }
  }, [allAnnotationsType, annotations.length])

  const fieldProps = useCallback(
    (annotations: (FormTemplateAnnotationProperties | AnnotationWithOverrides)[]) => ({
      templateType: template.type,
      annotations,
      updateSingleValue,
      templateVariables,
      onUpdate,
    }),
    [template.type, onUpdate, templateVariables, updateSingleValue]
  )
  const signatureTypes = [FormTemplateAnnotationType.SIGNATURE]

  // Most fields are only editable if a single annotation is selected
  const singleAnnotation = annotations.length === 1 ? annotations[0] : undefined
  const singleAnnotationWithOverride = useMemo(() => {
    const annotation = singleAnnotation
    if (!annotation || !override) {
      return null
    }
    return applyOverride(annotation, override)
  }, [override, singleAnnotation])

  const showUserEnteredFieldOptions =
    annotations.length > 0 && allAnnotationsType === FormTemplateAnnotationType.USER_ENTERED_FIELD

  const hasOverride = useCallback(
    (field: keyof Omit<AnnotationOverrideProperties, 'id' | 'annotationPermanentId'>) => {
      return !_.isNil(override?.[field])
    },
    [override]
  )

  type AvailableOverride = {
    name: string
    key: keyof Omit<AnnotationOverrideProperties, 'id' | 'annotationPermanentId'>
    onClick: () => void
  }
  const availableOverrides = useMemo((): AvailableOverride[] => {
    const annotation = singleAnnotationWithOverride ?? singleAnnotation
    if (!annotation) {
      return []
    }
    const available: AvailableOverride[] = []

    if (annotation.type === FormTemplateAnnotationType.USER_ENTERED_FIELD) {
      available.push({
        key: 'userVisibleName',
        name: 'User visible name',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              userVisibleName: 'User visible name',
            },
          ]),
      })
      available.push({
        key: 'fieldType',
        name: 'Field type',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              fieldType: FormTemplateAnnotationMetadataFieldType.LIEN_WAIVER_AMOUNT,
            },
          ]),
      })
      available.push({
        key: 'isOptional',
        name: 'Optional',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              isOptional: annotation.isOptional,
            },
          ]),
      })
      available.push({
        key: 'copyDefaultValueFromPreviousAnnotationValue',
        name: 'Copy value from previous months',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              copyDefaultValueFromPreviousAnnotationValue:
                annotation.copyDefaultValueFromPreviousAnnotationValue,
            },
          ]),
      })
      available.push({
        key: 'defaultValueKey',
        name: 'Default value (template variable)',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              // Purposefully using || to have a fallback for empty strings as well
              defaultValueKey: annotation.defaultValueKey || 'project.name',
            },
          ]),
      })
      available.push({
        key: 'doNotRetainOnReset',
        name: 'Do not retain when resetting',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              doNotRetainOnReset: annotation.doNotRetainOnReset,
            },
          ]),
      })
      available.push({
        key: 'fontFamily',
        name: 'Font family',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              fontFamily: annotation.fontFamily,
            },
          ]),
      })
      available.push({
        key: 'textAlignment',
        name: 'Text alignment',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              textAlignment: annotation.textAlignment,
            },
          ]),
      })
      available.push({
        key: 'wrapText',
        name: 'Wrap text if too long',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              wrapText: annotation.wrapText,
            },
          ]),
      })
      available.push({
        key: 'fontColor',
        name: 'Text color',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              fontColor: annotation.fontColor,
            },
          ]),
      })
      available.push({
        key: 'syncTag',
        name: 'Sync tag',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              // Purposefully using || to have a fallback for empty strings as well
              syncTag: annotation.syncTag || 'syncTag',
            },
          ]),
      })
    }

    if (annotation.type === FormTemplateAnnotationType.TEMPLATE_VARIABLE) {
      available.push({
        key: 'selectedKey',
        name: 'Variable path',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              // Purposefully using || to have a fallback for empty strings as well
              selectedKey: annotation.selectedKey || 'project.name',
            },
          ]),
      })
      available.push({
        key: 'prefix',
        name: 'Prefix',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              // Purposefully using || to have a fallback for empty strings as well
              prefix: annotation.prefix || 'Prefix',
            },
          ]),
      })
      available.push({
        key: 'suffix',
        name: 'Suffix',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              // Purposefully using || to have a fallback for empty strings as well
              suffix: annotation.suffix || 'Suffix',
            },
          ]),
      })
      available.push({
        key: 'fontFamily',
        name: 'Font family',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              fontFamily: annotation.fontFamily,
            },
          ]),
      })
      available.push({
        key: 'textAlignment',
        name: 'Text alignment',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              textAlignment: annotation.textAlignment,
            },
          ]),
      })
      available.push({
        key: 'wrapText',
        name: 'Wrap text if too long',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              wrapText: annotation.wrapText,
            },
          ]),
      })
      available.push({
        key: 'fontColor',
        name: 'Text color',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              fontColor: annotation.fontColor,
            },
          ]),
      })
    }

    if (annotation.type === FormTemplateAnnotationType.IMAGE) {
      available.push({
        key: 'imageType',
        name: 'Image type',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              imageType: annotation.imageType ?? FormTemplateAnnotationImageType.SUBCONTRACTOR_LOGO,
            },
          ]),
      })
    }

    if (annotation.type === FormTemplateAnnotationType.SIGNATURE) {
      available.push({
        key: 'signatureType',
        name: 'Signature type',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              signatureType: annotation.signatureType ?? SignatureAnnotationType.DIGITAL,
            },
          ]),
      })
      available.push({
        key: 'textAlignment',
        name: 'Text alignment',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              textAlignment: annotation.textAlignment,
            },
          ]),
      })
      available.push({
        key: 'wrapText',
        name: 'Wrap text if too long',
        onClick: () =>
          onUpdate([
            {
              id: annotation.id,
              wrapText: annotation.wrapText,
            },
          ]),
      })

      if (annotation.signatureType === SignatureAnnotationType.DIGITAL) {
        available.push({
          key: 'isOptional',
          name: 'Optional',
          onClick: () =>
            onUpdate([
              {
                id: annotation.id,
                isOptional: annotation.isOptional,
              },
            ]),
        })
        available.push({
          key: 'fontColor',
          name: 'Text color',
          onClick: () =>
            onUpdate([
              {
                id: annotation.id,
                fontColor: annotation.fontColor,
              },
            ]),
        })
      }
    }

    return _.chain(available)
      .filter((override) => !hasOverride(override.key))
      .orderBy((override) => override.name)
      .value()
  }, [hasOverride, onUpdate, singleAnnotation, singleAnnotationWithOverride])

  const handleAvailableOverride = useCallback(
    (annotation: AvailableOverride) => {
      annotation.onClick()
      popupState.close()
    },
    [popupState]
  )

  const showColorPicker = useMemo(() => {
    if (!allAnnotationsType) {
      return false
    }
    switch (allAnnotationsType) {
      case FormTemplateAnnotationType.TEMPLATE_VARIABLE:
      case FormTemplateAnnotationType.USER_ENTERED_FIELD:
        return true
      case FormTemplateAnnotationType.SIGNATURE:
        return annotations.every((a) => a.signatureType === SignatureAnnotationType.DIGITAL)
      case FormTemplateAnnotationType.IMAGE:
        return false
    }
  }, [allAnnotationsType, annotations])

  return (
    <Card>
      <CardContent>
        <TemplateVariantSelector
          template={template}
          variantId={variantId}
          onVariantIdChange={onVariantIdChange}
          className={classes.variantSelector}
        />
        <SitelineText variant="h5" className={classes.title}>
          {title}
        </SitelineText>
        {annotations.length === 0 && <Alert severity="info">Select an annotation to edit</Alert>}

        {isDefaultVariant && (
          <>
            {singleAnnotation && isDynamic && (
              <DynamicTagField {...fieldProps([singleAnnotation])} />
            )}

            {/* User-entered field */}
            {showUserEnteredFieldOptions && (
              <div className={classes.fieldGroup}>
                <>
                  {singleAnnotation && (
                    <>
                      <UserVisibleNameField {...fieldProps([singleAnnotation])} />
                      <FieldTypeField {...fieldProps([singleAnnotation])} />
                    </>
                  )}
                  <IsOptionalField {...fieldProps(annotations)} />
                  <CopyDefaultValueField {...fieldProps(annotations)} />
                  {singleAnnotation && (
                    <>
                      <TemplateVariableAutocomplete
                        {...fieldProps([singleAnnotation])}
                        label="Default value (template variable)"
                        field="defaultValueKey"
                        updateUserVisibleName={false}
                        removeWhenNull
                      />
                      {singleAnnotation.defaultValueKey && (
                        <DoNotRetainField {...fieldProps([singleAnnotation])} />
                      )}
                      <Box sx={{ marginTop: 2 }}>
                        <SyncTagField {...fieldProps([singleAnnotation])} />
                      </Box>
                    </>
                  )}
                </>
              </div>
            )}

            {/* Template variable */}
            {singleAnnotation &&
              singleAnnotation.type === FormTemplateAnnotationType.TEMPLATE_VARIABLE && (
                <div className={classes.fieldGroup}>
                  <TemplateVariableAutocomplete
                    {...fieldProps([singleAnnotation])}
                    label="Variable path"
                    field="selectedKey"
                    updateUserVisibleName
                    removeWhenNull={false}
                  />

                  <Grid container className={classes.fieldGroup} spacing={2}>
                    <Grid item xs={6} className={classes.removeTopSpacing}>
                      <PrefixField {...fieldProps([singleAnnotation])} />
                    </Grid>

                    <Grid item xs={6} className={classes.removeTopSpacing}>
                      <SuffixField {...fieldProps([singleAnnotation])} />
                    </Grid>
                  </Grid>
                </div>
              )}

            {/* Image */}
            {singleAnnotation && singleAnnotation.type === FormTemplateAnnotationType.IMAGE && (
              <div className={classes.fieldGroup}>
                <ImageTypeField {...fieldProps([singleAnnotation])} />
              </div>
            )}

            {/* Font family */}
            {allAnnotationsType !== undefined &&
              !signatureTypes.includes(allAnnotationsType) &&
              allAnnotationsType !== FormTemplateAnnotationType.IMAGE && (
                <FontFamilyField {...fieldProps(annotations)} />
              )}

            {/* Signature type */}
            {singleAnnotation && signatureTypes.includes(singleAnnotation.type) && (
              <div className={classes.fieldGroup}>
                <SignatureTypeField {...fieldProps([singleAnnotation])} />
              </div>
            )}

            {/* Optional signature */}
            {singleAnnotation &&
              singleAnnotation.signatureType === SignatureAnnotationType.DIGITAL && (
                <div className={classes.fieldGroup}>
                  <IsOptionalField {...fieldProps([singleAnnotation])} />
                </div>
              )}

            {/* Text alignment, wrap text */}
            {allAnnotationsType !== undefined &&
              allAnnotationsType !== FormTemplateAnnotationType.IMAGE && (
                <Grid container className={classes.fieldGroup} spacing={2}>
                  <Grid item className={classes.removeTopSpacing}>
                    <TextAlignmentField {...fieldProps(annotations)} />
                  </Grid>

                  <Grid item className={classes.removeTopSpacing}>
                    <WrapTextField {...fieldProps(annotations)} />
                  </Grid>
                </Grid>
              )}

            {/* Positioning */}
            {annotations.length > 0 && (
              <Grid container className={classes.fieldGroup} spacing={1}>
                {!isDynamic && (
                  <>
                    <Grid item xs className={classes.removeTopSpacing}>
                      <GeometryField label="X" field="xStart" {...fieldProps(annotations)} />
                    </Grid>
                    <Grid item xs className={classes.removeTopSpacing}>
                      <GeometryField label="Y" field="yStart" {...fieldProps(annotations)} />
                    </Grid>
                  </>
                )}
                <Grid item xs className={classes.removeTopSpacing}>
                  <GeometryField label="Width" field="width" {...fieldProps(annotations)} />
                </Grid>
                <Grid item xs className={classes.removeTopSpacing}>
                  <GeometryField label="Height" field="height" {...fieldProps(annotations)} />
                </Grid>
              </Grid>
            )}

            {/* Color picker */}
            {showColorPicker && (
              <div className={classes.fieldGroup}>
                <ColorField {...fieldProps(annotations)} />
              </div>
            )}
            {annotations.length > 1 && (
              <div
                className={clsx(classes.batchAlignButtons, {
                  withUserEnteredFieldOptions: showUserEnteredFieldOptions,
                })}
              >
                <Button
                  color="secondary"
                  startIcon={<AlignHorizontalLeftIcon />}
                  size="small"
                  onClick={() => onMatchGeometry('xStart')}
                >
                  Align left (shift+l)
                </Button>
                <Button
                  color="secondary"
                  startIcon={<AlignVerticalTopIcon />}
                  size="small"
                  onClick={() => onMatchGeometry('yStart')}
                >
                  Align top (shift+t)
                </Button>
                <Button
                  color="secondary"
                  startIcon={<HeightIcon style={{ transform: 'rotate(90deg)' }} />}
                  size="small"
                  onClick={() => onMatchGeometry('width')}
                >
                  Match widths (shift+w)
                </Button>
                <Button
                  color="secondary"
                  startIcon={<HeightIcon />}
                  size="small"
                  onClick={() => onMatchGeometry('height')}
                >
                  Match heights (shift+h)
                </Button>
              </div>
            )}
          </>
        )}
        {!isDefaultVariant && annotations.length > 1 && (
          <Alert severity="info">Only 1 annotation can be selected when updating a variant</Alert>
        )}
        {!isDefaultVariant && singleAnnotation && (
          <>
            <Button startIcon={<AddOutlined />} {...bindToggle(popupState)}>
              Add override
            </Button>
            <Menu {...bindMenu(popupState)}>
              {availableOverrides.map((override) => (
                <MenuItem key={override.name} onClick={() => handleAvailableOverride(override)}>
                  {override.name}
                </MenuItem>
              ))}
              {availableOverrides.length === 0 && (
                <MenuItem disabled>No more overrides available</MenuItem>
              )}
            </Menu>
          </>
        )}
        {!isDefaultVariant && override && singleAnnotation && singleAnnotationWithOverride && (
          <>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="userVisibleName"
            >
              <UserVisibleNameField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="fieldType"
            >
              <FieldTypeField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="isOptional"
            >
              <IsOptionalField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="copyDefaultValueFromPreviousAnnotationValue"
            >
              <CopyDefaultValueField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="defaultValueKey"
            >
              <TemplateVariableAutocomplete
                {...fieldProps([singleAnnotationWithOverride])}
                label="Default value (template variable)"
                field="defaultValueKey"
                updateUserVisibleName={false}
                removeWhenNull
              />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="doNotRetainOnReset"
            >
              <DoNotRetainField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="selectedKey"
            >
              <TemplateVariableAutocomplete
                {...fieldProps([singleAnnotationWithOverride])}
                label="Variable path"
                field="selectedKey"
                updateUserVisibleName
                removeWhenNull={false}
              />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="prefix"
            >
              <PrefixField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="suffix"
            >
              <SuffixField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="imageType"
            >
              <ImageTypeField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="fontFamily"
            >
              <FontFamilyField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="signatureType"
            >
              <SignatureTypeField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="textAlignment"
            >
              <TextAlignmentField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="wrapText"
            >
              <WrapTextField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="fontColor"
            >
              <ColorField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
            <AnnotationOverride
              annotation={singleAnnotation}
              override={override}
              onUpdate={onUpdate}
              field="syncTag"
            >
              <SyncTagField {...fieldProps([singleAnnotationWithOverride])} />
            </AnnotationOverride>
          </>
        )}
      </CardContent>
      {annotations.length > 0 && isDefaultVariant && (
        <CardActions className={classes.actions} disableSpacing>
          {singleAnnotation && (
            <>
              <IdentifierButton id={singleAnnotation.id} className={classes.identifier} />
              {singleAnnotation.type === FormTemplateAnnotationType.TEMPLATE_VARIABLE &&
                !isDynamic && (
                  <Button
                    startIcon={<FontAwesomeIcon fixedWidth icon={faICursor} />}
                    size="small"
                    onClick={() => convertToUserEnteredField()}
                  >
                    Convert to User-entered Field
                  </Button>
                )}
              {singleAnnotation.type === FormTemplateAnnotationType.USER_ENTERED_FIELD &&
                !isDynamic && (
                  <Button
                    startIcon={<TextFieldsIcon />}
                    size="small"
                    onClick={() => convertToTemplateVariable()}
                  >
                    Convert to Template Variable
                  </Button>
                )}
            </>
          )}
          <Button
            startIcon={<FileCopyIcon />}
            size="small"
            onClick={() => onClone(annotations.map((annotation) => annotation.id))}
          >
            {annotations.length > 1
              ? `Clone ${annotations.length} annotations`
              : 'Clone annotation'}
          </Button>
          <Button
            color="error"
            startIcon={<DeleteIcon />}
            size="small"
            onClick={() => onDelete(annotations)}
          >
            {annotations.length > 1
              ? `Delete ${annotations.length} annotations`
              : 'Delete annotation'}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}
