export enum AggregateLienWaiverStatus {
  /** No lien waivers have been requested */
  NONE_REQUESTED = 'NONE_REQUESTED',
  /** At least one lien waiver request is still outstanding and has not been viewed */
  REQUESTED = 'REQUESTED',
  /** All lien waiver requests have been completed, but at least one has not yet been submitted */
  SIGNED = 'SIGNED',
  /** All lien waiver requests have been submitted */
  SUBMITTED = 'SUBMITTED',
  /** All lien waiver requests have been viewed, but at least one is still outstanding */
  VIEWED = 'VIEWED',
}

/** Type of amount to use on the aging report (progress, retention, or both) */
export enum AgingAmountType {
  /** Progress billed - retention held + retention released */
  AMOUNT_DUE = 'AMOUNT_DUE',
  /** (default) Progress billed - retention held. This is the amount due for progress-only pay apps. */
  PROGRESS_MINUS_RETENTION_HELD = 'PROGRESS_MINUS_RETENTION_HELD',
  /** Retention released */
  RETENTION_RELEASED = 'RETENTION_RELEASED',
}

export enum AgingIntervalType {
  /** Aging is calculated from the billing end of the pay app */
  BILLING_END = 'BILLING_END',
  /** Aging is calculated from the date the pay app or invoice was submitted */
  DATE_SUBMITTED = 'DATE_SUBMITTED',
}

export enum AuthProviderType {
  EMAIL = 'EMAIL',
  SAML = 'SAML',
}

/** Billing type for a project, stored at the contract level. */
export enum BillingType {
  /**
   * Project has a fixed Schedule of Values (SOV).
   * Pay apps contain progress against each line item in the SOV.
   * Total value of the project is fixed and contained within the SOV.
   * Retention can be tracked at the project / pay app / standard / progress level.
   */
  LUMP_SUM = 'LUMP_SUM',
  /**
   * Each pay-app has a free-form list of amount line items.
   * Project has no defined total value.
   * There is no retention.
   */
  QUICK = 'QUICK',
  /**
   * Time and Materials (T&M) projects bill similarly to unit price
   * projects. However, while unit price projects are based on
   * predetermined total values derived from pre-negotiated unit
   * pricing, T&M projects utilize a rate table with pre-established
   * rates.
   */
  TIME_AND_MATERIALS = 'TIME_AND_MATERIALS',
  /**
   * Unit price projects bill monthly pay apps with the same workflow as lump sum projects, but bill
   * using unit quantities corresponding to pre-negotiatied unit pricing.
   */
  UNIT_PRICE = 'UNIT_PRICE',
}

export enum CacheType {
  FILES = 'FILES',
  GENERIC = 'GENERIC',
}

export enum CashForecastPeriodType {
  /** Aggregate data by month */
  MONTH = 'MONTH',
  /** Aggregate data by week */
  WEEK = 'WEEK',
}

export enum ChangeOrderRequestEventType {
  ADDED_BACKUP = 'ADDED_BACKUP',
  CHANGE_ORDER_ADDED_TO_SOV = 'CHANGE_ORDER_ADDED_TO_SOV',
  CHANGE_ORDER_APPROVED = 'CHANGE_ORDER_APPROVED',
  CHANGE_ORDER_AUTHORIZED_TO_PROCEED = 'CHANGE_ORDER_AUTHORIZED_TO_PROCEED',
  CHANGE_ORDER_CREATED = 'CHANGE_ORDER_CREATED',
  CHANGE_ORDER_IMPORTED = 'CHANGE_ORDER_IMPORTED',
  CHANGE_ORDER_REJECTED = 'CHANGE_ORDER_REJECTED',
  CHANGE_ORDER_SIGNED = 'CHANGE_ORDER_SIGNED',
  CHANGE_ORDER_SUBMITTED = 'CHANGE_ORDER_SUBMITTED',
  EDITED_AMOUNT = 'EDITED_AMOUNT',
  EDITED_GENERAL_CONTRACTOR_NUMBER = 'EDITED_GENERAL_CONTRACTOR_NUMBER',
  EDITED_INTERNAL_NOTES = 'EDITED_INTERNAL_NOTES',
  EDITED_INTERNAL_NUMBER = 'EDITED_INTERNAL_NUMBER',
  EDITED_NAME = 'EDITED_NAME',
  EDITED_REASON = 'EDITED_REASON',
  EDITED_SCHEDULE_IMPACT = 'EDITED_SCHEDULE_IMPACT',
  GROUPED_ADDED_BACKUP = 'GROUPED_ADDED_BACKUP',
  GROUPED_EDITED_AMOUNT = 'GROUPED_EDITED_AMOUNT',
  GROUPED_EDITED_GENERAL_CONTRACTOR_NUMBER = 'GROUPED_EDITED_GENERAL_CONTRACTOR_NUMBER',
  GROUPED_EDITED_INTERNAL_NOTES = 'GROUPED_EDITED_INTERNAL_NOTES',
  GROUPED_EDITED_INTERNAL_NUMBER = 'GROUPED_EDITED_INTERNAL_NUMBER',
  GROUPED_EDITED_NAME = 'GROUPED_EDITED_NAME',
  GROUPED_EDITED_REASON = 'GROUPED_EDITED_REASON',
  GROUPED_EDITED_SCHEDULE_IMPACT = 'GROUPED_EDITED_SCHEDULE_IMPACT',
  LINKED_TO_CHANGE_ORDER_REQUESTS = 'LINKED_TO_CHANGE_ORDER_REQUESTS',
  REMOVED_BACKUP = 'REMOVED_BACKUP',
  REQUESTED_REVIEW = 'REQUESTED_REVIEW',
  REVERTED_TO_DRAFT = 'REVERTED_TO_DRAFT',
  SET_INVISIBLE_TO_GC = 'SET_INVISIBLE_TO_GC',
  SET_VISIBLE_TO_GC = 'SET_VISIBLE_TO_GC',
  TOGGLED_PROCEEDING_WITH_WORK = 'TOGGLED_PROCEEDING_WITH_WORK',
  UNLINKED_FROM_CHANGE_ORDER_REQUESTS = 'UNLINKED_FROM_CHANGE_ORDER_REQUESTS',
  UPLOADED_SCO_FILE = 'UPLOADED_SCO_FILE',
}

/** Status of a change order request across its lifecycle */
export enum ChangeOrderRequestStatus {
  APPROVED = 'APPROVED',
  AUTHORIZED_TO_PROCEED = 'AUTHORIZED_TO_PROCEED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  SIGNED = 'SIGNED',
  SUBMITTED = 'SUBMITTED',
}

export enum CollectionsNotificationAnchor {
  DUE_DATE = 'DUE_DATE',
  SUBMISSION_DATE = 'SUBMISSION_DATE',
}

export enum CollectionsNotificationGranularity {
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export enum CollectionsNotificationModifier {
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
}

export enum CollectionsNotificationType {
  /** Notification that reminds the GC that a due date is coming up */
  DUE_DATE_UPCOMING = 'DUE_DATE_UPCOMING',
  /** Notification that reminds the GC that a due date has passed */
  OVERDUE = 'OVERDUE',
  /** Notification that asks the GC to confirm they received the pay app */
  SUBMISSION_ACKNOWLEDGEMENT = 'SUBMISSION_ACKNOWLEDGEMENT',
}

export enum CollectionsTaskStatus {
  /** Task is complete */
  COMPLETE = 'COMPLETE',
  /** Task is no longer needed and was closed by the system, most likely because the pay app was marked as paid */
  NOT_NEEDED = 'NOT_NEEDED',
  /** Task is open and not yet complete */
  OPEN = 'OPEN',
}

export enum CompanyListSortCriteria {
  NAME = 'NAME',
  SEARCH_RELEVANCE = 'SEARCH_RELEVANCE',
}

/**
 * Represents the role a company has on a given project. This may be different than the CompanyType
 * as some general contractors actually end up being subcontractors and some subcontractors end up
 * being sub-subcontractors, depending on the job. This also allows us to handle the case where a
 * general contractor is also self-performing (they're a general and a subcontractor).
 */
export enum CompanyProjectRole {
  ARCHITECT = 'ARCHITECT',
  GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
  OWNER_DEVELOPER = 'OWNER_DEVELOPER',
  SELF_PERFORM = 'SELF_PERFORM',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUB_SUBCONTRACTOR = 'SUB_SUBCONTRACTOR',
}

/** Represents what type of company you are. */
export enum CompanyType {
  ARCHITECT = 'ARCHITECT',
  GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
  LENDER = 'LENDER',
  OWNER_DEVELOPER = 'OWNER_DEVELOPER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
}

/** Represents the various roles that could exist at a company. */
export enum CompanyUserRole {
  /** Users with an accountant role see external project due dates by default */
  ACCOUNTANT = 'ACCOUNTANT',
  /** An administrator has all permissions enabled and access to all company projects */
  ADMINISTRATOR = 'ADMINISTRATOR',
  /** A field worker is a guest user that only has permissions to view and update certain fields of the invoice */
  FIELD_GUEST = 'FIELD_GUEST',
  /** Project managers by default see internal project due dates, earlier than GC due dates */
  PROJECT_MANAGER = 'PROJECT_MANAGER',
}

export enum ComplianceStatus {
  COMPLIANT = 'COMPLIANT',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
}

export enum ContractCommentType {
  AGING = 'AGING',
  BILLING = 'BILLING',
  CASH_FORECASTING = 'CASH_FORECASTING',
}

export enum ContractEventType {
  ADDED_CHANGE_ORDER = 'ADDED_CHANGE_ORDER',
  CREATED_CONTRACT = 'CREATED_CONTRACT',
  EDITED_SOV = 'EDITED_SOV',
  FORECAST_CREATED = 'FORECAST_CREATED',
  FORECAST_DELETED = 'FORECAST_DELETED',
  FORECAST_EDITED = 'FORECAST_EDITED',
  GROUPED_EDITED_SOV = 'GROUPED_EDITED_SOV',
  OPTED_OUT_OF_BILLING = 'OPTED_OUT_OF_BILLING',
  PAY_APP_CREATED = 'PAY_APP_CREATED',
  PAY_APP_EDITED_BALANCE_MANUALLY_CLOSED = 'PAY_APP_EDITED_BALANCE_MANUALLY_CLOSED',
  PAY_APP_EDITED_RETENTION = 'PAY_APP_EDITED_RETENTION',
  PAY_APP_MARKED_AS_PAID = 'PAY_APP_MARKED_AS_PAID',
  PAY_APP_PAID_AMOUNT_UPDATED = 'PAY_APP_PAID_AMOUNT_UPDATED',
  PAY_APP_PAID_IN_INTEGRATION = 'PAY_APP_PAID_IN_INTEGRATION',
  PAY_APP_REVERTED_TO_DRAFT = 'PAY_APP_REVERTED_TO_DRAFT',
  PAY_APP_SUBMITTED = 'PAY_APP_SUBMITTED',
  PROJECT_ARCHIVED = 'PROJECT_ARCHIVED',
  SELECTED_FORMS = 'SELECTED_FORMS',
  UPDATED_GC_CONTACTS = 'UPDATED_GC_CONTACTS',
  UPDATED_LEAD_PMS = 'UPDATED_LEAD_PMS',
  UPDATED_PROJECT_SETTINGS = 'UPDATED_PROJECT_SETTINGS',
  UPDATED_RETENTION_SETTINGS = 'UPDATED_RETENTION_SETTINGS',
  UPDATED_USERS = 'UPDATED_USERS',
}

/** Project list filter for Project Status */
export enum ContractListContractStatusFilter {
  /** Active contracts only. Default filter. */
  ACTIVE = 'ACTIVE',
  /** Include all active and archived contracts */
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
  FULLY_BILLED = 'FULLY_BILLED',
  FULLY_BILLED_INCLUDING_DRAFT = 'FULLY_BILLED_INCLUDING_DRAFT',
  ONBOARDING = 'ONBOARDING',
}

/** Project list filter for Pay App Status */
export enum ContractListPayAppStatusFilter {
  AWAITING_SOV = 'AWAITING_SOV',
  DRAFT = 'DRAFT',
  DRAFT_SUBMITTED = 'DRAFT_SUBMITTED',
  INVOICE_READY = 'INVOICE_READY',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_BILLING_THIS_MONTH = 'NOT_BILLING_THIS_MONTH',
  NOT_ERP_SYNCED = 'NOT_ERP_SYNCED',
  NOT_STARTED = 'NOT_STARTED',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  SIGNED = 'SIGNED',
  SUBMITTED_SYNCED = 'SUBMITTED_SYNCED',
  SUBMITTED_SYNCED_PAID = 'SUBMITTED_SYNCED_PAID',
  SYNC_FAILED = 'SYNC_FAILED',
  SYNC_PENDING = 'SYNC_PENDING',
}

export enum ContractListSortCriteria {
  COMPLETED_TO_DATE = 'COMPLETED_TO_DATE',
  COMPLIANCE_STATUS = 'COMPLIANCE_STATUS',
  CONTRACT_STATUS = 'CONTRACT_STATUS',
  DUE_TO_ACCOUNTING = 'DUE_TO_ACCOUNTING',
  DUE_TO_GC = 'DUE_TO_GC',
  FORECAST_DURATION = 'FORECAST_DURATION',
  FORECAST_START_DATE = 'FORECAST_START_DATE',
  FORM_BUILDING_STATUS = 'FORM_BUILDING_STATUS',
  FULLY_BILLED_NEXT_STEP = 'FULLY_BILLED_NEXT_STEP',
  INCOMPLETE_ONBOARDING_TASK = 'INCOMPLETE_ONBOARDING_TASK',
  LAST_EDITED = 'LAST_EDITED',
  LEAD_PM = 'LEAD_PM',
  PROGRESS_AMOUNT_OUTSTANDING = 'PROGRESS_AMOUNT_OUTSTANDING',
  PROGRESS_REMAINING = 'PROGRESS_REMAINING',
  PROJECT_NAME = 'PROJECT_NAME',
  PROJECT_NUMBER = 'PROJECT_NUMBER',
  RETENTION_AMOUNT_OUTSTANDING = 'RETENTION_AMOUNT_OUTSTANDING',
  RETENTION_HELD_ON_BILLED_PAY_APPS = 'RETENTION_HELD_ON_BILLED_PAY_APPS',
  SAME_GC = 'SAME_GC',
  SEARCH_RELEVANCE = 'SEARCH_RELEVANCE',
  TOTAL_CONTRACT_VALUE = 'TOTAL_CONTRACT_VALUE',
  VENDOR_COMPLIANCE_STATUS = 'VENDOR_COMPLIANCE_STATUS',
  VENDOR_NUM_DOCUMENTS_PENDING_RESPONSE = 'VENDOR_NUM_DOCUMENTS_PENDING_RESPONSE',
  VENDOR_PROJECT_ONBOARDING_STATUS = 'VENDOR_PROJECT_ONBOARDING_STATUS',
}

/** Project list filter for Submit Via */
export enum ContractListSubmitViaFilter {
  GC_PAY = 'GC_PAY',
  PROCORE = 'PROCORE',
  SITELINE = 'SITELINE',
  TEXTURA = 'TEXTURA',
}

/** Breakdown of contract statuses that a user can apply to the reporting page filters */
export enum ContractMonthlyStatusFilter {
  /** If a contract has a draft pay app or no pay app yet, but the month's due date has not passed */
  DRAFT_ON_TIME = 'DRAFT_ON_TIME',
  /** The contract is not billing if it has been opted out of billing that month, or is fully billed */
  NOT_BILLING = 'NOT_BILLING',
  /** The contract is considered past due if there are pay apps past due in a given month */
  PAST_DUE = 'PAST_DUE',
  /** The contract is submitted for a given month if a pay app has been submitted for that month */
  SUBMITTED = 'SUBMITTED',
}

export enum ContractPaymentTermsType {
  /**
   * Net payment terms mean the GC is contractually obligated to pay within a certain number of days
   * after an invoice date, e.g. NET30 or NET60.
   */
  NET_PAYMENT = 'NET_PAYMENT',
  /**
   * Similar to pay-when-paid, and functionally the same within Siteline, but technically leaves open
   * the possibility that the subcontract never receives payment, if the GC is not paid by the owner.
   */
  PAY_IF_PAID = 'PAY_IF_PAID',
  /**
   * If a contract is pay-when-paid, the GC pays out contractors only after they've received payment
   * from the owner
   */
  PAY_WHEN_PAID = 'PAY_WHEN_PAID',
}

/** Represents the overall status of a given contract. */
export enum ContractStatus {
  /** A contract that is currently billing and active. */
  ACTIVE = 'ACTIVE',
  /** A contract that has been completed or removed from Siteline. */
  ARCHIVED = 'ARCHIVED',
  /** A contract that is not billing right now i.e. because it is put on hold or hasn't started yet. */
  INACTIVE = 'INACTIVE',
}

/** Role a user has on a specific contract for a project */
export enum ContractUserRole {
  /** LEAD_PM indicates the primary PM on a project */
  LEAD_PM = 'LEAD_PM',
  /** MEMBER is the default role given to someone on the project */
  MEMBER = 'MEMBER',
}

export enum DashboardBillingStatus {
  /** A pay app exists and has not been submitted, but its due date has not passed */
  DRAFT_ON_TIME = 'DRAFT_ON_TIME',
  /** A pay app exists and has not been submitted, and its due date has passed */
  DRAFT_PAST_DUE = 'DRAFT_PAST_DUE',
  /** This contract is not expecting to bill this month, e.g. because it has been fully billed */
  NOT_BILLING = 'NOT_BILLING',
  /** No pay app has been created yet for the month, but the default due date has not passed */
  NOT_STARTED_ON_TIME = 'NOT_STARTED_ON_TIME',
  /** No pay app has been created yet for the month, and the default due date has passed */
  NOT_STARTED_PAST_DUE = 'NOT_STARTED_PAST_DUE',
  /** The subcontractor has opted out of billing for this month */
  OPTED_OUT = 'OPTED_OUT',
  /** A pay app has been submitted for this month */
  SUBMITTED = 'SUBMITTED',
}

export enum DocumentExpirationFrequency {
  /** Recurs monthly on the last day of the month. i.e. 1/31, 2/28 (or 2/29), 3/31, 4/30, etc. */
  END_OF_MONTH = 'END_OF_MONTH',
  /**
   * Recurs monthly starting on the startDate that is specified on the LegalRequirement.
   * Note: we don't allow the 29th, 30th, or 31st because those dates don't make sense in February.
   */
  MONTHLY = 'MONTHLY',
  /**
   * These requirements will never expire. Only one document ever has to be uploaded and no expiration
   * date is associated with it.
   */
  NEVER = 'NEVER',
  /**
   * Recurs every 3 months starting on the startDate that is specified on the LegalRequirement.
   * Note: we don't allow the 29th, 30th, or 31st because those dates don't make sense in February.
   */
  QUARTERLY = 'QUARTERLY',
  /**
   * Does not recur on any specific frequency. The user inputs the expiration date every time they
   * upload a document and it doesn't prompt you for a new document till the previous one expires.
   */
  USER_INPUT = 'USER_INPUT',
  /**
   * Recurs weekly starting on the startDate that is specified on the LegalRequirement. i.e. if it
   * is weekly starting Tuesday, 9/14/2021, then it recurs weekly on Tuesdays starting 9/14.
   */
  WEEKLY = 'WEEKLY',
  /** Recurs yearly on the startDate that is specified on the LegalRequirement. */
  YEARLY = 'YEARLY',
}

/** Represents the current status of a LienWaiverRequest from a lower-tier company. */
export enum DocumentRequestStatus {
  /** Request was canceled. This state is no longer used and is only kept around for legacy requests. */
  CANCELED = 'CANCELED',
  /** Request has been signed by the lower-tier sub */
  COMPLETE = 'COMPLETE',
  /** Request is no longer needed because another contact at the lower-tier sub signed the form */
  NOT_NEEDED = 'NOT_NEEDED',
  /** Request has not been sent yet */
  NOT_SENT = 'NOT_SENT',
  /** Request has been sent to the lower-tier sub */
  SENT = 'SENT',
  /** Completed request has been submitted to the GC */
  SUBMITTED = 'SUBMITTED',
  /** Completed request has been synced to Textura */
  SYNCED = 'SYNCED',
  /** Request has been viewed by the lower-tier sub */
  VIEWED = 'VIEWED',
}

export enum DocumentType {
  CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE',
  CERTIFIED_PAYROLL = 'CERTIFIED_PAYROLL',
  DAILY_REPORT = 'DAILY_REPORT',
  INVOICE = 'INVOICE',
  LABOR_RATES = 'LABOR_RATES',
  /** For any legal document type that isn't in one of the above categories */
  OTHER = 'OTHER',
}

export enum DueToType {
  ACCOUNTING = 'ACCOUNTING',
  GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
}

/** All emails that can be configured in email notification preferences are listed here. */
export enum EmailType {
  /** Added to one or more projects */
  ADDED_TO_PROJECT = 'ADDED_TO_PROJECT',
  /** Reminder to create a pay app monthly on the 15th. */
  CREATE_PAY_APP = 'CREATE_PAY_APP',
  DAILY_LEGAL_REQUIREMENT = 'DAILY_LEGAL_REQUIREMENT',
  /**
   * Daily emails sent to all users have two types of errors: pay app or legal requirement.
   * Each of these sets can be configured to be turned off. If both are turned off, the
   * daily email will not be sent.
   */
  DAILY_PAY_APP = 'DAILY_PAY_APP',
  /**
   * Transactional emails have various types that can be individually modified.
   * The first one is if the invoice has been marked ready for review.
   */
  INVOICE_CHANGED = 'INVOICE_CHANGED',
  /** Lower tier lien waiver has been signed. */
  LOWER_TIER_LIEN_WAIVER_COMPLETE = 'LOWER_TIER_LIEN_WAIVER_COMPLETE',
  /** Monthly emails are sent to companies with data reports from the previous month */
  MONTHLY_REPORT = 'MONTHLY_REPORT',
  /** Project forms have been processed */
  ONBOARDED_PROJECT_FORMS = 'ONBOARDED_PROJECT_FORMS',
  /** Project opted out of billing for a month */
  OPTED_OUT_OF_BILLING = 'OPTED_OUT_OF_BILLING',
  /** Sent when a pay app has been marked as paid. */
  PAY_APP_PAID = 'PAY_APP_PAID',
  /** Report of Textura sync (for Textura projects only). */
  TEXTURA_SYNC = 'TEXTURA_SYNC',
  WEEKLY_LEGAL_REQUIREMENT = 'WEEKLY_LEGAL_REQUIREMENT',
  /**
   * Weekly emails sent to all users have two types of errors: pay app or legal requirement.
   * Each of these sets can be configured to be turned off. If both are turned off, the
   * weekly email will not be sent.
   */
  WEEKLY_PAY_APP = 'WEEKLY_PAY_APP',
}

/** A curve used to generate a forecast, with amounts fitted to the curve over a series of months */
export enum ForecastCurve {
  BELL = 'BELL',
  CUSTOM = 'CUSTOM',
  FLAT = 'FLAT',
  FRONTLOAD = 'FRONTLOAD',
}

/** Type of image to render on a form */
export enum FormTemplateAnnotationImageType {
  ARCHITECT_LOGO = 'ARCHITECT_LOGO',
  GENERAL_CONTRACTOR_LOGO = 'GENERAL_CONTRACTOR_LOGO',
  NOTARY_SIGNATURE = 'NOTARY_SIGNATURE',
  NOTARY_STAMP = 'NOTARY_STAMP',
  OWNER_LOGO = 'OWNER_LOGO',
  SECONDARY_SIGNATURE = 'SECONDARY_SIGNATURE',
  SUBCONTRACTOR_LOGO = 'SUBCONTRACTOR_LOGO',
}

export enum FormTemplateAnnotationMetadataFieldType {
  LIEN_WAIVER_AMOUNT = 'LIEN_WAIVER_AMOUNT',
}

/**
 * Represents the type of form template. We will be filling out the form differently depending on what
 * type of form it is. Specifics are included on each enum value.
 */
export enum FormTemplateAnnotationType {
  /** An image annotation. Usually subcontractor logo, or owner / GC / architect. */
  IMAGE = 'IMAGE',
  /** Allows you to render a signature in this field. */
  SIGNATURE = 'SIGNATURE',
  /** This is a field from the data model. How to render/format will be stored in metadata */
  TEMPLATE_VARIABLE = 'TEMPLATE_VARIABLE',
  /** This would be anything the user needs to enter on every pay app, like invoice number */
  USER_ENTERED_FIELD = 'USER_ENTERED_FIELD',
}

/**
 * Represents the font used for annotating forms. This is the full font, including whether or not it
 * is normal, bold, or italicized.
 */
export enum FormTemplateFont {
  ARIAL_NORMAL = 'ARIAL_NORMAL',
  CARLITO_BOLD = 'CARLITO_BOLD',
  CARLITO_ITALIC = 'CARLITO_ITALIC',
  CARLITO_NORMAL = 'CARLITO_NORMAL',
  HELVETICA_BOLD = 'HELVETICA_BOLD',
  HELVETICA_ITALIC = 'HELVETICA_ITALIC',
  HELVETICA_NORMAL = 'HELVETICA_NORMAL',
  TAHOMA_NORMAL = 'TAHOMA_NORMAL',
  TIMES_ROMAN_BOLD = 'TIMES_ROMAN_BOLD',
  TIMES_ROMAN_ITALIC = 'TIMES_ROMAN_ITALIC',
  TIMES_ROMAN_NORMAL = 'TIMES_ROMAN_NORMAL',
}

/**
 * When onboarding new forms, users may specify which type(s) of signatures are required on the form.
 * These are the options we include for users to select.
 */
export enum FormTemplateOnboardingSignatureType {
  /** A digital signature is required */
  DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
  /** No signatures are required */
  NONE = 'NONE',
  /** Notarization is required */
  NOTARIZATION = 'NOTARIZATION',
  /** A wet signature is required */
  WET_SIGNATURE = 'WET_SIGNATURE',
}

export enum FormTemplateStatus {
  /** The form is being built */
  BUILDING = 'BUILDING',
  /** The template has been abandoned and will not be built */
  CANCELED = 'CANCELED',
  /** The template is ready to add to projects */
  COMPLETE = 'COMPLETE',
  /** The form template is a duplicate of an existing form template */
  DUPLICATE = 'DUPLICATE',
  /** The form needs some work before it is ready be built, including adding instructions, finding matches, or splitting up the form */
  PREPARING_FOR_BUILD = 'PREPARING_FOR_BUILD',
  /** The form is ready to be built */
  READY_FOR_BUILD = 'READY_FOR_BUILD',
  /** The template is finished building and ready for validation */
  READY_FOR_VALIDATION = 'READY_FOR_VALIDATION',
  /** The template is finished validating and needs final owner approval to add to projects */
  VALIDATED = 'VALIDATED',
  /** The form has been created but it does not have an original file yet, so it is not ready to be built */
  WAITING_ON_ORIGINAL_FILE = 'WAITING_ON_ORIGINAL_FILE',
}

export enum FormTemplateTag {
  CONDITIONAL_FINAL = 'CONDITIONAL_FINAL',
  /** LIEN_WAIVER tags */
  CONDITIONAL_PROGRESS = 'CONDITIONAL_PROGRESS',
  COVER_SHEET = 'COVER_SHEET',
  /** Mostly just PAY_APP_LUMP_SUM tags */
  G702 = 'G702',
  G703 = 'G703',
  GROUPED_LINE_ITEMS = 'GROUPED_LINE_ITEMS',
  NOTARIZED = 'NOTARIZED',
  RETENTION_ONLY = 'RETENTION_ONLY',
  SUBCONTRACTOR_AFFIDAVIT = 'SUBCONTRACTOR_AFFIDAVIT',
  SWORN_STATEMENT = 'SWORN_STATEMENT',
  UNCONDITIONAL_FINAL = 'UNCONDITIONAL_FINAL',
  UNCONDITIONAL_PROGRESS = 'UNCONDITIONAL_PROGRESS',
}

/** Represents the type of a form template. Each type accepts a different set of template variables. */
export enum FormTemplateType {
  /** Optionally used to submit change order request log on official form, provided with ChangeOrderLogTemplateVariables */
  CHANGE_ORDER_LOG = 'CHANGE_ORDER_LOG',
  /** Used in change order request flows, provided with ChangeOrderRequestTemplateVariables */
  CHANGE_ORDER_REQUEST = 'CHANGE_ORDER_REQUEST',
  /** Used in legal requirement flows, provided with LegalRequirementTemplateVariables */
  LEGAL_DOCUMENT = 'LEGAL_DOCUMENT',
  /** Used in lien-waiver flows and pay-app packages, provided with LienWaiverTemplateVariables */
  LIEN_WAIVER = 'LIEN_WAIVER',
  /** Used in LUMP_SUM pay-app packages, provided with PayAppLumpSumTemplateVariables */
  PAY_APP_LUMP_SUM = 'PAY_APP_LUMP_SUM',
  /** Used in QUICK pay-app packages, provided with PayAppQuickTemplateVariables */
  PAY_APP_QUICK = 'PAY_APP_QUICK',
  /** Used in TIME_AND_MATERIALS, provided with PayAppTimeAndMaterialsTemplateVariables */
  PAY_APP_TIME_AND_MATERIALS = 'PAY_APP_TIME_AND_MATERIALS',
  /** Used in UNIT_PRICE pay-app packages, provided with PayAppUnitPriceTemplateVariables */
  PAY_APP_UNIT_PRICE = 'PAY_APP_UNIT_PRICE',
}

export enum FormWorkerRole {
  BUILDER = 'BUILDER',
  OWNER = 'OWNER',
  VALIDATOR = 'VALIDATOR',
}

export enum ImportIntegrationChangeOrdersMethod {
  /**
   * In special cases, customers may prefer for change orders in their ERP to be imported as
   * new, individual line items on the SOV, rather than being merged into their linked line item.
   * This means the SOV we read from the ERP may *not* match the SOV in the ERP. This logic will
   * be used only when reading the SOV to import into Siteline, not when allocating invoice billing.
   */
  CREATE_NEW_LINE_ITEMS = 'CREATE_NEW_LINE_ITEMS',
  /**
   * The default method of importing change orders is to match change orders to SOV line items,
   * use the revised amount as the line item amount, and mark the corresponding line item as a
   * change order (regardless of whether it had an original amount prior to the change order). This
   * affects ERP integrations only.
   */
  MERGE_ORIGINAL_LINE_ITEMS = 'MERGE_ORIGINAL_LINE_ITEMS',
}

export enum ImportIntegrationComboJobMethod {
  /**
   * In this method, we will import each job as a separate project, and the corresponding SOVs will
   * include only the subset of the integration contract line items that link to that job. There will
   * be no special grouping of the SOV line items.
   *
   * This is not currently supported by any integration, but will be supported by the Sage 300 Agave
   * integration.
   */
  MULTIPLE_PROJECTS = 'MULTIPLE_PROJECTS',
  /**
   * The default method is to import a single project with the contract SOV in Siteline, with no extra
   * handling for combo jobs. The line items are imported directly, with no special grouping.
   */
  SINGLE_PROJECT_FLAT_SOV = 'SINGLE_PROJECT_FLAT_SOV',
  /**
   * In this method, a single project will be imported with the contract SOV in Siteline, but we will
   * group the contract line items by job if there are multiple jobs linked by the line items
   */
  SINGLE_PROJECT_GROUPED_SOV = 'SINGLE_PROJECT_GROUPED_SOV',
}

export enum ImportIntegrationProjectNameSource {
  /**
   * Use the contract name instead of the job name, if different. If no contract, or contracts are not
   * supported by this integration, will fall back to the job name.
   */
  CONTRACT_NAME = 'CONTRACT_NAME',
  /** The default method is to use the job name from the integration */
  JOB_NAME = 'JOB_NAME',
}

/** Enum for all async import operations (project, vendors, etc) */
export enum ImportOperationStatus {
  COMPLETED = 'COMPLETED',
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
}

/** The status of an individual integration sync request. */
export enum IntegrationSyncRequestStatus {
  /** The request was canceled by a user action (i.e. deleting the pay app). */
  CANCELED = 'CANCELED',
  /** The request failed. */
  ERROR = 'ERROR',
  /** The request has not been processed, for example if there was a GLOBAL_ERROR on the sync attempt. */
  NOT_PROCESSED = 'NOT_PROCESSED',
  /** The request is pending until the draw opens. */
  PENDING = 'PENDING',
  /** The request succeeded. */
  SUCCESS = 'SUCCESS',
  /** The request is currently in progress. */
  SYNCING = 'SYNCING',
}

/**
 * Represents an integration request type. The user can sync:
 * 1. A pay app (all the invoice details)
 * 2. A set of line items for a pay app (only specific line items and amounts are specified to sync)
 * 3. A lien waiver request (the lien waiver that a lower tier sub signed)
 * 4. A legal requirement (the latest legal document uploaded to it)
 */
export enum IntegrationSyncRequestType {
  LEGAL_REQUIREMENT = 'LEGAL_REQUIREMENT',
  LIEN_WAIVER = 'LIEN_WAIVER',
  PAY_APP = 'PAY_APP',
  PAY_APP_LINE_ITEMS = 'PAY_APP_LINE_ITEMS',
}

export enum IntegrationSyncResultCode {
  /** Billing and retention must be rounded to the nearest dollar */
  BILLING_MUST_BE_ROUNDED = 'BILLING_MUST_BE_ROUNDED',
  /** Cannot bill for retention with this integration */
  CANNOT_BILL_FOR_RETENTION = 'CANNOT_BILL_FOR_RETENTION',
  /** The draw hasn't been opened yet */
  DRAW_NOT_OPENED = 'DRAW_NOT_OPENED',
  /** Sage could not import the invoice and customer needs to check the journal */
  EXPORT_FAILED_JOURNAL_NEEDED = 'EXPORT_FAILED_JOURNAL_NEEDED',
  /** There were extra line items on the Integration so an import of line items is needed */
  LINE_ITEM_IMPORT_NEEDED = 'LINE_ITEM_IMPORT_NEEDED',
  /** No result code specified - this means no user action or specific user message required */
  NONE = 'NONE',
  /** Pay app was already submitted from the integration */
  PAY_APP_ALREADY_SUBMITED = 'PAY_APP_ALREADY_SUBMITED',
  /** Pay app retention value does not match after pay app sync */
  PAY_APP_RETENTION_DOES_NOT_MATCH = 'PAY_APP_RETENTION_DOES_NOT_MATCH',
}

/** The status of an entire integration sync that may include multiple requests. */
export enum IntegrationSyncStatus {
  /** The sync was canceled by a user action (i.e. deleting the pay app). */
  CANCELED = 'CANCELED',
  /** The entire sync attempt failed due to a global error. */
  GLOBAL_ERROR = 'GLOBAL_ERROR',
  /** Some subset (or all) of the requests failed with individual errors. */
  REQUEST_ERRORS = 'REQUEST_ERRORS',
  /** The sync succeeded. */
  SUCCESS = 'SUCCESS',
  /** The sync is currently in progress. */
  SYNCING = 'SYNCING',
}

export enum IntegrationType {
  /** Acumatica ERP */
  ACUMATICA = 'ACUMATICA',
  /** File-base integration with ComputerEase */
  COMPUTER_EASE_FILE = 'COMPUTER_EASE_FILE',
  /** Integration via Agave with Foundation */
  FOUNDATION = 'FOUNDATION',
  /** File-based integration with Foundation */
  FOUNDATION_FILE = 'FOUNDATION_FILE',
  /** Export-only integration to GCPay */
  GC_PAY = 'GC_PAY',
  /** Integration via Agave with Procore */
  PROCORE = 'PROCORE',
  /** File-based integration with Quickbooks Enterprise */
  QUICKBOOKS_ENTERPRISE_FILE = 'QUICKBOOKS_ENTERPRISE_FILE',
  /** Integration via hh2 with Sage 100 Contractor */
  SAGE_100_CONTRACTOR = 'SAGE_100_CONTRACTOR',
  /** Integration via Agave with Sage 100 Contractor */
  SAGE_100_CONTRACTOR_AGAVE = 'SAGE_100_CONTRACTOR_AGAVE',
  /** Integration via hh2 with Sage 300 Construction and Real Estate */
  SAGE_300_CRE = 'SAGE_300_CRE',
  /** Integration via Agave with Sage 300 Construction and Real Estate */
  SAGE_300_CRE_AGAVE = 'SAGE_300_CRE_AGAVE',
  /** Integration via Agave with Sage Intacct */
  SAGE_INTACCT = 'SAGE_INTACCT',
  /** Integration with Viewpoint Spectrum's web services API */
  SPECTRUM = 'SPECTRUM',
  /** Export-only integration which randomly fails or succeeds, for testing purposes */
  TEST = 'TEST',
  /** Export-only integration to Textura */
  TEXTURA = 'TEXTURA',
  /** Integration via Agave with Viewpoint Vista */
  VISTA = 'VISTA',
}

export enum LienWaiverCategory {
  CONDITIONAL = 'CONDITIONAL',
  UNCONDITIONAL = 'UNCONDITIONAL',
}

/** Represents the type of lien waiver (conditional/unconditional, progress/final). */
export enum LienWaiverType {
  CONDITIONAL_FINAL_PAYMENT = 'CONDITIONAL_FINAL_PAYMENT',
  CONDITIONAL_PROGRESS_PAYMENT = 'CONDITIONAL_PROGRESS_PAYMENT',
  UNCONDITIONAL_FINAL_PAYMENT = 'UNCONDITIONAL_FINAL_PAYMENT',
  UNCONDITIONAL_PROGRESS_PAYMENT = 'UNCONDITIONAL_PROGRESS_PAYMENT',
}

/**
 * Type of a Rate or Amount line item.
 * This is used to determine grouping in T&M invoices, but can also be used to aggregate and categorize
 * different types of line items in pay-app packages.
 */
export enum LineItemType {
  ADDITIONAL = 'ADDITIONAL',
  EQUIPMENT = 'EQUIPMENT',
  LABOR = 'LABOR',
  MATERIALS = 'MATERIALS',
  UNCATEGORIZED = 'UNCATEGORIZED',
}

/**
 * Represents whether or not a particular PayApp or LienWaiver has any physical annotations on it, i.e.
 * notary, witness, or wet signatures.
 * DEPRECATED
 */
export enum NotarySignatureStatus {
  /** All FormTemplateAnnotation's are notary, witness, or wet signatures */
  ALL_REQUIRED = 'ALL_REQUIRED',
  /** No FormTemplateAnnotation's are notary, witness, or wet signatures */
  NOT_REQUIRED = 'NOT_REQUIRED',
  /** Some, but not all FormTemplateAnnotation's are notary, witness, or wet signatures */
  SOME_REQUIRED = 'SOME_REQUIRED',
}

export enum NotaryTag {
  DIGITAL_NOTARY_SIGNATURE = 'DIGITAL_NOTARY_SIGNATURE',
  DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
  IMAGE_NOTARY = 'IMAGE_NOTARY',
  IMAGE_SECONDARY_SIGNATURE = 'IMAGE_SECONDARY_SIGNATURE',
  IMAGE_SIGNATURE = 'IMAGE_SIGNATURE',
  NOTARY = 'NOTARY',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  STANDARD_NOTARY = 'STANDARD_NOTARY',
  TO_BE_DETERMINED = 'TO_BE_DETERMINED',
  WET_SIGNATURE = 'WET_SIGNATURE',
}

export enum NotificationType {
  /** Forms are ready to be completed */
  FORMS_REVIEW = 'FORMS_REVIEW',
  /** Invoice has been changed. */
  INVOICE_CHANGED = 'INVOICE_CHANGED',
  /** LegalRequirement has expired. */
  LEGAL_REQUIREMENT_EXPIRED = 'LEGAL_REQUIREMENT_EXPIRED',
  /** LegalRequirement expiring soon. */
  LEGAL_REQUIREMENT_EXPIRING = 'LEGAL_REQUIREMENT_EXPIRING',
  /** Legal document/expiration date missing. */
  LEGAL_REQUIREMENT_MISSING = 'LEGAL_REQUIREMENT_MISSING',
  /** Lower Tier Lien Waiver signed. */
  LOWER_TIER_LIEN_WAIVER_COMPLETE = 'LOWER_TIER_LIEN_WAIVER_COMPLETE',
  /** Mentioned in aging comment */
  MENTIONED_IN_AGING_COMMENT = 'MENTIONED_IN_AGING_COMMENT',
  /** Mentioned in cash forecasting comment */
  MENTIONED_IN_CASH_FORECASTING_COMMENT = 'MENTIONED_IN_CASH_FORECASTING_COMMENT',
  /** Mentioned in change order request comment */
  MENTIONED_IN_CHANGE_ORDER_REQUEST_COMMENT = 'MENTIONED_IN_CHANGE_ORDER_REQUEST_COMMENT',
  /** Mentioned in pay app aging comment, on collections or AR aging page */
  MENTIONED_IN_PAY_APP_AGING_COMMENT = 'MENTIONED_IN_PAY_APP_AGING_COMMENT',
  /** Mentioned in pay app comment */
  MENTIONED_IN_PAY_APP_COMMENT = 'MENTIONED_IN_PAY_APP_COMMENT',
  /** Mentioned in project-level comment */
  MENTIONED_IN_PROJECT_COMMENT = 'MENTIONED_IN_PROJECT_COMMENT',
  /** Project opted out of billing for a month */
  OPTED_OUT_OF_BILLING = 'OPTED_OUT_OF_BILLING',
  /** Pay app is past its due date. */
  PAY_APP_PASTDUE = 'PAY_APP_PASTDUE',
  /** Project is fully billed. */
  RETENTION_COMPLETE = 'RETENTION_COMPLETE',
  /** Project is halfway billed. */
  RETENTION_HALFWAY = 'RETENTION_HALFWAY',
  /** Reminder to bill for retention. */
  RETENTION_REMINDER = 'RETENTION_REMINDER',
  /** Reminder to fill out Unconditional Lien Waiver. */
  UNCONDITIONAL_LIEN_WAIVER_REMINDER = 'UNCONDITIONAL_LIEN_WAIVER_REMINDER',
  /** Welcome notification for a new user. */
  WELCOME = 'WELCOME',
}

export enum PaginatedListSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PayAppCollectionsNotificationStatus {
  /** Notification has been canceled by the user */
  CANCELED = 'CANCELED',
  /**
   * Notification is no longer needed.
   * We use this status when the pay app is marked as paid.
   */
  NOT_NEEDED = 'NOT_NEEDED',
  /** Notification is queued to be sent */
  NOT_SENT = 'NOT_SENT',
  /** Notification has been sent */
  SENT = 'SENT',
}

export enum PayAppCommentType {
  /** Aging / collections comments */
  AGING = 'AGING',
  /** Billing comments */
  BILLING = 'BILLING',
}

/** An event triggered by a user action or our system, on a specific pay app. */
export enum PayAppEventType {
  ADDED_BACKUP = 'ADDED_BACKUP',
  CLEARED_FORM_VALUES = 'CLEARED_FORM_VALUES',
  EDITED_LINE_ITEM = 'EDITED_LINE_ITEM',
  EDITED_QUICK_BILL = 'EDITED_QUICK_BILL',
  EDITED_RETENTION = 'EDITED_RETENTION',
  EDITED_SOV = 'EDITED_SOV',
  EDITED_WORKSHEET_LINE_ITEM = 'EDITED_WORKSHEET_LINE_ITEM',
  GROUPED_ADDED_BACKUP = 'GROUPED_ADDED_BACKUP',
  GROUPED_EDITED_LINE_ITEM = 'GROUPED_EDITED_LINE_ITEM',
  GROUPED_EDITED_QUICK_BILL = 'GROUPED_EDITED_QUICK_BILL',
  GROUPED_EDITED_SOV = 'GROUPED_EDITED_SOV',
  GROUPED_EDITED_WORKSHEET_LINE_ITEM = 'GROUPED_EDITED_WORKSHEET_LINE_ITEM',
  GROUPED_PAY_APP_SWORN_STATEMENT_EDITED = 'GROUPED_PAY_APP_SWORN_STATEMENT_EDITED',
  PAY_APP_BACKUP_SUBMITTED = 'PAY_APP_BACKUP_SUBMITTED',
  PAY_APP_CREATED = 'PAY_APP_CREATED',
  PAY_APP_DRAFT_SUBMITTED = 'PAY_APP_DRAFT_SUBMITTED',
  PAY_APP_EDITED_BALANCE_MANUALLY_CLOSED = 'PAY_APP_EDITED_BALANCE_MANUALLY_CLOSED',
  PAY_APP_MARKED_AS_PAID = 'PAY_APP_MARKED_AS_PAID',
  PAY_APP_NOTARIZING_UNCONDITIONAL = 'PAY_APP_NOTARIZING_UNCONDITIONAL',
  PAY_APP_PAID_AMOUNT_UPDATED = 'PAY_APP_PAID_AMOUNT_UPDATED',
  /** @deprecated Use PAY_APP_PAID_IN_INTEGRATION instead */
  PAY_APP_PAID_IN_ERP = 'PAY_APP_PAID_IN_ERP',
  PAY_APP_PAID_IN_INTEGRATION = 'PAY_APP_PAID_IN_INTEGRATION',
  PAY_APP_READ_SYNCED = 'PAY_APP_READ_SYNCED',
  PAY_APP_SIGNED = 'PAY_APP_SIGNED',
  PAY_APP_SUBMITTED = 'PAY_APP_SUBMITTED',
  PAY_APP_SWORN_STATEMENT_EDITED = 'PAY_APP_SWORN_STATEMENT_EDITED',
  PAY_APP_SYNCED = 'PAY_APP_SYNCED',
  PAY_APP_SYNC_FAILED = 'PAY_APP_SYNC_FAILED',
  PAY_APP_SYNC_PENDING = 'PAY_APP_SYNC_PENDING',
  REMOVED_BACKUP = 'REMOVED_BACKUP',
  REQUESTED_REVIEW = 'REQUESTED_REVIEW',
  REVERTED_TO_DRAFT = 'REVERTED_TO_DRAFT',
  ROUNDED_LINE_ITEMS = 'ROUNDED_LINE_ITEMS',
  /** @deprecated No longer used since forms are auto-saved */
  SAVED_FORM_VALUES = 'SAVED_FORM_VALUES',
  SENT_FORMS_TO_SIGNER = 'SENT_FORMS_TO_SIGNER',
  UNCONDITIONAL_LIEN_WAIVER_SUBMITTED = 'UNCONDITIONAL_LIEN_WAIVER_SUBMITTED',
}

export enum PayAppListSortCriteria {
  PAID_AT = 'PAID_AT',
  SUBMITTED_AT = 'SUBMITTED_AT',
}

/**
 * Condition provided in PayAppRequirement.conditions, describing what state a pay-app should be in
 * for a requirement to be included.
 * Examples:
 * - Regular progress: PROGRESS_ONLY, SOME_PROGRESS_REMAINING
 * - Final retention: RETENTION_ONLY, NO_RETENTION_HELD
 * - Final pay-app: NO_PROGRESS_REMAINING, NO_RETENTION_HELD
 */
export enum PayAppRequirementCondition {
  /** There is no more progress remaining for the line items that this pay-app bills against */
  NO_PROGRESS_REMAINING = 'NO_PROGRESS_REMAINING',
  /** After this pay-app, there will be no retention held */
  NO_RETENTION_HELD = 'NO_RETENTION_HELD',
  /** This pay-app was created with the retention-only flag off */
  PROGRESS_ONLY = 'PROGRESS_ONLY',
  /** This pay-app was created with the retention-only flag on */
  RETENTION_ONLY = 'RETENTION_ONLY',
  /** There is remaining work for the line items that this pay-app bills against */
  SOME_PROGRESS_REMAINING = 'SOME_PROGRESS_REMAINING',
  /** After this pay-app, there will still be some retention held */
  SOME_RETENTION_HELD = 'SOME_RETENTION_HELD',
  /** There are stored materials billed on this pay app */
  STORED_MATERIALS_BILLED = 'STORED_MATERIALS_BILLED',
}

/**
 * Overarching pay-app status.
 * State life-cycle with integration:
 *   DRAFT -> SYNCED -> PAID
 * State life-cycle with no integration and no notarization needed for their lien waivers:
 *   DRAFT -> SIGNED -> PROPOSED -> PAID
 * State life-cycle with no integration but yes notarization needed for their lien waivers:
 *   DRAFT -> SIGNED -> PROPOSED -> NOTARIZING_UNCONDITIONAL -> PAID
 */
export enum PayAppStatus {
  /** Pay app has not been signed or submitted yet. */
  DRAFT = 'DRAFT',
  /** If the pay app is already submitted and the the unconditional lien waiver is being notarized. */
  NOTARIZING_UNCONDITIONAL = 'NOTARIZING_UNCONDITIONAL',
  /** Pay app has been paid out by the GC and the unconditional lien waiver has been signed. */
  PAID = 'PAID',
  /** Pay app has been submitted for full-review to the General Contractor. */
  PROPOSED = 'PROPOSED',
  /** Pay app has been signed and awaiting a final submit to the General Contractor. */
  SIGNED = 'SIGNED',
  /** Pay app has been synced (i.e. to Textura). */
  SYNCED = 'SYNCED',
  /** Pay app sync has failed. If a user modifies the pay app, it will revert to draft state. */
  SYNC_FAILED = 'SYNC_FAILED',
  /**
   * The user has clicked Sync but its still pending because the draw isn't open yet. This is
   * considered the same as a synced state in that if a user edits anything, the pay app state will
   * revert to draft and the pending sync will be canceled.
   */
  SYNC_PENDING = 'SYNC_PENDING',
}

/** Type of billing to use for the billing report (billed/submitted only, or billed & draft) */
export enum PayAppStatusFilter {
  /** (default) Includes billing on all pay apps regardless of status */
  ALL = 'ALL',
  /** Include only billing on pay apps that have been submitted (includes synced and paid statuses) */
  SUBMITTED_OR_PAID = 'SUBMITTED_OR_PAID',
}

export enum PaymentDateEstimationType {
  /** Based on contract PAY_WHEN_PAID payment terms with an expected # of days */
  CONTRACT_EXPECTED_PAYMENT_TERMS = 'CONTRACT_EXPECTED_PAYMENT_TERMS',
  /** Based on contract NET_PAYMENT payment terms */
  CONTRACT_NET_PAYMENT_TERMS = 'CONTRACT_NET_PAYMENT_TERMS',
  /**
   * Based on contract payment terms.
   * Deprecated, use CONTRACT_NET_PAYMENT_TERMS or CONTRACT_EXPECTED_PAYMENT_TERMS instead.
   */
  CONTRACT_PAYMENT_TERMS = 'CONTRACT_PAYMENT_TERMS',
  /** Default days to paid */
  FIXED_DEFAULT = 'FIXED_DEFAULT',
  /** Average days to paid for this GC across all companies */
  GC_AVERAGE_ALL = 'GC_AVERAGE_ALL',
  /** Average days to paid for this GC across all projects within sub */
  GC_AVERAGE_SUB = 'GC_AVERAGE_SUB',
  /** Average days to paid across pay apps on this specific project */
  PROJECT_AVERAGE_PAYMENTS = 'PROJECT_AVERAGE_PAYMENTS',
  /** Average predicted days to paid across pay apps on this specific project */
  PROJECT_AVERAGE_PREDICTED_PAYMENTS = 'PROJECT_AVERAGE_PREDICTED_PAYMENTS',
}

export enum Permission {
  /** Allows a user to create a quick bill project. */
  CREATE_QUICK_BILL_PROJECT = 'CREATE_QUICK_BILL_PROJECT',
  /** Allows a user to delete projects they are part of */
  DELETE_PROJECT = 'DELETE_PROJECT',
  /** Allows a user to manage backup files. */
  EDIT_BACKUP = 'EDIT_BACKUP',
  /** Allows a user to add, edit, or delete change orders. */
  EDIT_CHANGE_ORDER = 'EDIT_CHANGE_ORDER',
  /** Allows a user to edit user info and project access for company users */
  EDIT_COMPANY_USERS = 'EDIT_COMPANY_USERS',
  /** Allows a user to edit the invoice. */
  EDIT_INVOICE = 'EDIT_INVOICE',
  /** Allows a user to manage legal requirement information. */
  EDIT_LEGAL_REQUIREMENT = 'EDIT_LEGAL_REQUIREMENT',
  /** Allows a user to manage settings on the projects they are part of. */
  EDIT_PROJECT_SETTINGS = 'EDIT_PROJECT_SETTINGS',
  /** Allows a user to sync (write) data to an ERP */
  ERP_SYNC = 'ERP_SYNC',
  /** Allows a user to manage lien waivers. */
  MANAGE_VENDORS = 'MANAGE_VENDORS',
  /** Allows a user to sign documents on behalf of their company. */
  SIGNER = 'SIGNER',
  /** Allows a user to submit pay app documents to the GC */
  SUBMIT = 'SUBMIT',
  /** Allows a user to submit draft pay apps */
  SUBMIT_DRAFT = 'SUBMIT_DRAFT',
}

/**
 * If a form template requires a physical signature, this represents the type of signature required. If
 * multiple types are required, the most "restrictive" type is used (notary > witness > wet).
 */
export enum PhysicalSignatureRequired {
  /** No physical signatures are required */
  NONE_REQUIRED = 'NONE_REQUIRED',
  /** A notary signature is required */
  NOTARY_SIGNATURE_REQUIRED = 'NOTARY_SIGNATURE_REQUIRED',
  /** A wet signature is required */
  WET_SIGNATURE_REQUIRED = 'WET_SIGNATURE_REQUIRED',
  /** A witness signature is required */
  WITNESS_SIGNATURE_REQUIRED = 'WITNESS_SIGNATURE_REQUIRED',
}

/** Subset of PayAppStatus that only applies to pre-siteline pay apps */
export enum PreSitelinePayAppStatus {
  /** Pay app has been paid out by the GC */
  PAID = 'PAID',
  /** Pay app has been submitted for full-review to the General Contractor */
  PROPOSED = 'PROPOSED',
}

/** Represents the types of forms selected when onboarding a new contract */
export enum ProjectOnboardingFormType {
  CHANGE_ORDER_LOG = 'CHANGE_ORDER_LOG',
  CHANGE_ORDER_REQUEST = 'CHANGE_ORDER_REQUEST',
  COMPLIANCE = 'COMPLIANCE',
  PAY_APP = 'PAY_APP',
  PRIMARY_LIEN_WAIVER = 'PRIMARY_LIEN_WAIVER',
  VENDOR_LIEN_WAIVER = 'VENDOR_LIEN_WAIVER',
}

export enum RateTableStatus {
  /**
   * The rate table is not archived and is currently effective, meaning one of the following:
   * - There is no effective date and no expiration date
   * - There is no expiration date, and an effective date in the past
   * - There is no effective date, and an expiration date in the future
   * - There is an effective date in the past and an expiration date in the future
   */
  ACTIVE = 'ACTIVE',
  /** The rate table has been archived */
  ARCHIVED = 'ARCHIVED',
  /** The rate table is not archived and is after its expiration date */
  EXPIRED = 'EXPIRED',
  /** The rate table is not archived and is before its effective date */
  UPCOMING = 'UPCOMING',
}

export enum RequiredFieldForForms {
  GENERAL_CONTRACTOR_ADDRESS = 'GENERAL_CONTRACTOR_ADDRESS',
  OWNER_ADDRESS = 'OWNER_ADDRESS',
  OWNER_NAME = 'OWNER_NAME',
}

export enum RetentionTrackingLevel {
  /**
   * Retention is tracked on the sov line item level. This means that a fixed percent of the total
   * billed on a line item is held and added up. For example, lets say a subcontractor has $10,000
   * billed from past months and an additional $10,000 in current progress billing. If their
   * progressRetentionPercent is 10%, then the GC has $2,000 retention held.
   */
  LINE_ITEM = 'LINE_ITEM',
  /**
   * There is no retention tracking.
   * This only applies to QUICK projects.
   */
  NONE = 'NONE',
  /**
   * Retention is tracked at the pay app level. This means that a fixed percent of the total billed on
   * that pay app is held and added to the fixed amount of each past pay app. For example, if a
   * subcontractor has billed for $10,000 last pay app with 10% rate and $10,000 this pay app with 5%
   * rate, then the GC has $1,500 retention held.
   */
  PAY_APP = 'PAY_APP',
  /**
   * Retention is tracked at the project level. This means that a fixed percent of the total billed on
   * the project is held. For example, if a subcontractor has billed for $100,000 on the project so far
   * and their PayApp retentionPercent is set to 10%, then the GC has $10,000 retention held.
   */
  PROJECT = 'PROJECT',
  /**
   * Retention is tracked on the sov line item progress level. This means that a fixed percent of the
   * total billed on a line item progress is held and added up. For example, lets say a subcontractor
   * has $10,000 billed from past months and an additional $10,000 in current progress billing. If
   * their progressRetentionPercent in the past pay apps is 10%, but it is set to 5% on this pay app,
   * then the GC has $1,500 retention held.
   */
  STANDARD = 'STANDARD',
}

export enum Sage100InvoiceTaxType {
  /**
   * Some customers like Guthrie need to explicitly record taxes in the SOV, as separate line items
   * which are billed for month over month.
   * For instance the SOV would have:
   *  1. NON-TAXABLE LABOR
   *  2. TAXABLE LABOR
   *  3. TAX ON LABOR
   *
   * In this case, the tax amount is the billing value of "TAX ON LABOR", and the Siteline SOV is
   * considered to be post tax. Siteline tax calculation is disabled on these contracts.
   * Tax amount on the AR invoice will come from these individual items, which must be passed in
   * at sync time via the `taxLineItemIds` payload field.
   */
  SITELINE_SOV_POST_TAX = 'SITELINE_SOV_POST_TAX',
  /**
   * Set a tax district on the invoice so that Sage calculates taxes automatically.
   * Taxes will appear on the AR invoice in the Sales tax field.
   * There will be only one line item containing the pre-tax amount of the pay app.
   * All payments (pre-tax + taxes) will go to the specified income account.
   */
  TAX_DISTRICT = 'TAX_DISTRICT',
}

export enum SearchResultType {
  COMPANY = 'COMPANY',
  CONTRACT = 'CONTRACT',
  FORM_TEMPLATE = 'FORM_TEMPLATE',
  PAY_APP = 'PAY_APP',
  USER = 'USER',
}

export enum SignatureAnnotationType {
  /** Most basic signature type, attached digitally to the PDF */
  DIGITAL = 'DIGITAL',
  /** Required to be signed by a notary. */
  NOTARY = 'NOTARY',
  /** Required to be signed physically, rather than digitally. */
  WET = 'WET',
  /** Required to be physically signed with a witness. (with or by a witness??) */
  WITNESS = 'WITNESS',
}

/** Represents the font used for a particular signature rendered on a form. */
export enum SignatureFont {
  CEDARVILLE_CURSIVE = 'CEDARVILLE_CURSIVE',
  PACIFICO = 'PACIFICO',
  PARISIENNE = 'PARISIENNE',
  PERMANENT_MARKER = 'PERMANENT_MARKER',
  PORTRAIT_SCRIPT = 'PORTRAIT_SCRIPT',
  PORTRAIT_SCRIPT_BOUNCE = 'PORTRAIT_SCRIPT_BOUNCE',
  RIVER_JADE = 'RIVER_JADE',
  WESTBURY_SIGNATURE = 'WESTBURY_SIGNATURE',
}

/** An event triggered by a user action or our system, on a specific line-item progress */
export enum SovLineItemProgressEventType {
  /** {} */
  CREATE_LINE_ITEM = 'CREATE_LINE_ITEM',
  /** {} */
  RESET_FROM_PREVIOUS_PAY_APP = 'RESET_FROM_PREVIOUS_PAY_APP',
  /** { oldUnitPrice, newUnitPrice } */
  RETENTION_ADJUSTED = 'RETENTION_ADJUSTED',
  /** { oldLineItemCode, newLineItemCode } */
  SET_LINE_ITEM_CODE = 'SET_LINE_ITEM_CODE',
  /** { oldLineItemCostCode, newLineItemCostCode} */
  SET_LINE_ITEM_COST_CODE = 'SET_LINE_ITEM_COST_CODE',
  /** { oldLineItemName, newLineItemName } */
  SET_LINE_ITEM_NAME = 'SET_LINE_ITEM_NAME',
  /** { oldLineItemOrder, newLineItemOrder } */
  SET_LINE_ITEM_ORDER = 'SET_LINE_ITEM_ORDER',
  /** { oldLineItemPreviousBilled, newLineItemPreviousBilled } */
  SET_LINE_ITEM_PREVIOUS_BILLED = 'SET_LINE_ITEM_PREVIOUS_BILLED',
  /** { oldLineItemTotalValue, newLineItemTotalValue } */
  SET_LINE_ITEM_TOTAL_VALUE = 'SET_LINE_ITEM_TOTAL_VALUE',
  /** { oldRetentionHeld, newRetentionHeld } */
  SET_LINE_ITEM_UNIT_NAME = 'SET_LINE_ITEM_UNIT_NAME',
  /** { oldUnitName, newUnitName } */
  SET_LINE_ITEM_UNIT_PRICE = 'SET_LINE_ITEM_UNIT_PRICE',
  /** { oldProgressBilled, newProgressBilled } */
  SET_PROGRESS_BILLED = 'SET_PROGRESS_BILLED',
  /** { oldStoredMaterialBilled, newStoredMaterialBilled } */
  SET_STORED_MATERIAL_BILLED = 'SET_STORED_MATERIAL_BILLED',
  /** { oldStoredMaterialBilled, newStoredMaterialBilled, oldStoredMaterialInstalled, newStoredMaterialInstalled, oldMaterialsInStorage, newMaterialsInStorage } */
  SET_STORED_MATERIAL_BILLED_AND_INSTALLED = 'SET_STORED_MATERIAL_BILLED_AND_INSTALLED',
}

export enum StoredFileType {
  DOC = 'DOC',
  DOCX = 'DOCX',
  JPEG = 'JPEG',
  PDF = 'PDF',
  PNG = 'PNG',
  XLS = 'XLS',
  XLSX = 'XLSX',
}

export enum StoredMaterialsCarryoverType {
  /** Stored materials billed in a pay app are automatically moved to previous billing on the next pay app. This is the default type. */
  AUTOMATIC = 'AUTOMATIC',
  /**
   * Stored materials remain in the stored column until the user moves them to previous billed at the time of installation. This is
   * the “proper” way to handle stored materials per AIA instructions, but less common.
   */
  MANUAL = 'MANUAL',
}

export enum TaxCalculationType {
  /** Line items can have different tax groups, as long they belong to the company. */
  MULTIPLE_TAX_GROUPS = 'MULTIPLE_TAX_GROUPS',
  /** Tax is not calculated at all */
  NONE = 'NONE',
  /**
   * All line items have the same tax group.
   * If a tax group other than `defaultTaxGroup` is assigned, we throw an error.
   */
  SINGLE_TAX_GROUP = 'SINGLE_TAX_GROUP',
}

export enum TemplateListSortCriteria {
  CREATED_AT = 'CREATED_AT',
  DUE_DATE = 'DUE_DATE',
  SEARCH_RELEVANCE = 'SEARCH_RELEVANCE',
  USER_VISIBLE_NAME = 'USER_VISIBLE_NAME',
}

export enum TemplateListStatusFilter {
  COMPLETE = 'COMPLETE',
  NOT_COMPLETE = 'NOT_COMPLETE',
  NOT_VALIDATED = 'NOT_VALIDATED',
  READY_FOR_VALIDATION = 'READY_FOR_VALIDATION',
  VALIDATED = 'VALIDATED',
}

export enum TextAlignment {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum Tutorial {
  LIEN_WAIVER_COPY_OVER_VENDORS = 'LIEN_WAIVER_COPY_OVER_VENDORS',
}

/** The status of a user, right now disabled users do not receive any email notifications. */
export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum VendorComplianceStatus {
  COMPLIANT = 'COMPLIANT',
  NOT_COMPLIANT = 'NOT_COMPLIANT',
}

export enum VendorContractCommitmentType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  SUB_CONTRACT = 'SUB_CONTRACT',
}

export enum VendorLienWaiverReminderFrequency {
  /** Reminders are automatically send every business day until the lien waiver is signed */
  DAILY = 'DAILY',
  /** Siteline never sends automatic reminders for outstanding vendor lien waivers */
  NEVER = 'NEVER',
  /** Reminders are automatically send once per week until the lien waiver is signed */
  WEEKLY = 'WEEKLY',
}

export enum VendorListSortCriteria {
  NAME = 'NAME',
}

export enum WriteSyncOperationStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  DEFERRED = 'DEFERRED',
  NOT_STARTED = 'NOT_STARTED',
  QUEUED_IN_HH2 = 'QUEUED_IN_HH2',
  RUNNING = 'RUNNING',
}

export enum WriteSyncOperationsSortCriteria {
  CREATED_AT = 'CREATED_AT',
}
