import { Grid } from '@mui/material'
import { isMonthlyBillingType } from 'siteline-common-all'
import { DetailedPayApp } from '../../common/graphql/Fragments'
import { BillingType } from '../../common/graphql/apollo-operations'
import ContractDetailsInfo from '../contract-details/lump-sum/ContractDetailsInfo'
import PayAppDetailsAmountLineItem from './PayAppDetailsAmountLineItem'
import PayAppDetailsInfo from './PayAppDetailsInfo'
import PayAppDetailsPinnedTemplates from './PayAppDetailsPinnedTemplates'
import PayAppDetailsProgress from './PayAppDetailsProgress'

interface PayAppDetailsContentProps {
  payApp: DetailedPayApp
}

export default function PayAppDetailsContent({ payApp }: PayAppDetailsContentProps) {
  const contract = payApp.contract
  return (
    <>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PayAppDetailsInfo payApp={payApp} contract={payApp.contract} />
          </Grid>
          {payApp.hasPinnedTemplates && (
            <Grid item xs={12}>
              <PayAppDetailsPinnedTemplates payApp={payApp} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <ContractDetailsInfo
          contract={contract}
          project={contract.project}
          company={contract.company}
          withLink
        />
      </Grid>
      <Grid item xs={12}>
        {isMonthlyBillingType(payApp.billingType) && <PayAppDetailsProgress payApp={payApp} />}
        {payApp.billingType === BillingType.QUICK && (
          <PayAppDetailsAmountLineItem payApp={payApp} />
        )}
      </Grid>
    </>
  )
}
