import { ApolloCache, FetchResult, gql, MutationFunctionOptions } from '@apollo/client'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
  Autocomplete,
  Checkbox,
  IconButton,
  Link,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material'
import moment from 'moment-timezone'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { DAY_FORMAT } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { formatLocationManyLines } from '../../common/util/Location'
import { Exact, LocationProperties, useGetCompanyQuery } from '../graphql/apollo-operations'
import { CompanyAutocomplete } from './CompanyAutocomplete'
import { LocationCard } from './LocationCard'

interface EditableCardRowBaseProps<
  T,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> {
  value: T
  label?: string | ReactElement
  readOnly: boolean
  validate?: (value: T) => boolean
  mutate: (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>
  ) => Promise<FetchResult<TData>>
  variables: (value: T) => Exact<TVariables> | Promise<Exact<TVariables>>
  confirmationText?: string
  onToggleEditing?: (editing: boolean) => void
  className?: string
  /** Shown to the right of the value when not editing the value */
  nonEditingEndAdornment?: ReactElement
}

// Simple text row
type EditableCardRowTextProps<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<string, TData, TVariables, TContext, TCache>
export function EditableCardRowText<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>(props: EditableCardRowTextProps<TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={(value) => value}
      editComponent={(value, setValue) => (
        <TextField
          value={value}
          onChange={(ev) => setValue(ev.target.value)}
          size="small"
          autoFocus
        />
      )}
      {...props}
    />
  )
}

// Simple checkbox row
type EditableCardRowCheckboxProps<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<boolean, TData, TVariables, TContext, TCache>
export function EditableCardRowCheckbox<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>(props: EditableCardRowCheckboxProps<TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={(value) => value.toString()}
      editComponent={(value, setValue) => (
        <Checkbox checked={value} onChange={(ev) => setValue(ev.target.checked)} size="small" />
      )}
      {...props}
    />
  )
}

// Date row (YYYY-MM-DD)
type EditableCardRowDateProps<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<string | null, TData, TVariables, TContext, TCache> & {
  timeZone: string
  clearable?: boolean
}
export function EditableCardRowDate<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({
  timeZone,
  clearable,
  ...props
}: EditableCardRowDateProps<TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={(value) =>
        value ? moment.tz(value, timeZone).format('MMM D, YYYY') : 'Not set'
      }
      editComponent={(value, setValue) => (
        <TextField
          type="date"
          size="small"
          value={value ? moment.tz(value, timeZone).format(DAY_FORMAT) : ''}
          onChange={(ev) => setValue(ev.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: clearable ? (
              <IconButton onClick={() => setValue('')} size="small" style={{ marginRight: -8 }}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : undefined,
          }}
        />
      )}
      {...props}
    />
  )
}

// Number row
type EditableCardRowNumberProps<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<number, TData, TVariables, TContext, TCache> & {
  minimum?: number
  maximum?: number
}
export function EditableCardRowNumber<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({ minimum, maximum, ...props }: EditableCardRowNumberProps<TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={(value) => String(value)}
      editComponent={(value, setValue) => (
        <TextField
          type="number"
          value={value}
          onChange={(ev) => setValue(Number(ev.target.value))}
          InputProps={{
            inputProps: {
              max: maximum,
              min: minimum,
            },
          }}
        />
      )}
      {...props}
    />
  )
}

// Location row that takes gets/sets LocationProperties
type EditableCardRowLocationProps<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<LocationProperties, TData, TVariables, TContext, TCache>
export function EditableCardRowLocation<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>(props: EditableCardRowLocationProps<TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={formatLocationManyLines}
      editComponent={(value, setValue) => (
        <LocationCard
          location={value}
          setLocation={(location) => {
            const timeZone = 'America/Los_Angeles'
            setValue({
              ...location,
              street1: location.street1 ?? null,
              street2: location.street2 ?? null,
              postalCode: location.postalCode ?? null,
              county: location.county ?? null,

              // This is a placeholder that is removed in `variables()`.
              // We need this because the row takes a `LocationProperties`, but the
              // autocomplete returns a `LocationInput`.
              timeZone,
              __typename: 'Location',
              id: '',
              nickname: null,
              createdAt: moment.tz(timeZone).toISOString(),
            })
          }}
        />
      )}
      {...props}
    />
  )
}

// Multiple choices row (select)
type EditableCardRowSelectProps<
  T extends string,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<T, TData, TVariables, TContext, TCache> & {
  formatValue?: (key: string) => string
  options: { key: string; value: T }[]
}
export function EditableCardRowSelect<
  T extends string,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({
  options,
  formatValue,
  ...props
}: EditableCardRowSelectProps<T, TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={(key) => (formatValue ? formatValue(key) : key)}
      editComponent={(value, setValue) => (
        <Select value={value} onChange={(ev) => setValue(ev.target.value as T)}>
          {options.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      )}
      {...props}
    />
  )
}

// Same as EditableCardRowSelect row but allows you to clear option and type in
type EditableCardRowAutocompleteProps<
  T extends string,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<T, TData, TVariables, TContext, TCache> & {
  formatValue?: (key: string) => string
  options: { key: string; value: T }[]
  getOptionDisabled?: (key: string) => boolean
}
export function EditableCardRowAutocomplete<
  T extends string,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({
  options,
  formatValue,
  getOptionDisabled,
  ...props
}: EditableCardRowAutocompleteProps<T, TData, TVariables, TContext, TCache>) {
  return (
    <EditableCardRow
      formatValue={(key) => (formatValue ? formatValue(key) : key)}
      editComponent={(value, setValue) => (
        <Autocomplete
          getOptionLabel={(key) => (formatValue ? formatValue(key) : key)}
          options={options.map((option) => option.key)}
          value={value}
          onChange={(ev, value) => setValue(value as T)}
          multiple={false}
          renderInput={(params) => <TextField {...params} />}
          getOptionDisabled={getOptionDisabled}
        />
      )}
      {...props}
    />
  )
}

gql`
  query getCompany($companyId: ID!) {
    company(id: $companyId) {
      ...CompanyProperties
    }
  }
  ${fragments.company}
`

// Company row with autocomplete from our own companies list
type EditableCardRowCompanyProps<
  T,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<T, TData, TVariables, TContext, TCache> & {
  nameOverride?: string
}
export function EditableCardRowCompany<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>(props: EditableCardRowCompanyProps<string | null, TData, TVariables, TContext, TCache>) {
  const companyId = props.value
  const { data } = useGetCompanyQuery({
    variables: {
      companyId: companyId ?? '',
    },
    skip: !companyId,
  })

  return (
    <EditableCardRow
      formatValue={(value) => (
        <>
          {props.nameOverride && <div>Project override: {props.nameOverride}</div>}
          {props.nameOverride && <span>Canonical name: </span>}
          <Link component={RouterLink} to={`/companies/${value}`} underline="hover">
            {data?.company.name ?? ''}
          </Link>
        </>
      )}
      editComponent={(value, setValue) => (
        <CompanyAutocomplete companyId={value} setCompanyId={setValue} />
      )}
      {...props}
    />
  )
}

type EditableCardProps<
  T,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> = EditableCardRowBaseProps<T, TData, TVariables, TContext, TCache> & {
  formatValue: (value: T) => ReactNode
  editComponent: (value: T, setValue: (value: T) => void) => ReactNode
}

/**
 * If you need to have an editable card within a row rather than as a separate row,
 * this can be used as a cell within a row
 */
export function EditableCardCell<
  T,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({
  value: defaultValue,
  formatValue,
  readOnly,
  validate,
  variables,
  mutate,
  editComponent,
  confirmationText,
}: EditableCardProps<T, TData, TVariables, TContext, TCache>) {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState<T>(defaultValue)
  const snackbar = useSitelineSnackbar()

  const validateAndSubmit = async () => {
    if (validate && !validate(value)) {
      throw new Error(`Value '${value}' is invalid`)
    }
    const vars = await variables(value)
    await mutate({ variables: vars })
  }

  const onSubmit = () => {
    if (!window.confirm(confirmationText ?? 'Are you sure you want to do this?')) {
      return
    }

    snackbar.showLoading()
    validateAndSubmit()
      .then(() => {
        snackbar.showSuccess()
        setIsEditing(false)
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const onCancel = () => {
    setIsEditing(false)
    setValue(defaultValue)
  }

  return (
    <TableCell>
      {!isEditing && formatValue(value)}
      {isEditing && <>{editComponent(value, setValue)}</>}
      {isEditing ? (
        <>
          <IconButton size="small" onClick={() => onSubmit()}>
            <CheckIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton size="small" onClick={() => setIsEditing(true)} disabled={readOnly}>
          <EditIcon />
        </IconButton>
      )}
    </TableCell>
  )
}

export default function EditableCardRow<
  T,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({
  value: defaultValue,
  formatValue,
  readOnly,
  label,
  validate,
  variables,
  mutate,
  editComponent,
  confirmationText,
  onToggleEditing,
  className,
  nonEditingEndAdornment,
}: EditableCardProps<T, TData, TVariables, TContext, TCache>) {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState<T>(defaultValue)
  const snackbar = useSitelineSnackbar()

  // If the default value changes (e.g. because the value changed in the database),
  // update the value
  useEffect(() => setValue(defaultValue), [defaultValue])

  // Callback notifying parent component if editing state changes
  useEffect(() => {
    if (onToggleEditing) {
      onToggleEditing(isEditing)
    }
  }, [onToggleEditing, isEditing])

  const validateAndSubmit = async () => {
    if (validate && !validate(value)) {
      throw new Error(`Value '${value}' is invalid`)
    }
    const vars = await variables(value)
    await mutate({ variables: vars })
  }

  const onSubmit = () => {
    if (!window.confirm(confirmationText ?? 'Are you sure you want to do this?')) {
      return
    }

    snackbar.showLoading()
    validateAndSubmit()
      .then(() => {
        snackbar.showSuccess()
        setIsEditing(false)
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const onCancel = () => {
    setIsEditing(false)
    setValue(defaultValue)
  }

  return (
    <TableRow className={className}>
      <TableCell>{label}</TableCell>
      <TableCell>
        {!isEditing && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {formatValue(value)}
            {nonEditingEndAdornment && (
              <div style={{ marginLeft: 16 }}>{nonEditingEndAdornment}</div>
            )}
          </div>
        )}
        {isEditing && <>{editComponent(value, setValue)}</>}
      </TableCell>
      <TableCell style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
        {!isEditing && (
          <IconButton size="small" onClick={() => setIsEditing(true)} disabled={readOnly}>
            <EditIcon />
          </IconButton>
        )}
        {isEditing && (
          <>
            <IconButton type="submit" size="small" onClick={() => onSubmit()}>
              <CheckIcon />
            </IconButton>
            <IconButton size="small" onClick={() => onCancel()}>
              <CloseIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  )
}
