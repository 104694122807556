import { gql } from '@apollo/client'
import { Alert, Container, Grid, Theme, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { makeStylesFast, StoredMaterialsCarryoverType } from 'siteline-common-web'
import CompanyCard from '../../../common/components/CompanyCard'
import { Loader } from '../../../common/components/Loader'
import Page from '../../../common/components/Page'
import * as fragments from '../../../common/graphql/Fragments'
import {
  CompanyProperties,
  CompanySovProperties,
  useSovLineItemGroupsQuery,
} from '../../../common/graphql/apollo-operations'
import { ContractPayApp, ProjectForSubcontractor } from '../ContractDetails'
import ContractDetailsOnboardedStatus from '../ContractDetailsOnboardedStatus'
import ContractDetailsPayAppRequirements from '../ContractDetailsPayAppRequirements'
import ContractDetailsPayApps from '../ContractDetailsPayApps'
import ContractDetailsPendingFormTemplates from '../ContractDetailsPendingFormTemplates'
import ContractDetailsTemplateVariables from '../ContractDetailsTemplateVariables'
import ContractDetailsUsers from '../ContractDetailsUsers'
import ContractDetailsInfo from './ContractDetailsInfo'
import ContractDetailsIntegrations from './ContractDetailsIntegrations'
import ContractDetailsLegalRequirements from './ContractDetailsLegalRequirements'
import ContractDetailsLineItemGroups from './ContractDetailsLineItemGroups'
import ContractDetailsRetention from './ContractDetailsRetention'
import ContractDetailsSov from './ContractDetailsSov'
import ContractLienWaiverSettings from './ContractLienWaiverSettings'

const lienWaiverSettingsFragment = gql`
  fragment ContractForLienWaiverSettings on Contract {
    id
    lienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
    lowerTierLienWaiverTemplates {
      ...SubcontractorLienWaiverTemplateSetProperties
    }
  }
  ${fragments.subcontractorLienWaiverTemplateSet}
`

gql`
  mutation enablePrimaryLienWaivers($input: EnableLienWaiverInput!) {
    enablePrimaryLienWaivers(input: $input) {
      ...ContractForLienWaiverSettings
    }
  }
  ${lienWaiverSettingsFragment}
`

gql`
  mutation disablePrimaryLienWaivers($contractId: ID!) {
    disablePrimaryLienWaivers(contractId: $contractId) {
      ...ContractForLienWaiverSettings
    }
  }
  ${lienWaiverSettingsFragment}
`

gql`
  mutation enableLowerTierLienWaivers($input: EnableLienWaiverInput!) {
    enableLowerTierLienWaivers(input: $input) {
      ...ContractForLienWaiverSettings
    }
  }
  ${lienWaiverSettingsFragment}
`

gql`
  mutation disableLowerTierLienWaivers($contractId: ID!) {
    disableLowerTierLienWaivers(contractId: $contractId) {
      ...ContractForLienWaiverSettings
    }
  }
  ${lienWaiverSettingsFragment}
`

gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ...ContractProperties
    }
  }
  ${fragments.contract}
`

gql`
  query sovLineItemGroups($contractId: ID!) {
    sovLineItemGroups(contractId: $contractId) {
      ...SovLineItemGroupProperties
    }
  }
  ${fragments.sovLineItemGroup}
`

gql`
  mutation updateChangeOrderRequestForm($input: UpdateChangeOrderRequestFormInput!) {
    updateChangeOrderRequestForm(input: $input) {
      id
      changeOrderRequestTemplate {
        id
      }
    }
  }
  ${fragments.contract}
`
gql`
  mutation updateChangeOrderLogForm($input: UpdateChangeOrderLogFormInput!) {
    updateChangeOrderLogForm(input: $input) {
      id
      changeOrderLogTemplate {
        id
      }
    }
  }
  ${fragments.contract}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
  },
}))

type ContractDetailsLumpSumProps = {
  company: CompanyProperties
  project: ProjectForSubcontractor
  payApps: ContractPayApp[]
  contract: fragments.Contract
}

export default function ContractDetailsLumpSum({
  company,
  project,
  payApps,
  contract,
}: ContractDetailsLumpSumProps) {
  const classes = useStyles()

  const [sov, setSov] = useState<CompanySovProperties | undefined>(undefined)
  const {
    data: groupsData,
    error: groupsError,
    loading: groupsLoading,
  } = useSovLineItemGroupsQuery({
    variables: { contractId: contract.id },
  })

  useEffect(() => {
    setSov(contract.sov ?? undefined)
  }, [contract])

  const legalRequirements = useMemo(
    () => contract.legalRequirements.filter((requirement) => !requirement.isVendorRequirement),
    [contract.legalRequirements]
  )
  const pageTitle = `${project.name} - ${company.name}`

  const usesManualStoredMaterials =
    contract.storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL

  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth={false}>
        {!sov && (
          <Alert severity="error">
            No SOV found for subcontractor. This will cause errors if a user logs in.
          </Alert>
        )}
        <Grid container spacing={2} className="grid">
          <Grid item xs={12}>
            <Typography variant="h4">{pageTitle}</Typography>
          </Grid>
          <Grid item xs={6}>
            <ContractDetailsInfo contract={contract} project={project} company={company} />
          </Grid>
          <Grid item xs={6}>
            <CompanyCard company={company} />
          </Grid>
        </Grid>

        <ContractDetailsIntegrations contract={contract} />

        <ContractDetailsPendingFormTemplates contract={contract} />

        <ContractDetailsOnboardedStatus contract={contract} />

        <ContractDetailsPayAppRequirements
          contractId={contract.id}
          billingType={contract.billingType}
          payAppRequirementGroups={[...contract.payAppRequirementGroups]}
          usesManualStoredMaterials={usesManualStoredMaterials}
        />

        <ContractDetailsLegalRequirements
          contract={contract}
          legalRequirements={[...legalRequirements]}
        />

        <ContractLienWaiverSettings contract={contract} />
        <ContractDetailsTemplateVariables contract={contract} />
        <ContractDetailsPayApps payApps={payApps} timeZone={contract.timeZone} />
        {groupsData && (
          <ContractDetailsLineItemGroups
            sovLineItemGroups={[...groupsData.sovLineItemGroups]}
            contractId={contract.id}
            sov={sov}
          />
        )}
        <ContractDetailsRetention contract={contract} payApps={payApps} />
        {sov && <ContractDetailsSov sov={sov} contract={contract} />}
        <ContractDetailsUsers contract={contract} />
        {/** If there is no company sov yet, show the create sov card. */}
        {!sov && (
          <Alert severity="info">
            To create an SOV, visit the first pay app of the project on the customer login on the
            main app.
          </Alert>
        )}
        {groupsError && <Alert severity="error">{groupsError.message}</Alert>}

        {groupsLoading && <Loader />}
      </Container>
    </Page>
  )
}
