import { gql } from '@apollo/client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Permission,
  SitelineInfoPopup,
  SitelineText,
  makeStylesFast,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import {
  Query,
  UserEducationCampaignProperties,
  useCreateUserEducationCampaignMutation,
  useUpdateUserEducationCampaignMutation,
  useUserEducationCampaignQuery,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& .container': {
      '& .header': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiButtonBase-root': {
          marginRight: theme.spacing(1),
        },
      },
      '& .MuiCardHeader-root': {
        paddingBottom: 0,
      },
      '& .details': {
        paddingTop: 0,
        '& > *': {
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
}))

gql`
  query userEducationCampaign($id: ID!) {
    userEducationCampaign(id: $id) {
      ...UserEducationCampaignProperties
    }
  }
  ${fragments.userEducationCampaign}
`

gql`
  mutation createUserEducationCampaign($input: CreateUserEducationCampaignInput!) {
    createUserEducationCampaign(input: $input) {
      ...UserEducationCampaignProperties
    }
  }
  ${fragments.userEducationCampaign}
`

gql`
  mutation updateUserEducationCampaign($input: UpdateUserEducationCampaignInput!) {
    updateUserEducationCampaign(input: $input) {
      ...UserEducationCampaignProperties
    }
  }
  ${fragments.userEducationCampaign}
`

type CampaignProperties = Omit<UserEducationCampaignProperties, '__typename' | 'id'>

// Use CA time zone for all campaigns
export const USER_CAMPAIGN_TIME_ZONE = 'America/Los_Angeles'
const emptyCampaign: CampaignProperties = {
  name: '',
  startDate: moment.tz(USER_CAMPAIGN_TIME_ZONE).toISOString(),
  endDate: moment.tz(USER_CAMPAIGN_TIME_ZONE).toISOString(),
  targetPermissions: [],
  title: '',
  message: '',
  showNewTag: true,
  buttonLabel: '',
  buttonLink: '',
}

/** Interface for editing details of a user campaign, either for creating or updating a campaign */
export default function CreateOrEditUserCampaign() {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()
  const { campaignId } = useParams() as { campaignId?: string }
  const [campaign, setCampaign] = useState<CampaignProperties>(emptyCampaign)
  const [includePermissions, setIncludePermissions] = useState<boolean>(false)
  const { data, loading } = useUserEducationCampaignQuery({
    variables: { id: campaignId ?? '' },
    skip: !campaignId,
  })
  const [createCampaign] = useCreateUserEducationCampaignMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      const newRef = cache.writeFragment({
        data: data.createUserEducationCampaign,
        fragment: fragments.userEducationCampaign,
        fragmentName: 'UserEducationCampaignProperties',
      })

      cache.modify<WritableDeep<Query>>({
        fields: {
          userEducationCampaigns(existingRefs, { toReference }) {
            const refs = toReferences(existingRefs, toReference)
            return _.compact([...refs, newRef])
          },
        },
      })
    },
  })
  const [updateCampaign] = useUpdateUserEducationCampaignMutation()

  // Update the campaign shown if loaded from the server
  useEffect(() => {
    if (data) {
      setCampaign(data.userEducationCampaign)
    }
  }, [data])

  const handleCreateCampaign = async () => {
    try {
      const input = {
        name: campaign.name,
        title: campaign.title,
        message: campaign.message,
        includeNewTag: campaign.showNewTag,
        buttonLabel: campaign.buttonLabel || undefined,
        buttonLink: campaign.buttonLabel && campaign.buttonLink ? campaign.buttonLink : undefined,
        startDate: moment
          .tz(campaign.startDate, USER_CAMPAIGN_TIME_ZONE)
          .startOf('day')
          .toISOString(),
        endDate: moment.tz(campaign.endDate, USER_CAMPAIGN_TIME_ZONE).endOf('day').toISOString(),
        targetPermissions: campaign.targetPermissions,
      }
      if (campaignId) {
        await updateCampaign({ variables: { input: { id: campaignId, info: input } } })
        snackbar.showSuccess('Updated user campaign')
      } else {
        await createCampaign({ variables: { input } })
        snackbar.showSuccess('Created user campaign')
      }
      navigate('/campaigns')
    } catch (err) {
      snackbar.showError(err.message)
    }
  }

  const details = {
    chipLabel: campaign.showNewTag ? 'New' : undefined,
    title: campaign.title,
    message: campaign.message,
    buttonLabel: campaign.buttonLabel ?? undefined,
  }

  return (
    <Page className={classes.root} title="New user campaign">
      <Container maxWidth={false} className="container">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={4} className="header">
            <IconButton onClick={() => navigate('/campaigns')}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">Create campaign</Typography>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12} lg={6}>
            {loading && (
              <Card>
                <Skeleton variant="rectangular" width="100%" height={400} />
              </Card>
            )}
            {!loading && (
              <Card>
                <CardHeader
                  subheader="Details"
                  subheaderTypographyProps={{ variant: 'overline' }}
                />
                <CardContent className="details">
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="Name (internal-only)"
                    value={campaign.name}
                    onChange={(ev) => setCampaign({ ...campaign, name: ev.target.value })}
                    style={{ marginTop: 8 }}
                  />
                  <h3>Popup details</h3>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={campaign.showNewTag}
                        checked={campaign.showNewTag}
                        onChange={(evt) =>
                          setCampaign({ ...campaign, showNewTag: evt.target.checked })
                        }
                      />
                    }
                    label={
                      <span>
                        Show <b>NEW</b> tag
                      </span>
                    }
                  />
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="Title"
                    value={campaign.title}
                    onChange={(ev) => setCampaign({ ...campaign, title: ev.target.value })}
                  />
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={3}
                    size="small"
                    label="Body"
                    value={campaign.message}
                    onChange={(ev) => setCampaign({ ...campaign, message: ev.target.value })}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Button label (optional)"
                    value={campaign.buttonLabel}
                    onChange={(ev) => setCampaign({ ...campaign, buttonLabel: ev.target.value })}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Button link (dismisses if none defined)"
                    value={campaign.buttonLink}
                    onChange={(ev) => setCampaign({ ...campaign, buttonLink: ev.target.value })}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setCampaign(data?.userEducationCampaign ?? emptyCampaign)}
                  >
                    Reset details
                  </Button>
                  <h3>Campaign settings</h3>
                  <SitelineText variant="body2" style={{ display: 'block', marginBottom: 24 }}>
                    Note: Campaigns will run from 12am PT on the start date to 11:59pm PT on the end
                    date
                  </SitelineText>
                  <DatePicker
                    label="Start date"
                    format="MM/DD/YYYY"
                    value={moment.tz(campaign.startDate, USER_CAMPAIGN_TIME_ZONE)}
                    onChange={(date) => {
                      if (!date) {
                        return
                      }
                      setCampaign({ ...campaign, startDate: date.toISOString() })
                    }}
                    slotProps={{ textField: { size: 'small', style: { marginRight: 16 } } }}
                  />
                  <DatePicker
                    label="End date"
                    format="MM/DD/YYYY"
                    value={moment.tz(campaign.endDate, USER_CAMPAIGN_TIME_ZONE)}
                    onChange={(date) => {
                      if (!date) {
                        return
                      }
                      setCampaign({ ...campaign, endDate: date.toISOString() })
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={includePermissions}
                        checked={includePermissions}
                        onChange={(evt) => setIncludePermissions(evt.target.checked)}
                      />
                    }
                    label="Include permissions targeting"
                    style={{ display: 'block' }}
                  />
                  <Collapse in={includePermissions}>
                    <Select
                      multiple
                      size="small"
                      value={[...campaign.targetPermissions]}
                      onChange={(ev) =>
                        setCampaign({
                          ...campaign,
                          targetPermissions: ev.target.value as Permission[],
                        })
                      }
                      renderValue={(selected: Permission[]) => selected.join(', ')}
                      style={{ minWidth: 200 }}
                    >
                      {Object.values(Permission).map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox checked={campaign.targetPermissions.indexOf(option) > -1} />
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                    {campaign.targetPermissions.length === 0 && (
                      <div style={{ fontSize: 14 }}>
                        No permissions selected, targeting all users
                      </div>
                    )}
                    {campaign.targetPermissions.length > 0 && (
                      <div style={{ fontSize: 14 }}>
                        Only targeting users with the {campaign.targetPermissions.length} selected
                        permission(s)
                      </div>
                    )}
                  </Collapse>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateCampaign}
                    disabled={!campaign.title || !campaign.message}
                  >
                    {campaignId ? 'Update campaign' : 'Create campaign'}
                  </Button>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader subheader="Preview" subheaderTypographyProps={{ variant: 'overline' }} />
              <CardContent>
                <SitelineInfoPopup
                  details={details}
                  onButtonClick={() => {
                    if (campaign.buttonLink) {
                      window.open(campaign.buttonLink, '_blank')
                    }
                  }}
                  onDismiss={() => {
                    // Do nothing
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
