import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { DetailedProject } from '../../common/graphql/Fragments'
import { formatLocationOneLine } from '../../common/util/Location'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
}))

export default function ProjectDetailsSubcontractors({ project }: { project: DetailedProject }) {
  const classes = useStyles()

  return (
    <Grid item xs={6}>
      <Card>
        <CardHeader title="Contracts" />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Billing Type</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {project.contracts.map((contract) => (
                <TableRow hover key={contract.company.id}>
                  <TableCell>
                    <Link component={RouterLink} to={`/contracts/${contract.id}`} underline="hover">
                      {contract.company.name}
                    </Link>
                  </TableCell>
                  <TableCell>{contract.billingType}</TableCell>
                  <TableCell>{formatLocationOneLine(contract.selectedAddress)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  )
}
