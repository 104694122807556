import { gql } from '@apollo/client'
import { Card, CardContent, CardHeader, Divider, Grid, Switch, Theme } from '@mui/material'
import _ from 'lodash'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { FIELD_GUEST_EMAILS, FIELD_GUEST_NOTIFICATIONS } from 'siteline-common-all'
import { CompanyUserRole, SitelineTooltip, makeStylesFast } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedUser } from '../../common/graphql/Fragments'
import {
  EmailType,
  NotificationType,
  ProjectProperties,
  useModifyEmailPreferencesMutation,
  useModifyNotificationPreferencesMutation,
  useProjectsByUserQuery,
} from '../../common/graphql/apollo-operations'
import {
  EmailSections,
  NotificationSections,
  getEmailPreferencesSections,
  getNotificationPreferencesSections,
} from '../../common/util/NotificationPreferences'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  preferences: {
    padding: theme.spacing(0, 2),
  },
  options: {
    margin: theme.spacing(3, 0),
  },
  switch: {
    margin: theme.spacing(2, 0),
  },
  section: {
    marginTop: theme.spacing(3),
  },
  row: {
    borderBottom: `1px solid #bfd4d9`,
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

gql`
  mutation modifyNotificationPreferences($input: ModifyNotificationPreferencesInput!) {
    modifyNotificationPreferences(input: $input) {
      ...UserProperties
    }
  }
  ${fragments.user}
`

gql`
  mutation modifyEmailPreferences($input: ModifyEmailPreferencesInput!) {
    modifyEmailPreferences(input: $input) {
      ...UserProperties
    }
  }
  ${fragments.user}
`

type FieldGuestEmail = (typeof FIELD_GUEST_EMAILS)[number]
type FieldGuestNotification = (typeof FIELD_GUEST_NOTIFICATIONS)[number]

function Emails({ user }: { user: DetailedUser; projects: ProjectProperties[] }) {
  const classes = useStyles()

  const [updatePreferences] = useModifyEmailPreferencesMutation()

  const emailSections = useMemo(() => getEmailPreferencesSections(), [])

  const isFieldGuest = useMemo(
    () => user.companyUsers.every(({ role }) => role === CompanyUserRole.FIELD_GUEST),
    [user.companyUsers]
  )

  const userBlockedEmail = useCallback(
    (email: string) => {
      return user.blockedEmails.includes(email as EmailType)
    },
    [user.blockedEmails]
  )

  const emailDisabled = useCallback(
    (email: EmailType) => {
      return isFieldGuest && !FIELD_GUEST_EMAILS.includes(email as FieldGuestEmail)
    },
    [isFieldGuest]
  )

  const sectionDisabled = useCallback(
    (emailSection: keyof EmailSections) => {
      const emails = emailSections[emailSection]
      return emails.every(emailDisabled)
    },
    [emailDisabled, emailSections]
  )

  const userBlockedSection = useCallback(
    (emailSection: keyof EmailSections) => {
      const emails = emailSections[emailSection]
      return emails.every((email) => user.blockedEmails.includes(email as EmailType))
    },
    [emailSections, user.blockedEmails]
  )

  const handleUpdatePreferences = useCallback(
    async (shouldBlock: boolean, emailTypes: EmailType[]) => {
      let newBlockedEmails = [...user.blockedEmails]
      if (shouldBlock) {
        newBlockedEmails = _.union(user.blockedEmails, emailTypes)
      } else {
        newBlockedEmails = _.difference(user.blockedEmails, emailTypes)
      }
      await updatePreferences({
        variables: {
          input: {
            userId: user.id,
            emailTypes,
            block: shouldBlock,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          modifyEmailPreferences: {
            ...user,
            blockedEmails: newBlockedEmails,
          },
        },
      })
    },
    [user, updatePreferences]
  )

  const handleSectionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const shouldBlockSection = !event.target.checked
      const emailSection = event.target.name
      const emailTypes = emailSections[emailSection as keyof EmailSections].filter((email) => {
        // If there exists a section with *some* disabled email types, we don't want to inadvertently
        // turn those on due to this bulk action. If we are switching the whole section on, be sure to not
        // include disabled emails
        if (shouldBlockSection) {
          return true
        }
        return !emailDisabled(email)
      })
      handleUpdatePreferences(shouldBlockSection, emailTypes)
    },
    [emailDisabled, emailSections, handleUpdatePreferences]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const shouldBlockEmail = !event.target.checked
      const emailType = event.target.name as EmailType
      handleUpdatePreferences(shouldBlockEmail, [emailType])
    },
    [handleUpdatePreferences]
  )

  return (
    <div className={classes.preferences}>
      {_.map(emailSections, (emails, emailSection: keyof EmailSections) => {
        const isSectionDisabled = sectionDisabled(emailSection)
        return (
          <div key={emailSection} className={classes.section}>
            <SitelineTooltip
              title={
                isSectionDisabled
                  ? 'This user is a field guest and cannot subscribe to these emails'
                  : ''
              }
            >
              {/* Show the section header as a switch option */}
              <div className={classes.row}>
                <h3 className={classes.options}>{emailSection}</h3>
                <Switch
                  checked={!isSectionDisabled && !userBlockedSection(emailSection)}
                  name={emailSection}
                  onChange={handleSectionChange}
                  className={classes.switch}
                  disabled={isSectionDisabled}
                />
              </div>
            </SitelineTooltip>

            {emails.map((email) => {
              const isEmailDisabled = emailDisabled(email)
              return (
                <SitelineTooltip
                  key={email}
                  title={
                    isEmailDisabled
                      ? 'This user is a field guest and cannot subscribe to this email'
                      : ''
                  }
                >
                  <div className={classes.row}>
                    {/* Show each individual email notification as a switch option */}
                    <p className={classes.options}>{email}</p>
                    <Switch
                      checked={!isEmailDisabled && !userBlockedEmail(email)}
                      name={email}
                      onChange={handleChange}
                      className={classes.switch}
                      disabled={isEmailDisabled}
                    />
                  </div>
                </SitelineTooltip>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

function Notifications({ user }: { user: DetailedUser }) {
  const classes = useStyles()

  const [updatePreferences] = useModifyNotificationPreferencesMutation()

  const notificationSections = useMemo(() => getNotificationPreferencesSections(), [])

  const isFieldGuest = useMemo(
    () => user.companyUsers.every(({ role }) => role === CompanyUserRole.FIELD_GUEST),
    [user.companyUsers]
  )

  const userBlockedNotification = useCallback(
    (notification: string) => {
      return user.blockedNotifications.includes(notification as NotificationType)
    },
    [user.blockedNotifications]
  )

  const notificationDisabled = useCallback(
    (notification: NotificationType) => {
      return (
        isFieldGuest && !FIELD_GUEST_NOTIFICATIONS.includes(notification as FieldGuestNotification)
      )
    },
    [isFieldGuest]
  )

  const sectionDisabled = useCallback(
    (notificationSection: keyof NotificationSections) => {
      const notifications = notificationSections[notificationSection]
      return notifications.every(notificationDisabled)
    },
    [notificationDisabled, notificationSections]
  )

  const userBlockedSection = useCallback(
    (notificationSection: keyof NotificationSections) => {
      const notifications = notificationSections[notificationSection]
      return notifications.every((notification) =>
        user.blockedNotifications.includes(notification as NotificationType)
      )
    },
    [notificationSections, user.blockedNotifications]
  )

  const handleUpdatePreferences = useCallback(
    async (shouldBlock: boolean, notificationTypes: NotificationType[]) => {
      let newBlockedNotifications = [...user.blockedNotifications]
      if (shouldBlock) {
        newBlockedNotifications = _.union(user.blockedNotifications, notificationTypes)
      } else {
        newBlockedNotifications = _.difference(user.blockedNotifications, notificationTypes)
      }

      await updatePreferences({
        variables: {
          input: {
            userId: user.id,
            notificationTypes,
            block: shouldBlock,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          modifyNotificationPreferences: {
            ...user,
            blockedNotifications: newBlockedNotifications,
          },
        },
      })
    },
    [updatePreferences, user]
  )

  const handleSectionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const shouldBlockSection = !event.target.checked
      const notificationSection = event.target.name
      const notificationTypes = notificationSections[
        notificationSection as keyof NotificationSections
      ].filter((notification) => {
        // If there exists a section with *some* disabled notification types, we don't want to inadvertently
        // turn those on due to this bulk action. If we are switching the whole section on, be sure to not
        // include disabled notifications
        if (shouldBlockSection) {
          return true
        }
        return !notificationDisabled(notification)
      })
      handleUpdatePreferences(shouldBlockSection, notificationTypes)
    },
    [handleUpdatePreferences, notificationDisabled, notificationSections]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const shouldBlockNotification = !event.target.checked
      const notificationType = event.target.name as NotificationType
      handleUpdatePreferences(shouldBlockNotification, [notificationType])
    },
    [handleUpdatePreferences]
  )

  return (
    <div className={classes.preferences}>
      {_.map(
        notificationSections,
        (notifications, notificationSection: keyof NotificationSections) => {
          const isSectionDisabled = sectionDisabled(notificationSection)
          return (
            <div key={notificationSection} className={classes.section}>
              <SitelineTooltip
                title={
                  isSectionDisabled
                    ? 'This user is a field guest and cannot subscribe to this group of notifications'
                    : ''
                }
              >
                {/* Show the section header as a switch option */}
                <div className={classes.row}>
                  <h3 className={classes.options}>{notificationSection}</h3>
                  <Switch
                    checked={!isSectionDisabled && !userBlockedSection(notificationSection)}
                    name={notificationSection}
                    onChange={handleSectionChange}
                    className={classes.switch}
                    disabled={isSectionDisabled}
                  />
                </div>
              </SitelineTooltip>

              {notifications.map((notification) => {
                const isNotificationDisabled = notificationDisabled(notification)
                return (
                  <SitelineTooltip
                    key={notification}
                    title={
                      isNotificationDisabled
                        ? 'This user is a field guest and cannot subscribe to this notification'
                        : ''
                    }
                  >
                    <div className={classes.row}>
                      {/* Show each individual notification notification as a switch option */}
                      <p className={classes.options}>{notification}</p>
                      <Switch
                        checked={!isNotificationDisabled && !userBlockedNotification(notification)}
                        name={notification}
                        onChange={handleChange}
                        className={classes.switch}
                        disabled={isNotificationDisabled}
                      />
                    </div>
                  </SitelineTooltip>
                )
              })}
            </div>
          )
        }
      )}
    </div>
  )
}

export default function UserDetailsNotificationPreferences({ user }: { user: DetailedUser }) {
  const classes = useStyles()

  const { data, error, loading } = useProjectsByUserQuery({
    variables: { id: user.id },
  })

  if (loading || error || !data) {
    return null
  }

  return (
    <>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardHeader title="Email Preferences" />
          <Divider />
          <CardContent className={classes.cardContent}>
            <Emails user={user} projects={[...data.projectsByUser]} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardHeader title="Notification Preferences" />
          <Divider />
          <CardContent className={classes.cardContent}>
            <Notifications user={user} />
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
