import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Autocomplete, Grid, TextField, Theme, Typography } from '@mui/material'
import parse from 'autosuggest-highlight/parse'
import _ from 'lodash'
import throttle from 'lodash/throttle'
import { useEffect, useMemo, useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { config } from '../../common/config/constants'
import { LocationInput } from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

const googleMapsLoader = new GoogleMapsLoader({
  apiKey: config.googleMapsApiKey,
  libraries: ['places'],
})

const placesLibrary = googleMapsLoader.importLibrary('places')
const autocompleteService = placesLibrary.then((places) => {
  return new places.AutocompleteService()
})

const fakeMap = document.createElement('div')
const placesService = placesLibrary.then((places) => {
  return new places.PlacesService(fakeMap)
})

export const geocodingService = googleMapsLoader.importLibrary('geocoding').then((geocoding) => {
  return new geocoding.Geocoder()
})

/**
 * Searches a component list for a component with a type, returning the first match from
 * the given the list of types
 */
function getComponentForTypes(
  components: google.maps.GeocoderAddressComponent[],
  types: string[]
): google.maps.GeocoderAddressComponent | undefined {
  return _.chain(types)
    .map((type) => components.find((component) => component.types.includes(type)))
    .compact()
    .first()
    .value()
}

interface LocationAutocompleteProps {
  location: LocationInput | null
  setLocation: (location: LocationInput) => void
}

/* Taken from https://material-ui.com/components/autocomplete/#google-maps-place */
export function LocationAutocomplete({ location, setLocation }: LocationAutocompleteProps) {
  const classes = useStyles()
  const [locationInputValue, setLocationInputValue] = useState('')
  const [locationOptions, setLocationOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  const [selectedOption, setSelectedOption] =
    useState<google.maps.places.AutocompletePrediction | null>(null)

  const onOptionSelected = (option: google.maps.places.AutocompletePrediction | null) => {
    if (!option) {
      return setSelectedOption(null)
    }
    setSelectedOption(option)
    placesService.then((service) => {
      const request = {
        placeId: option.place_id,
        fields: ['address_components', 'geometry'],
      }
      service.getDetails(request, (result) => {
        if (!result) {
          return
        }

        const components = result.address_components
        const geometry = result.geometry
        if (!components || !geometry) {
          return
        }
        const streetNumber = getComponentForTypes(components, ['street_number'])
        const street = getComponentForTypes(components, ['route', 'premise', 'town_square'])
        const subpremise = getComponentForTypes(components, ['subpremise'])
        const city = getComponentForTypes(components, ['locality', 'sublocality'])
        const county = getComponentForTypes(components, ['administrative_area_level_2'])
        const state = getComponentForTypes(components, ['administrative_area_level_1'])
        const country = getComponentForTypes(components, ['country'])
        const postalCode = getComponentForTypes(components, ['postal_code'])
        const coordinates = geometry.location

        // There must be a city, state, and country specified
        if (!city || !state || !country || !coordinates) {
          return
        }

        let street1: string | undefined = undefined
        if (streetNumber) {
          street1 = streetNumber.long_name
        }
        if (street) {
          street1 = street1 ? `${street1} ${street.long_name}` : `${street.long_name}`
        }

        setLocation({
          ...location,
          nickname: option.structured_formatting.main_text,
          street1,
          street2: subpremise ? `#${subpremise.long_name}` : null,
          city: city.long_name,
          county: county?.long_name ?? null,
          state: state.short_name,
          country: country.long_name,
          postalCode: postalCode?.long_name,
          coordinates: {
            latitude: coordinates.lat(),
            longitude: coordinates.lng(),
          },
        })
      })
    })
  }

  const fetchLocationOptions = useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (
            result: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) => void
        ) => {
          autocompleteService.then((service) => {
            service.getPlacePredictions(request, callback)
          })
        },
        200
      ),
    []
  )

  useEffect(() => {
    let active = true

    if (locationInputValue === '') {
      setLocationOptions([])
      return
    }

    fetchLocationOptions(
      { input: locationInputValue },
      // Explicitly add the null check because this is nullable (despite what typescript says)
      (results: google.maps.places.AutocompletePrediction[] | null) => {
        if (active) {
          setLocationOptions(results ?? [])
        }
      }
    )

    return () => {
      active = false
    }
  }, [locationInputValue, fetchLocationOptions])

  return (
    <Autocomplete
      id="company-location"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={locationOptions}
      autoComplete
      includeInputInList
      onChange={(ev, value) => onOptionSelected(value)}
      value={selectedOption}
      multiple={false}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Address"
          fullWidth
          onChange={(ev) => setLocationInputValue(ev.target.value)}
          className={classes.input}
        />
      )}
      renderOption={(props, option: google.maps.places.AutocompletePrediction) => {
        // Explicitly allow main_text_matched_substrings to be undefined because it can be (despite
        // what typecheck says). If it is undefined, assume the entire main_text is not highlighted
        const matches = option.structured_formatting.main_text_matched_substrings as
          | google.maps.places.PredictionSubstring[]
          | undefined
        let parts: { text: string; highlight: boolean }[] = []
        if (matches) {
          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          )
        } else {
          parts = [{ text: option.structured_formatting.main_text, highlight: false }]
        }

        return (
          <li {...props} key={option.place_id}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
