import { Container, Grid, Theme, Typography } from '@mui/material'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import DemoToolsMarkPayAppsAsPaid from './DemoMarkPayAppsAsPaid'
import DemoToolsDeleteLienWaivers from './DemoToolsDeleteLienWaivers'
import DemoToolsSubmitLienWaivers from './DemoToolsSubmitLienWaivers'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}))

/**
 * Tools for demo purposes (AEs)
 */
export default function DemoTools() {
  const classes = useStyles()
  return (
    <Page title="Demo tools" className={classes.root}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={4} className="header">
            <Typography variant="h4">Demo tools</Typography>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <DemoToolsMarkPayAppsAsPaid />
          </Grid>
          <Grid item xs={4}>
            <DemoToolsDeleteLienWaivers />
          </Grid>
          <Grid item xs={4}>
            <DemoToolsSubmitLienWaivers />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
