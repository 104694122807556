import { ApolloCache, DefaultContext, gql } from '@apollo/client'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ChipProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectOnboardingFormType } from 'siteline-common-all'
import {
  FormTemplateTag,
  SitelineText,
  SitelineTooltip,
  colors,
  makeStylesFast,
  useDebouncedSearch,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  EditableCardRowCompany,
  EditableCardRowDate,
  EditableCardRowSelect,
  EditableCardRowText,
} from '../../common/components/EditableCardRow'
import { TemplateSearchIcon } from '../../common/components/Icons'
import IdentifierRow from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  FormTemplateType,
  UpdateFormTemplateDueDateMutation,
  UpdateFormTemplateDueDateMutationVariables,
  UpdateFormTemplateMutationVariables,
  useCloneFormTemplateMutation,
  useContractsForAutocompleteQuery,
  useDeleteFormTemplateMutation,
  useSetFormTemplateTagsMutation,
  useUpdateFormTemplateAssociatedCompanyMutation,
  useUpdateFormTemplateAssociatedContractsMutation,
  useUpdateFormTemplateDueDateMutation,
  useUpdateFormTemplateMutation,
  useUpdateFormTemplateRequestingCompanyMutation,
} from '../../common/graphql/apollo-operations'
import {
  finishedFormTemplateStatus,
  getTemplateTagPrettyName,
} from '../../common/util/FormTemplate'
import { FormTemplateStatusChip } from '../FormTemplateStatusChip'
import { FormTemplateForDetails } from './FormTemplateDetails'

const useStyles = makeStylesFast((theme: Theme) => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  formTemplate: {
    width: '100%',
  },
  actions: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  chip: {
    marginRight: theme.spacing(2),
  },
  dueDateRow: {
    '& .MuiInputBase-root': {
      backgroundColor: colors.white,
    },
    '&.isAlmostDue': {
      backgroundColor: colors.yellow20,
    },
    '&.isDue': {
      backgroundColor: colors.red20,
    },
    '&.isPastDue': {
      backgroundColor: colors.red30,
    },
  },
}))

gql`
  query contractsForAutocomplete($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      contracts {
        id
        daysBeforePayAppDue
        company {
          id
          name
        }
        project {
          id
          name
          timeZone
          metadata {
            payAppDueOnDayOfMonth
          }
        }
      }
    }
  }
`

gql`
  mutation updateFormTemplate($input: UpdateFormTemplateInput!) {
    updateFormTemplate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateAssociatedCompany($input: UpdateFormTemplateAssociatedCompanyInput!) {
    updateFormTemplateAssociatedCompany(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateRequestingCompany($input: UpdateFormTemplateRequestingCompanyInput!) {
    updateFormTemplateRequestingCompany(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateAssociatedContracts(
    $input: UpdateFormTemplateAssociatedContractsInput!
  ) {
    updateFormTemplateAssociatedContracts(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation deleteFormTemplate($id: ID!) {
    deleteFormTemplate(id: $id) {
      id
    }
  }
`

gql`
  mutation cloneFormTemplate($input: CloneFormTemplateInput!) {
    cloneFormTemplate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation setFormTemplateTags($input: SetFormTemplateTagsInput!) {
    setFormTemplateTags(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

type CloneFormTemplateDialogProps = {
  open: boolean
  onClose: () => void
  formTemplate: FormTemplateProperties
}

/**
 * Dialog that allows cloning a form template with a new name + original file
 */
export function CloneFormTemplateDialog({
  open,
  onClose,
  formTemplate,
}: CloneFormTemplateDialogProps) {
  const snackbar = useSitelineSnackbar()
  const [cloneFormTemplateMutation] = useCloneFormTemplateMutation()
  const [name, setName] = useState<string>(`${formTemplate.userVisibleName} (copy)`)
  const [file, setFile] = useState<File | null>(null)

  const onClone = () => {
    if (!window.confirm('Are you sure you want to clone this template?')) {
      return
    }
    snackbar.showLoading()
    cloneFormTemplateMutation({
      variables: {
        input: {
          id: formTemplate.id,
          userVisibleName: name,
          originalFile: file,
        },
      },
    })
      .then((data) => {
        window.open(`/templates/${data.data?.cloneFormTemplate.id}`, '_blank')
        snackbar.showSuccess()
        onClose()
      })
      .catch((err: Error) => snackbar.showError(err.message))
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Clone template</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={(ev) => setName(ev.currentTarget.value)}
          label="User-visible name"
          fullWidth
          sx={{ marginTop: 1 }}
        />
        <input
          type="file"
          onChange={(ev) => setFile(ev.target.files?.[0] ?? null)}
          style={{ marginTop: 16 }}
        />
        <Alert severity="info" sx={{ marginTop: 2 }}>
          This will duplicate everything on this template, including versions, annotations,
          comments, and build status.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onClone} variant="contained" color="primary">
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type FormTemplateInfoProps = {
  formTemplate: FormTemplateForDetails
}

export default function FormTemplateInfo({ formTemplate }: FormTemplateInfoProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()
  const [isContractsAutocompleteOpen, setIsContractsAutocompleteOpen] = useState<boolean>(false)

  const { search, debouncedSearch, onSearch } = useDebouncedSearch()
  const { data: contractsData, loading: contractsLoading } = useContractsForAutocompleteQuery({
    variables: {
      input: {
        search: debouncedSearch,
        limit: 10,
      },
    },
    skip: !isContractsAutocompleteOpen || debouncedSearch.length < 3,
  })
  const [updateFormTemplateMutation] = useUpdateFormTemplateMutation()
  const [deleteFormTemplateMutation] = useDeleteFormTemplateMutation()
  const [setFormTemplateTagsMutation] = useSetFormTemplateTagsMutation()
  const [updateAssociatedCompany] = useUpdateFormTemplateAssociatedCompanyMutation()
  const [updateRequestingCompany] = useUpdateFormTemplateRequestingCompanyMutation()
  const [updateAssociatedContracts] = useUpdateFormTemplateAssociatedContractsMutation()
  const [updateDueDate] = useUpdateFormTemplateDueDateMutation()
  const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
  const availableTags = Object.values(FormTemplateTag).sort()
  const associatedContracts = useMemo(
    () => formTemplate.associatedContracts,
    [formTemplate.associatedContracts]
  )
  const associatedContractIds = useMemo(
    () => _.uniq(associatedContracts.map(({ contract }) => contract.id)),
    [associatedContracts]
  )

  const contractOptions = useMemo(() => {
    const autocompletedContracts = contractsData?.paginatedContracts.contracts ?? []
    const associatedContracts = formTemplate.associatedContracts.map(({ contract }) => contract)
    return _.uniqBy([...associatedContracts, ...autocompletedContracts], (contract) => contract.id)
  }, [contractsData?.paginatedContracts.contracts, formTemplate.associatedContracts])
  const contractOptionIDs = useMemo(() => contractOptions.map(({ id }) => id), [contractOptions])

  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to delete this template?')) {
      return
    }
    snackbar.showLoading()
    deleteFormTemplateMutation({
      variables: {
        id: formTemplate.id,
      },
    })
      .then(() => {
        snackbar.showSuccess()
        navigate('/templates')
      })
      .catch((err: Error) => snackbar.showError(err.message))
  }

  const handleNewTags = (newTags: FormTemplateTag[]) => {
    setFormTemplateTagsMutation({
      variables: {
        input: {
          id: formTemplate.id,
          tags: newTags,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setFormTemplateTags: {
          ...formTemplate,
          tags: newTags,
        },
      },
    })
      .then(() => snackbar.showSuccess('Tags updated'))
      .catch((err) => snackbar.showError(err.message))
  }

  const handleAssociatedContracts = (newContractIds: string[]) => {
    const newContracts = contractOptions.filter((contract) => newContractIds.includes(contract.id))
    updateAssociatedContracts({
      variables: {
        input: {
          id: formTemplate.id,
          contractIds: newContractIds,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateFormTemplateAssociatedContracts: {
          ...formTemplate,
          associatedContracts: newContracts.map((contract) => ({
            __typename: 'FormTemplateAssociatedContract',
            id: uuidv4(),
            providedAsFormType: null,
            contract: {
              __typename: 'Contract',
              id: contract.id,
            },
          })),
        },
      },
    })
      .then(() => snackbar.showSuccess('Associated contracts updated'))
      .catch((err) => snackbar.showError(err.message))
  }

  const isFinished = finishedFormTemplateStatus.includes(formTemplate.status)
  const daysUntilDue = useMemo(() => {
    if (!formTemplate.dueDate) {
      return null
    }
    const timeZone = moment.tz.guess()
    const dueDate = moment.tz(formTemplate.dueDate, timeZone)
    return Math.ceil(dueDate.diff(moment.tz(timeZone), 'days', true))
  }, [formTemplate.dueDate])
  const [isAlmostDue, isDue, isPastDue, dueDateChipColor] = useMemo(() => {
    if (daysUntilDue === null || isFinished) {
      return [false, false, false]
    }
    const [almostDue, due, pastDue] = [daysUntilDue <= 3, daysUntilDue <= 1, daysUntilDue < 0]
    let chipColor: ChipProps['color'] = 'default'
    if (pastDue) {
      chipColor = 'error'
    } else if (due) {
      chipColor = 'warning'
    } else if (almostDue) {
      chipColor = 'secondary'
    }
    return [almostDue, due, pastDue, chipColor]
  }, [daysUntilDue, isFinished])
  const dueFromNow = useMemo(() => {
    if (daysUntilDue === null) {
      return ''
    } else if (daysUntilDue === 0) {
      return 'Due today'
    } else if (daysUntilDue === 1) {
      return 'Due tomorrow'
    } else if (daysUntilDue > 0) {
      return `Due in ${daysUntilDue} days`
    } else if (daysUntilDue === -1) {
      return 'Due yesterday'
    } else if (daysUntilDue < 0) {
      return `Due ${-daysUntilDue} days ago`
    } else {
      return ''
    }
  }, [daysUntilDue])

  const getAssociatedContractById = useCallback(
    (contractId: string) => {
      const contract = contractOptions.find((contract) => contract.id === contractId)
      if (!contract) {
        throw new Error(`Could not find contract with id ${contractId}`)
      }
      return contract
    },
    [contractOptions]
  )

  return (
    <Card>
      <CardHeader
        title="Overview"
        action={
          <div>
            <FormTemplateStatusChip
              status={formTemplate.status}
              skippedValidation={formTemplate.skippedValidation}
            />
          </div>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableBody>
            <IdentifierRow id={formTemplate.id} />
            <EditableCardRowText
              readOnly={false}
              label="Template Name"
              value={formTemplate.userVisibleName}
              mutate={updateFormTemplateMutation}
              variables={(value) =>
                ({
                  input: {
                    id: formTemplate.id,
                    userVisibleName: value,
                  },
                }) as UpdateFormTemplateMutationVariables
              }
            />
            <EditableCardRowSelect
              readOnly={isFinished}
              label="Type"
              value={formTemplate.type}
              options={_.values(FormTemplateType).map((type) => ({ key: type, value: type }))}
              mutate={updateFormTemplateMutation}
              variables={(value: FormTemplateType) => ({
                input: {
                  id: formTemplate.id,
                  type: value,
                },
              })}
            />
            {formTemplate.dueDate && (
              <EditableCardRowDate<
                UpdateFormTemplateDueDateMutation,
                UpdateFormTemplateDueDateMutationVariables,
                DefaultContext,
                ApolloCache<unknown>
              >
                readOnly={isFinished}
                label="Due date"
                value={formTemplate.dueDate}
                timeZone={moment.tz.guess()}
                mutate={updateDueDate}
                variables={(value) => ({
                  input: {
                    id: formTemplate.id,
                    dueDate: value || null,
                  },
                })}
                className={clsx(classes.dueDateRow, {
                  isAlmostDue,
                  isDue,
                  isPastDue,
                })}
                nonEditingEndAdornment={
                  isFinished ? undefined : (
                    <Chip variant="filled" label={dueFromNow} color={dueDateChipColor} />
                  )
                }
                clearable
              />
            )}
            <EditableCardRowCompany
              readOnly={false}
              label={
                <SitelineText
                  variant="body2"
                  endIcon={
                    <Tooltip
                      title="The subcontractor, GC, or owner that created the original form. For example, the AGA G702's associated company is AGA while the Webcor Cover Sheet's associated company would be Webcor (not the sub that sent us the form to onboard)."
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon fontSize="small" sx={{ color: colors.grey50 }} />
                    </Tooltip>
                  }
                >
                  Company originated from
                </SitelineText>
              }
              value={formTemplate.associatedCompany?.id ?? ''}
              mutate={updateAssociatedCompany}
              variables={async (associatedCompanyId) => {
                return {
                  input: {
                    id: formTemplate.id,
                    associatedCompanyId: associatedCompanyId ?? '',
                  },
                }
              }}
            />
            <EditableCardRowCompany
              readOnly={false}
              label={
                <SitelineText
                  variant="body2"
                  endIcon={
                    <Tooltip
                      title="Which company, if any, requested this form template to be built"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon fontSize="small" sx={{ color: colors.grey50 }} />
                    </Tooltip>
                  }
                >
                  Requested by
                </SitelineText>
              }
              value={formTemplate.requestingCompany?.id ?? ''}
              mutate={updateRequestingCompany}
              variables={async (requestingCompanyId) => {
                return {
                  input: {
                    id: formTemplate.id,
                    requestingCompanyId: requestingCompanyId ?? '',
                  },
                }
              }}
            />
            <TableRow>
              <TableCell>
                <SitelineText
                  variant="body2"
                  endIcon={
                    <Tooltip
                      title="Projects that this template is being built for. The form will not be automatically added to them when complete."
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon fontSize="small" sx={{ color: colors.grey50 }} />
                    </Tooltip>
                  }
                >
                  Projects
                </SitelineText>
              </TableCell>
              <TableCell colSpan={2} style={{ maxWidth: 300 }}>
                <Autocomplete
                  multiple
                  open={isContractsAutocompleteOpen}
                  onOpen={() => setIsContractsAutocompleteOpen(true)}
                  onClose={() => setIsContractsAutocompleteOpen(false)}
                  loading={contractsLoading}
                  options={contractOptionIDs}
                  inputValue={search}
                  onInputChange={(ev, inputValue) => onSearch(inputValue)}
                  filterOptions={(option) => option}
                  value={associatedContractIds}
                  onChange={(event, value) => handleAssociatedContracts(value)}
                  // Required to provide an explicit child key
                  // See https://stackoverflow.com/a/69396153
                  renderOption={(props, contractId) => {
                    const contract = getAssociatedContractById(contractId)
                    return (
                      <li {...props} key={contractId}>
                        {contract.project.name} • {contract.company.name}
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="standard"
                      placeholder="Select projects"
                      InputProps={{ ...params.InputProps, disableUnderline: true }}
                    />
                  )}
                  getOptionLabel={(contractId) => {
                    const contract = getAssociatedContractById(contractId)
                    return `${contract.project.name} • ${contract.company.name}`
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((contractId, index) => {
                      const associatedContract = formTemplate.associatedContracts.find(
                        ({ contract }) => contract.id === contractId
                      )
                      let typeTooltip = ''
                      if (associatedContract?.providedAsFormType) {
                        switch (associatedContract.providedAsFormType) {
                          case ProjectOnboardingFormType.PAY_APP:
                            typeTooltip = 'Provided as pay app form'
                            break
                          case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
                            typeTooltip = 'Provided as primary lien waiver form'
                            break
                          case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
                            typeTooltip = 'Provided as vendor lien waiver form'
                            break
                          case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
                            typeTooltip = 'Provided as COR form'
                            break
                          case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
                            typeTooltip = 'Provided as COR log'
                            break
                          case ProjectOnboardingFormType.COMPLIANCE:
                            typeTooltip = 'Provided as compliance form'
                            break
                        }
                      }
                      const contract = getAssociatedContractById(contractId)
                      const payAppDueOnDayOfMonth = contract.project.metadata.payAppDueOnDayOfMonth
                      const billingDay = moment
                        .tz(contract.project.timeZone)
                        .date(payAppDueOnDayOfMonth)
                        .subtract(contract.daysBeforePayAppDue, 'days')
                        .format('Do')
                      return (
                        <SitelineTooltip
                          key={contract.id}
                          title={
                            <div>
                              <div>{typeTooltip}</div>
                              <div>Pay apps due on the {billingDay} of the month</div>
                            </div>
                          }
                          disableInteractive
                        >
                          <Chip
                            variant="outlined"
                            size="small"
                            label={contract.project.name}
                            {...getTagProps({ index })}
                            onDelete={(evt) => {
                              // Prevent following the link when deleting
                              evt.preventDefault()
                              getTagProps({ index }).onDelete(evt)
                            }}
                            clickable
                            component="a"
                            target="_blank"
                            href={`/contracts/${contract.id}`}
                            className={classes.chip}
                          />
                        </SitelineTooltip>
                      )
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tags</TableCell>
              <TableCell colSpan={2} style={{ maxWidth: 300 }}>
                <Autocomplete
                  multiple
                  options={availableTags}
                  value={[...formTemplate.tags]}
                  onChange={(event, value) => handleNewTags(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="standard"
                      placeholder="Select tags"
                      InputProps={{ ...params.InputProps, disableUnderline: true }}
                    />
                  )}
                  getOptionLabel={(option) => getTemplateTagPrettyName(option)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        label={getTemplateTagPrettyName(option)}
                        {...getTagProps({ index })}
                        key={option}
                      />
                    ))
                  }
                />
              </TableCell>
            </TableRow>
            {formTemplate.duplicateTemplate && (
              <TableRow>
                <TableCell>Duplicate of</TableCell>
                <TableCell colSpan={2}>
                  <Link target="_blank" href={`/templates/${formTemplate.id}`}>
                    {formTemplate.duplicateTemplate.userVisibleName}
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        <Button
          startIcon={<DeleteForeverOutlinedIcon />}
          color="secondary"
          onClick={() => handleDelete()}
        >
          Delete template
        </Button>
        <Button
          startIcon={<ContentCopyIcon />}
          color="secondary"
          onClick={() => setCloneDialogOpen(true)}
        >
          Clone Template
        </Button>
        {!isFinished && (
          <Button
            startIcon={<TemplateSearchIcon />}
            color="secondary"
            onClick={() => {
              window.open(
                `/templates/match?formTemplateId=${formTemplate.id}&useOriginalFile=true`,
                '_blank'
              )
            }}
          >
            Find duplicate forms
          </Button>
        )}
      </CardActions>

      <CloneFormTemplateDialog
        open={cloneDialogOpen}
        onClose={() => setCloneDialogOpen(false)}
        formTemplate={formTemplate}
      />
    </Card>
  )
}
