import { gql } from '@apollo/client'
import EditIcon from '@mui/icons-material/Edit'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import {
  Alert,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { SitelineInfoPopup, colors, makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import { useUserEducationCampaignsQuery } from '../../common/graphql/apollo-operations'
import { USER_CAMPAIGN_TIME_ZONE } from './CreateOrEditUserCampaign'

gql`
  query userEducationCampaigns {
    userEducationCampaigns {
      ...UserEducationCampaignProperties
    }
  }
  ${fragments.userEducationCampaign}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  tooltip: {
    padding: 0,
    backgroundColor: 'transparent',
  },
}))

function doNothing() {
  // No-op
}

/** Admin view of all existing user campaigns, including active, expired, and future campaigns */
export default function UserCampaigns() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { data, error, loading } = useUserEducationCampaignsQuery()

  const sortedCampaigns =
    data && _.orderBy(data.userEducationCampaigns, (campaign) => campaign.startDate, 'desc')

  return (
    <Page className={classes.root} title="Campaigns">
      <Container maxWidth={false}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4">Campaigns</Typography>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/campaigns/create">
              <Button variant="contained" color="primary">
                New campaign
              </Button>
            </Link>
          </Grid>
        </Grid>
        {sortedCampaigns && (
          <TableContainer component={Paper} style={{ marginTop: 24 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Start date</TableCell>
                  <TableCell>End date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Preview</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedCampaigns.map((campaign) => {
                  const startDate = moment.tz(campaign.startDate, USER_CAMPAIGN_TIME_ZONE)
                  const endDate = moment.tz(campaign.endDate, USER_CAMPAIGN_TIME_ZONE)
                  let status = ''
                  let statusColor = ''
                  if (
                    moment.tz(USER_CAMPAIGN_TIME_ZONE).isBetween(startDate, endDate, 'date', '[]')
                  ) {
                    status = 'Active'
                    statusColor = colors.green70
                  } else if (moment.tz(USER_CAMPAIGN_TIME_ZONE).isAfter(endDate)) {
                    status = 'Ended'
                    statusColor = colors.red70
                  }
                  return (
                    <TableRow key={campaign.id}>
                      <TableCell>{campaign.name}</TableCell>
                      <TableCell>{startDate.format('MMM Do, YYYY')}</TableCell>
                      <TableCell>{endDate.format('MMM Do, YYYY')}</TableCell>
                      <TableCell style={{ color: statusColor, fontWeight: 'bold' }}>
                        {status}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <SitelineInfoPopup
                              details={{
                                title: campaign.title,
                                message: campaign.message,
                                chipLabel: campaign.showNewTag ? 'NEW' : undefined,
                                buttonLabel: campaign.buttonLabel ?? undefined,
                              }}
                              onButtonClick={doNothing}
                              onDismiss={doNothing}
                            />
                          }
                          classes={{ tooltip: classes.tooltip }}
                          placement="bottom-start"
                        >
                          <div>
                            <HighlightAltIcon />
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => navigate(`/campaigns/${campaign.id}`)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
