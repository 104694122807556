import { gql } from '@apollo/client'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import CheckIcon from '@mui/icons-material/Check'
import {
  Alert,
  Button,
  Container,
  Grid,
  IconButton,
  IconButtonProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { MIN_DAYS_PENDING_SYNC_OVERDUE, integrationTypes } from 'siteline-common-all'
import { colors, getIntegrationWebsite, makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import { useDeferredWriteSyncOperationsQuery } from '../../common/graphql/apollo-operations'

gql`
  query deferredWriteSyncOperations {
    deferredWriteSyncOperations {
      id
      createdAt
      integration {
        id
        type
        mappings
        contract {
          id
          company {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
      payload
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  tooltip: {
    padding: 0,
    backgroundColor: 'transparent',
  },
}))

export function CopyIdButton({ id, ...rest }: { id: string } & IconButtonProps) {
  const [didCopy, setDidCopy] = useState<boolean>(false)
  const onCopy = () => {
    copy(id)
    setDidCopy(true)
    setTimeout(() => setDidCopy(false), 5000)
  }
  return (
    <Tooltip title="Copy ID" placement="top" arrow>
      <IconButton size="small" onClick={onCopy} {...rest}>
        {didCopy ? <CheckIcon htmlColor="#2ecc71" /> : <AssignmentOutlinedIcon />}
      </IconButton>
    </Tooltip>
  )
}

/** Admin view of all pending sync requests across all customers and projects */
export default function PendingIntegrationSyncs() {
  const classes = useStyles()
  const { data, error, loading } = useDeferredWriteSyncOperationsQuery()

  const sortedOperations = useMemo(() => {
    if (!data) {
      return []
    }
    const sorted = _.orderBy(
      data.deferredWriteSyncOperations,
      (operation) => moment.utc(operation.createdAt),
      'asc'
    )
    return sorted.map((operation) => ({
      ...operation,
      payload: operation.payload as integrationTypes.WriteSyncPayload,
    }))
  }, [data])

  const numLateRequests = useMemo(() => {
    return _.sumBy(sortedOperations, (operation) => {
      return moment.utc().diff(moment.utc(operation.createdAt), 'days') >=
        MIN_DAYS_PENDING_SYNC_OVERDUE
        ? 1
        : 0
    })
  }, [sortedOperations])

  return (
    <Page className={classes.root} title="Campaigns">
      <Container maxWidth={false}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4">Pending sync requests</Typography>
          </Grid>
          <Grid item>
            {numLateRequests > 0 && (
              <Alert severity="error">
                There are {numLateRequests} sync requests that have been pending for more than{' '}
                {MIN_DAYS_PENDING_SYNC_OVERDUE - 1} days
              </Alert>
            )}
            {numLateRequests === 0 && (
              <Alert severity="info">
                There are no sync requests that have been pending for more than{' '}
                {MIN_DAYS_PENDING_SYNC_OVERDUE - 1} days
              </Alert>
            )}
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 24 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Triggered</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>GC Portal</TableCell>
                <TableCell>Type</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedOperations.map((operation) => {
                const contract = operation.integration.contract
                const createdAt = moment.utc(operation.createdAt)
                const daysSince = createdAt.fromNow()
                const showWarning =
                  moment.utc().diff(createdAt, 'days') >= MIN_DAYS_PENDING_SYNC_OVERDUE
                return (
                  <TableRow
                    key={operation.id}
                    style={{ backgroundColor: showWarning ? colors.red10 : undefined }}
                  >
                    <TableCell style={{ minWidth: 130 }}>
                      <div>{createdAt.format('MMM D')}</div>
                      <div
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {daysSince}
                      </div>
                    </TableCell>
                    <TableCell>{contract.company.name}</TableCell>
                    <TableCell>{contract.project.name}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          const thirdPartyUrl = getIntegrationWebsite(operation.integration)
                          if (thirdPartyUrl) {
                            window.open(thirdPartyUrl, '_blank')
                          }
                        }}
                      >
                        {operation.integration.type}
                      </Button>
                    </TableCell>
                    <TableCell>{operation.payload.type}</TableCell>
                    <TableCell>
                      <CopyIdButton id={operation.id} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
