import { IntegrationType } from '../enums.js'

/**
 * The minimum number of days at which we consider a pending integration sync overdue.
 * Should be kept in sync with the copy in the Slack notification from the analytics event.
 */
export const MIN_DAYS_PENDING_SYNC_OVERDUE = 8

// This is a limit imposed by Sage, see https://github.com/siteline/siteline/issues/9034
export const MAX_SAGE_INVOICE_CODE_LENGTH = 15

// This is a limit imposed by Spectrum (per error received when attempting to sync longer #)
export const MAX_SPECTRUM_INVOICE_CODE_LENGTH = 10

// This is a limit imposed by Spectrum (per error received when attempting to sync longer #)
export const MAX_SPECTRUM_BATCH_NUMBER_LENGTH = 10

// This is a limit imposed by Sage (seems to be the max by trial and error)
export const MAX_SAGE_300_DRAW_NUMBER_LENGTH = 15

// The maximum number of characters supported for a Sage Intacct reference number
// the reference number will be cut off after this number of characters
export const MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH = 120

// Based on maxing out the possible length in the Sage Intacct UI
export const MAX_SAGE_INTACCT_BILLING_APPLICATION_NO_LENGTH = 100

// This is a limit imposed by Foundation (per error received when attempting to sync longer #)
export const MAX_FOUNDATION_INVOICE_NUMBER_LENGTH = 10

// This is a limit imposed by Vista (per error received when attempting to sync longer #)
export const MAX_VISTA_INVOICE_NUMBER_LENGTH = 10

// Around this date, we started recording all pay app ERP syncs so that we could surface ERP
// sync statuses in the UI. We opted to use this cut-off date as a reference since we don’t
// know the sync status of pay apps created prior to this date. Pay apps created prior to this
// date are treated as already synced across all surfaces using this variable, meaning a need
// to sync is *not* surfaced for them. This is a safer assumption to prevent incorrect or
// misleading sync statuses from being surfaced.
export const ERP_SYNC_FIELD_INTRODUCED_AT = '2024-09-23T23:00:00Z'

export function getIntegrationInvoiceCodeMaxLength(
  integrationType: IntegrationType
): number | null {
  switch (integrationType) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return MAX_SAGE_INVOICE_CODE_LENGTH
    case IntegrationType.SPECTRUM:
      return MAX_SPECTRUM_INVOICE_CODE_LENGTH
    case IntegrationType.FOUNDATION:
      return MAX_FOUNDATION_INVOICE_NUMBER_LENGTH
    case IntegrationType.VISTA:
      return MAX_VISTA_INVOICE_NUMBER_LENGTH
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.SAGE_INTACCT:
      return null
  }
}
