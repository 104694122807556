import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { VariantAutocomplete } from '../../common/components/VariantAutocomplete'
import {
  FormTemplateType,
  SubcontractorLienWaiverTemplateSetProperties,
  useTemplateVariantForAutocompleteLazyQuery,
} from '../../common/graphql/apollo-operations'
import { FormTemplateStatusChip } from '../FormTemplateStatusChip'

const useStyles = makeStylesFast(() => ({
  table: {
    tableLayout: 'fixed',
    '& .link': {
      width: 100,
    },
  },
}))

type Variant = Exclude<
  SubcontractorLienWaiverTemplateSetProperties['conditionalFinalVariant'],
  null
>

export type LienWaiverTemplateSetValues = {
  conditionalFinalVariant: Variant | null
  conditionalProgressVariant: Variant | null
  unconditionalFinalVariant: Variant | null
  unconditionalProgressVariant: Variant | null
}

// Template-set values, but each field can be null
// See https://github.com/siteline/siteline/pull/3149#discussion_r563009909
type LienWaiverTemplateSetValuesPartial = {
  [P in keyof LienWaiverTemplateSetValues]: LienWaiverTemplateSetValues[P] | null
}

type LienWaiverTemplateSetProps = {
  title: string
  // `null` here means that no settings have been created yet (contract creation)
  lienWaiverSet: LienWaiverTemplateSetValues | null
  onChange: (lienWaiverSet: LienWaiverTemplateSetValues | null) => Promise<boolean>
  onReset?: () => void
}

const EMPTY_VALUES = {
  conditionalFinalVariant: null,
  conditionalProgressVariant: null,
  unconditionalFinalVariant: null,
  unconditionalProgressVariant: null,
}

export function LienWaiverTemplateSet({
  title,
  lienWaiverSet,
  onChange,
  onReset,
}: LienWaiverTemplateSetProps) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [getVariant] = useTemplateVariantForAutocompleteLazyQuery()
  const [pendingValues, setPendingValues] = useState<LienWaiverTemplateSetValuesPartial>({
    ...EMPTY_VALUES,
  })

  useEffect(
    () => setPendingValues(lienWaiverSet ? { ...lienWaiverSet } : { ...EMPTY_VALUES }),
    [lienWaiverSet]
  )

  const rowStatus = (templateIdKey: keyof LienWaiverTemplateSetValues) => {
    const existingVariant = pendingValues[templateIdKey]
    const type = existingVariant?.template.type ?? FormTemplateType.LIEN_WAIVER
    return {
      status: existingVariant?.template.status ?? null,
      skippedValidation: existingVariant?.template.skippedValidation ?? false,
      isWrongType: type !== FormTemplateType.LIEN_WAIVER,
    }
  }

  // TS compiler doesn't like asserting non-null fields on `pendingValues` itself, so here we have
  // to manually extract all properties to temporary variables and reconstruct a `finalValues` object.
  const {
    conditionalFinalVariant,
    conditionalProgressVariant,
    unconditionalFinalVariant,
    unconditionalProgressVariant,
  } = pendingValues
  let finalValues: LienWaiverTemplateSetValues | null = null
  if (
    conditionalFinalVariant !== null ||
    conditionalProgressVariant !== null ||
    unconditionalFinalVariant !== null ||
    unconditionalProgressVariant !== null
  ) {
    finalValues = {
      conditionalFinalVariant,
      conditionalProgressVariant,
      unconditionalFinalVariant,
      unconditionalProgressVariant,
    }
  }

  const onEdit = () => setIsEditing(true)
  const onCancel = () => {
    if (onReset) {
      onReset()
    }
    setIsEditing(false)
  }
  const onSave = async () => {
    const confirmed = window.confirm('Are you sure you want to save those template settings?')
    if (!confirmed) {
      return
    }
    const success = await onChange(finalValues)
    if (success) {
      setIsEditing(false)
    }
  }

  const handleViewVariant = useCallback(
    async (variantId: string) => {
      const data = await getVariant({ variables: { id: variantId } })
      const templateId = data.data?.formTemplateVariant.template.id
      if (templateId) {
        window.open(`/templates/${templateId}`, '_blank')
      }
    },
    [getVariant]
  )

  const rows: { name: string; key: keyof LienWaiverTemplateSetValues }[] = [
    { name: 'Conditional Final', key: 'conditionalFinalVariant' },
    { name: 'Conditional Progress', key: 'conditionalProgressVariant' },
    { name: 'Unconditional Final', key: 'unconditionalFinalVariant' },
    { name: 'Unconditional Progress', key: 'unconditionalProgressVariant' },
  ]

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          {!isEditing && (
            <>
              <Button onClick={onEdit} color="secondary">
                Edit
              </Button>
            </>
          )}
          {isEditing && (
            <>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={onSave} color="secondary">
                Save
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Template Status</TableCell>
            <TableCell className="link">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const { status, skippedValidation, isWrongType } = rowStatus(row.key)
            return (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <VariantAutocomplete
                    disabled={!isEditing}
                    templateType={FormTemplateType.LIEN_WAIVER}
                    variant={pendingValues[row.key]?.id ?? null}
                    onVariantChange={(value) =>
                      setPendingValues({ ...pendingValues, [row.key]: value })
                    }
                  />
                </TableCell>
                <TableCell>
                  {status && (
                    <FormTemplateStatusChip status={status} skippedValidation={skippedValidation} />
                  )}
                  {isWrongType && (
                    <SitelineText variant="body2" color="red70">
                      Wrong template type?
                    </SitelineText>
                  )}
                </TableCell>
                <TableCell>
                  {pendingValues[row.key] && (
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        const variantId = pendingValues[row.key]?.id
                        if (!variantId) {
                          return
                        }
                        handleViewVariant(variantId)
                      }}
                    >
                      View
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
