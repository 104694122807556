import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

/**
 * Home page of the admin dashboard, with links to the other pages.
 */
export function Home() {
  const navigate = useNavigate()
  const handleOpenForms = useCallback(() => navigate('/templates'), [navigate])
  const handleOpenFormMatch = useCallback(() => navigate('/templates/match'), [navigate])
  const handleOpenTemplateVariables = useCallback(() => navigate('/templateVariables'), [navigate])

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item>
        <Card>
          <CardHeader title="Forms"></CardHeader>
          <CardContent>Create, design, and manage form templates</CardContent>
          <CardActions>
            <Button onClick={handleOpenForms}>Go to Forms</Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title="Form match"></CardHeader>
          <CardContent>Find similar and duplicate templates from a file</CardContent>
          <CardActions>
            <Button onClick={handleOpenFormMatch}>Go to Form match</Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title="Template variables"></CardHeader>
          <CardContent>Find template variables and their documentation</CardContent>
          <CardActions>
            <Button onClick={handleOpenTemplateVariables}>Go to Template variables</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
