import { Grid } from '@mui/material'
import { BillingType, StoredMaterialsCarryoverType } from 'siteline-common-all'
import CompanyCard from '../../common/components/CompanyCard'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { CompanyDetailsCompanyContacts } from './CompanyContacts'
import { CompanyDetailsChangeOrderLog } from './CompanyDetailsChangeOrderLog'
import { CompanyDetailsChangeOrderRequest } from './CompanyDetailsChangeOrderRequest'
import CompanyDetailsLienWaivers from './CompanyDetailsLienWaivers'
import { CompanyDetailsPayAppRequirements } from './CompanyDetailsPayAppRequirement'
import { CompanyIntegrations } from './CompanyIntegrations'
import CompanyLocations from './CompanyLocations'
import { CompanyNotesCard } from './CompanyNotes'
import CompanyProjects from './CompanyProjects'
import CompanyUsers from './CompanyUsers'
import { CompanyDetailsVendorContacts } from './CompanyVendorContacts'
import CompanyDetailsVendors from './CompanyVendors'

interface CompanyDetailsContentProps {
  company: DetailedCompany
}

export default function CompanyDetailsContent({ company }: CompanyDetailsContentProps) {
  const usesManualStoredMaterials =
    company.storedMaterialsCarryoverType === StoredMaterialsCarryoverType.MANUAL
  return (
    <>
      <Grid item xs={5}>
        <CompanyCard company={company} withLink={false} withActions={true} readOnly={false} />
      </Grid>
      <Grid item xs={7}>
        <CompanyNotesCard company={company} />
        <CompanyLocations company={company} />
        <CompanyIntegrations company={company} />
        <CompanyDetailsChangeOrderRequest company={company} />
        <CompanyDetailsChangeOrderLog company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsPayAppRequirements
          company={company}
          billingType={BillingType.LUMP_SUM}
          usesManualStoredMaterials={usesManualStoredMaterials}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsPayAppRequirements
          company={company}
          billingType={BillingType.UNIT_PRICE}
          usesManualStoredMaterials={usesManualStoredMaterials}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsPayAppRequirements
          company={company}
          billingType={BillingType.TIME_AND_MATERIALS}
          usesManualStoredMaterials={false}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsLienWaivers company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsVendors company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsVendorContacts company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyDetailsCompanyContacts company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyUsers company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyProjects company={company} />
      </Grid>
    </>
  )
}
