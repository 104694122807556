import { gql } from '@apollo/client'
import { Alert, Container, Grid, Theme, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import { useGetProjectForDetailsQuery } from '../../common/graphql/apollo-operations'
import ProjectDetailsOverview from './ProjectDetailsOverview'
import ProjectDetailsSubcontractors from './ProjectDetailsSubcontractors'

gql`
  query getProjectForDetails($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectForDetailsProperties
    }
  }
  ${fragments.projectForDetails}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
}))

type ProjectDetailsParams = {
  projectId?: string
}

export default function ProjectDetails() {
  const classes = useStyles()
  const { projectId } = useParams() as ProjectDetailsParams

  if (!projectId) {
    throw new Error('No project ID provided')
  }

  const { data, error, loading } = useGetProjectForDetailsQuery({
    variables: { projectId },
  })

  const name = data?.project.name ?? 'Project'

  return (
    <Page className={classes.root} title={name}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">{name}</Typography>
          </Grid>
          {data?.project && (
            <>
              <ProjectDetailsOverview project={data.project} />
              <ProjectDetailsSubcontractors project={data.project} />
            </>
          )}
        </Grid>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
