import { gql } from '@apollo/client'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Table,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { PDFDocument } from 'pdf-lib'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import 'react-resizable/css/styles.css'
import { Navigate, Link as RouterLink, useParams } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import {
  FORM_TEMPLATE_INSTRUCTIONS_PREFIX,
  FormTemplateAnnotationImageType,
  FormTemplateAnnotationMetadataFieldType,
  FormTemplateAnnotationType,
  pdfTypes,
  SignatureAnnotationType,
  templateVariablesDocumentation,
} from 'siteline-common-all'
import {
  FormTemplateVersion,
  loadTemplateVariables,
  makeStylesFast,
  saveAs,
  toReferences,
  useSitelineSnackbar,
} from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import { Loader } from '../../../common/components/Loader'
import { useNavBar } from '../../../common/components/NavBar'
import Page from '../../../common/components/Page'
import * as fragments from '../../../common/graphql/Fragments'
import {
  AnnotationOverrideProperties,
  FormAnnotationInput,
  FormTemplateType,
  FormTemplateVariantProperties,
  StoredFileType,
  UpdateFormAnnotationInput,
  useApplyAnnotationOverrideMutation,
  useCreateFormTemplateAnnotationsMutation,
  useDeleteFormTemplateAnnotationsMutation,
  useGetFormTemplateQuery,
  useTemplateVariantForEditorQuery,
  useTemplateVersionForEditorQuery,
  useUpdateFormTemplateAnnotationsMutation,
  useUpdateFormTemplateVersionMutation,
} from '../../../common/graphql/apollo-operations'
import { getLatestTemplateVersion } from '../../../common/util/FormTemplate'
import TemplatePreview from '../form-template-version-details/TemplatePreview'
import { FormTemplateAnnotationEditor } from './FormTemplateAnnotationEditor'
import { AnnotationGeometry } from './FormTemplateAnnotationLayer'
import { FormTemplateAnnotationList } from './FormTemplateAnnotationList'
import { FormTemplatePdfEditor, getTopLeftMostAnnotation } from './FormTemplatePdfEditor'
import { PreviewPosition } from './PreviewPositionSelector'
import { TemplateVersionSelector } from './TemplateVersionSelector'

gql`
  mutation createFormTemplateAnnotation($input: CreateFormAnnotationInput!) {
    createFormTemplateAnnotation(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${fragments.formTemplateAnnotation}
`

gql`
  mutation createFormTemplateAnnotations($input: CreateFormAnnotationsInput!) {
    createFormTemplateAnnotations(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${fragments.formTemplateAnnotation}
`

gql`
  mutation deleteFormTemplateAnnotations($ids: [ID!]!) {
    deleteFormTemplateAnnotations(ids: $ids) {
      id
    }
  }
`

gql`
  mutation updateFormTemplateAnnotations($input: UpdateFormAnnotationsInput!) {
    updateFormTemplateAnnotations(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${fragments.formTemplateAnnotation}
`

gql`
  mutation applyAnnotationOverride($input: ApplyAnnotationOverrideInput!) {
    applyAnnotationOverride(input: $input) {
      ...AnnotationOverrideProperties
    }
  }
  ${fragments.annotationOverride}
`

gql`
  query templateVersionForEditor($id: ID!) {
    formTemplateVersion(id: $id) {
      ...FormTemplateVersionProperties
    }
  }
  ${fragments.formTemplateVersion}
`

gql`
  query templateVariantForEditor($id: ID!) {
    formTemplateVariant(id: $id) {
      ...FormTemplateVariantProperties
    }
  }
  ${fragments.formTemplateVariant}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: 0,
    marginLeft: `-${theme.spacing(2)}`,
    position: 'relative',
    height: 'calc(100vh - 64px)',
    overflow: 'scroll',
  },
  previewBottom: {
    marginTop: theme.spacing(2),
  },
  grid: {
    '& .middleColumn': {
      height: 'fit-content',
      position: 'sticky',
      top: 0,
    },
  },
}))

/**
 * Component that handles the following deprecated routes:
 *  - /templates/:templateId/versions/:versionId
 *  - /templates/:templateId/versions/:versionId/edit
 *
 * Both redirected to /templates/:templateId/edit
 */
export function TemplateVersionRedirect() {
  const { templateId } = useParams() as FormTemplateVersionEditorParams
  return <Navigate to={`/templates/${templateId}/edit`} replace />
}

type FormTemplateVersionEditorParams = {
  templateId: string
}

export function FormTemplateEditor() {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const { setIsOpen: setNavBarOpen } = useNavBar()
  const { templateId } = useParams() as FormTemplateVersionEditorParams
  const [previewPosition, setPreviewPosition] = useState<PreviewPosition>('bottom')
  const [variantId, setVariantId] = useState<string | null>(null)
  const [versionId, setVersionId] = useState<string | null>(null)
  const [selectedAnnotationIds, setSelectedAnnotationIds] = useState<string[]>([])
  const [templateVariables, setTemplateVariables] = useState<pdfTypes.TemplateVariables | null>(
    null
  )
  const [showAllAnnotationNames, setShowAllAnnotationNames] = useState<boolean>(false)
  const [updateFormTemplateVersion] = useUpdateFormTemplateVersionMutation()
  const { data: versionData } = useTemplateVersionForEditorQuery({
    variables: { id: versionId ?? '' },
    skip: !versionId,
  })
  const { data: templateData } = useGetFormTemplateQuery({
    variables: { id: templateId },
  })
  const { data: variantData } = useTemplateVariantForEditorQuery({
    variables: { id: variantId ?? '' },
    skip: !variantId,
  })

  const version = versionData?.formTemplateVersion
  const template = templateData?.formTemplate

  const annotations = useMemo(() => {
    if (!version?.annotations) {
      return []
    }
    return [...version.annotations]
  }, [version?.annotations])

  const selectedAnnotations = annotations.filter((a) => selectedAnnotationIds.includes(a.id))

  const variant = useMemo(() => variantData?.formTemplateVariant ?? null, [variantData])
  const overrides = useMemo(() => [...(variant?.annotationOverrides ?? [])], [variant])
  const selectedOverride = useMemo((): AnnotationOverrideProperties | null => {
    if (!variant || selectedAnnotations.length !== 1) {
      return null
    }
    const permanentId = selectedAnnotations[0].permanentId
    const found = overrides.find((override) => override.annotationPermanentId === permanentId)
    return found ?? null
  }, [overrides, selectedAnnotations, variant])

  // Determine the initial version to select when the template loads
  const initialVersionId = useMemo(() => {
    const versions = _.orderBy(template?.versions ?? [], (version) => version.versionNumber, 'asc')
    const latest = _.last(versions)
    return latest?.id ?? null
  }, [template?.versions])

  // Apply initial version ID when it changes
  useEffect(() => setVersionId(initialVersionId), [initialVersionId])

  // Determine the initial variant to select when the template loads
  const initialVariantId = useMemo(() => {
    const variants = template?.variants ?? []
    const defaultVariant = variants.find((variant) => variant.isDefaultVariant)
    return defaultVariant?.id ?? null
  }, [template?.variants])

  // Apply initial variant ID when it changes
  useEffect(() => setVariantId(initialVariantId), [initialVariantId])

  const isMissingLienWaiverAmountAnnotation = useMemo(() => {
    if (!template) {
      return false
    }
    const lienWaiverAmountAnnotation = annotations.find((annotation) => {
      return (
        annotation.type === FormTemplateAnnotationType.USER_ENTERED_FIELD &&
        annotation.fieldType === FormTemplateAnnotationMetadataFieldType.LIEN_WAIVER_AMOUNT &&
        annotation.defaultValueKey === 'currentPaymentDue' &&
        annotation.doNotRetainOnReset === true
      )
    })
    return template.type === FormTemplateType.LIEN_WAIVER && !lienWaiverAmountAnnotation
  }, [annotations, template])

  const isReadyForManualStoredMaterials = useMemo(() => {
    if (!template) {
      return false
    }
    const payAppTypes = [
      FormTemplateType.PAY_APP_LUMP_SUM,
      FormTemplateType.PAY_APP_QUICK,
      FormTemplateType.PAY_APP_TIME_AND_MATERIALS,
      FormTemplateType.PAY_APP_UNIT_PRICE,
    ]
    const version = getLatestTemplateVersion(template)
    return payAppTypes.includes(template.type) && (version?.isReadyForManualStoredMaterials ?? true)
  }, [template])

  const isMissingNotaryStamp = useMemo(() => {
    if (!template || !variant) {
      return false
    }
    const hasNotarySignature = annotations.some(
      ({ signatureType }) => signatureType === SignatureAnnotationType.NOTARY
    )
    const hasNotaryStamp = annotations.some(
      ({ imageType }) => imageType === FormTemplateAnnotationImageType.NOTARY_STAMP
    )
    return variant.useCompanyNotarySignatureIfAvailable && hasNotarySignature && !hasNotaryStamp
  }, [annotations, template, variant])

  const hasMoreThanOneNotarySignature = useMemo(() => {
    if (!template || !variant) {
      return false
    }
    const notarySignatures = annotations.filter(
      ({ signatureType }) => signatureType === SignatureAnnotationType.NOTARY
    )
    return notarySignatures.length > 1
  }, [annotations, template, variant])

  const showMediaBoxInfo = version?.file.type === StoredFileType.PDF
  const showAlertCard =
    isMissingLienWaiverAmountAnnotation ||
    isMissingNotaryStamp ||
    hasMoreThanOneNotarySignature ||
    showMediaBoxInfo

  const [createAnnotationsMutation] = useCreateFormTemplateAnnotationsMutation({
    update(cache, { data }) {
      if (!data || !version) {
        return
      }

      cache.modify({
        id: cache.identify(version),
        fields: {
          annotations(existingAnnotationRefs, { toReference }) {
            const newAnnotations = data.createFormTemplateAnnotations.map((annotation) =>
              cache.writeFragment({
                data: annotation,
                fragment: fragments.formTemplateAnnotation,
                fragmentName: 'FormTemplateAnnotationProperties',
              })
            )

            const refs = toReferences(existingAnnotationRefs, toReference)
            return _.compact([...refs, ...newAnnotations])
          },
        },
      })
    },
  })
  const [deleteAnnotationsMutation] = useDeleteFormTemplateAnnotationsMutation({
    update(cache, { data }) {
      if (!data || !version) {
        return
      }

      cache.modify<WritableDeep<FormTemplateVersion>>({
        id: cache.identify(version),
        fields: {
          annotations(existingAnnotationRefs, { readField, toReference }) {
            const refs = toReferences(existingAnnotationRefs, toReference)
            return refs.filter((annotationRef) => {
              const annotationId = readField('id', annotationRef)
              return !data.deleteFormTemplateAnnotations.some(({ id }) => id === annotationId)
            })
          },
        },
      })
    },
  })
  const [updateAnnotationsMutation] = useUpdateFormTemplateAnnotationsMutation()
  const [applyOverride] = useApplyAnnotationOverrideMutation()

  useEffect(() => {
    setNavBarOpen(false)
    loadTemplateVariables()
      .then((vars) => setTemplateVariables(vars))
      .catch(() => setTemplateVariables(null))

    return () => setNavBarOpen(true)
  }, [setNavBarOpen])

  // Always use this function instead of directly calling `setSelectedAnnotationIds` so we avoid
  // unnecessarily re-creating the array, which is used to build a stable list of annotation refs
  const handleUpdateSelectedAnnotationIds = useCallback(
    (annotationIds: string[]) => {
      if (!_.isEqual(new Set(annotationIds), new Set(selectedAnnotationIds))) {
        setSelectedAnnotationIds(annotationIds)
      }
    },
    [selectedAnnotationIds]
  )

  const handleUpdate = useCallback(
    async (inputs: UpdateFormAnnotationInput[]) => {
      const existingAnnotations = annotations.filter((annotation) =>
        inputs.some((input) => input.id === annotation.id)
      )
      if (existingAnnotations.length !== inputs.length) {
        snackbar.showError('Could not match all inputs with an annotation')
        return
      }

      // If there's no variant selected (shouldn't happen), or if the default variant is selected,
      // we modify the annotation directly, instead of the override.
      if (!variant || variant.isDefaultVariant) {
        // Update state
        const updatedAnnotations = existingAnnotations.map((annotation) => {
          const input = inputs.find((input) => input.id === annotation.id)
          return _.extend(_.cloneDeep(annotation), input)
        })

        // Perform mutation
        try {
          await updateAnnotationsMutation({
            variables: { input: { inputs } },
            optimisticResponse: {
              __typename: 'Mutation',
              updateFormTemplateAnnotations: updatedAnnotations,
            },
          })
        } catch (err) {
          snackbar.showError(err.message)
        }

        // If there's a variant selected, we update the override instead.
      } else {
        const annotation = annotations.find((annotation) => annotation.id === inputs[0].id)
        if (!annotation) {
          throw new Error('Could not find annotation to override')
        }

        const input = _.omit(inputs[0], 'id')
        let optimisticOverride: AnnotationOverrideProperties
        if (selectedOverride) {
          optimisticOverride = _.extend(_.cloneDeep(selectedOverride), input)
        } else {
          optimisticOverride = {
            __typename: 'AnnotationOverride',
            id: 'temp-id',
            annotationPermanentId: annotation.permanentId,
            userVisibleName: null,
            fontFamily: null,
            fontColor: null,
            textAlignment: null,
            wrapText: null,
            isOptional: null,
            selectedKey: null,
            defaultValueKey: null,
            prefix: null,
            suffix: null,
            fieldType: null,
            doNotRetainOnReset: null,
            copyDefaultValueFromPreviousAnnotationValue: null,
            imageType: null,
            signatureType: null,
            syncTag: null,
            ...input,
          }
        }

        // Apply override
        try {
          await applyOverride({
            variables: {
              input: {
                annotationPermanentId: annotation.permanentId,
                variantId: variant.id,
                ...input,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              applyAnnotationOverride: optimisticOverride,
            },
            update: (cache, { data }) => {
              if (!data) {
                return
              }
              const newOverrideRef = cache.writeFragment({
                data: data.applyAnnotationOverride,
                fragment: fragments.annotationOverride,
                fragmentName: 'AnnotationOverrideProperties',
              })

              cache.modify<WritableDeep<FormTemplateVariantProperties>>({
                id: cache.identify(variant),
                fields: {
                  annotationOverrides(existingRefs, { readField, toReference }) {
                    const refs = toReferences(existingRefs, toReference)
                    const withoutSelected = refs.filter(
                      (override) => readField('id', override) !== selectedOverride?.id
                    )
                    const out = newOverrideRef
                      ? [...withoutSelected, newOverrideRef]
                      : withoutSelected
                    return out
                  },
                },
              })
            },
          })
        } catch (err) {
          snackbar.showError(err.message)
        }
      }

      // Update selection
      const inputIds = inputs.map((input) => input.id)
      handleUpdateSelectedAnnotationIds(inputIds)
    },
    [
      annotations,
      variant,
      handleUpdateSelectedAnnotationIds,
      snackbar,
      updateAnnotationsMutation,
      selectedOverride,
      applyOverride,
    ]
  )

  const getTemplateVariablesByType = useCallback(
    (
      type: FormTemplateType
    ): (typeof templateVariablesDocumentation)[keyof typeof templateVariablesDocumentation] => {
      if (!templateVariables) {
        return templateVariablesDocumentation.payAppLumpSum
      }
      switch (type) {
        case FormTemplateType.PAY_APP_UNIT_PRICE:
          return templateVariables.payAppUnitPrice
        case FormTemplateType.PAY_APP_LUMP_SUM:
          return templateVariables.payAppLumpSum
        case FormTemplateType.PAY_APP_QUICK:
          return templateVariables.payAppQuick
        case FormTemplateType.PAY_APP_TIME_AND_MATERIALS:
          return templateVariables.payAppTimeAndMaterials
        case FormTemplateType.LIEN_WAIVER:
          return templateVariables.lienWaiver
        case FormTemplateType.LEGAL_DOCUMENT:
          return templateVariables.legalRequirement
        case FormTemplateType.CHANGE_ORDER_REQUEST:
          return templateVariables.changeOrderRequest
        case FormTemplateType.CHANGE_ORDER_LOG:
          return templateVariables.changeOrderLog
      }
    },
    [templateVariables]
  )

  const handleMatchGeometry = useCallback(
    (matchKey: keyof AnnotationGeometry) => {
      const topLeftMostAnnotation = getTopLeftMostAnnotation(selectedAnnotations, _.identity)
      if (!topLeftMostAnnotation) {
        return
      }
      handleUpdate(
        selectedAnnotations.map((annotation) => ({
          id: annotation.id,
          [matchKey]: topLeftMostAnnotation[matchKey],
        }))
      )
    },
    [selectedAnnotations, handleUpdate]
  )

  const createAnnotations = useCallback(
    (annotationInputs: Required<FormAnnotationInput>[]) => {
      if (!version) {
        return
      }
      const ids = annotationInputs.map((annotation) => annotation.id)
      handleUpdateSelectedAnnotationIds(ids)
      createAnnotationsMutation({
        optimisticResponse: {
          __typename: 'Mutation',
          createFormTemplateAnnotations: annotationInputs.map((input) => {
            const subId = _.uniqueId()
            return {
              __typename: 'FormTemplateAnnotation',
              ...input,
              permanentId: `temp-permanent-id-${subId}`,
              syncTag: null,
            }
          }),
        },
        variables: {
          input: {
            formTemplateVersionId: version.id,
            annotations: annotationInputs,
          },
        },
      }).catch((err) => {
        snackbar.showError(err.message)
      })
    },
    [createAnnotationsMutation, version, handleUpdateSelectedAnnotationIds, snackbar]
  )

  const onClone = useCallback(
    (annotationIds: string[]) => {
      const cloneAnnotations = annotations.filter((annotation) =>
        annotationIds.includes(annotation.id)
      )

      const maxY = Math.max(
        ...cloneAnnotations.map((annotation) => annotation.yStart + annotation.height)
      )
      const minY = Math.min(...cloneAnnotations.map((annotation) => annotation.yStart))
      const offset = maxY - minY

      createAnnotations(
        cloneAnnotations.map((annotation) => ({
          id: uuidv4(),
          type: annotation.type,
          fontColor: annotation.fontColor,
          fontFamily: annotation.fontFamily,
          pageNumber: annotation.pageNumber,
          textAlignment: annotation.textAlignment,
          wrapText: annotation.wrapText,
          userVisibleName: annotation.userVisibleName,
          width: annotation.width,
          height: annotation.height,
          xStart: annotation.xStart,
          yStart: annotation.yStart + offset,
          copyDefaultValueFromPreviousAnnotationValue:
            annotation.copyDefaultValueFromPreviousAnnotationValue,
          defaultValueKey: annotation.defaultValueKey ?? null,
          doNotRetainOnReset: annotation.doNotRetainOnReset,
          dynamicFieldTag: annotation.dynamicFieldTag ?? null,
          fieldType: annotation.fieldType ?? null,
          isOptional: annotation.isOptional,
          prefix: annotation.prefix ?? null,
          selectedKey: annotation.selectedKey ?? null,
          suffix: annotation.suffix ?? null,
          imageType: annotation.imageType ?? null,
          signatureType: annotation.signatureType ?? null,
        }))
      )
    },
    [annotations, createAnnotations]
  )

  const handleDelete = useCallback(
    async (annotationIds: string[]) => {
      const confirmText =
        annotationIds.length === 1
          ? 'Are you sure you want to delete this annotation?'
          : `Are you sure you want to delete these ${annotationIds.length} annotations?`
      if (!window.confirm(confirmText)) {
        return
      }
      handleUpdateSelectedAnnotationIds([])
      snackbar.showLoading()
      try {
        await deleteAnnotationsMutation({
          variables: {
            ids: annotationIds,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteFormTemplateAnnotations: annotationIds.map((id) => ({
              __typename: 'DeletionResult',
              id,
            })),
          },
        })
        snackbar.showSuccess()
      } catch (err) {
        snackbar.showError(err.message)
      }
    },
    [deleteAnnotationsMutation, handleUpdateSelectedAnnotationIds, snackbar]
  )

  const formBuildingInstructions = useMemo(() => {
    const comments = template?.comments ?? []
    const instructions = comments.find(
      (comment) =>
        comment.isSystemGenerated && comment.message.includes(FORM_TEMPLATE_INSTRUCTIONS_PREFIX)
    )
    if (instructions) {
      return instructions.message
        .replace(FORM_TEMPLATE_INSTRUCTIONS_PREFIX, '')
        .replaceAll('\n', '\n\n')
    }
    return null
  }, [template?.comments])

  const isDynamic = version && version.file.type !== StoredFileType.PDF

  const columnWidths = useMemo(() => {
    if (previewPosition === 'right') {
      if (isDynamic) {
        return { left: 4, middle: 3, right: 5 }
      } else {
        return { left: 5, middle: 3, right: 4 }
      }
    } else {
      return { left: 9, middle: 3, right: 0 }
    }
  }, [isDynamic, previewPosition])

  // Set mediabox frame to 0, which essentially clears out the margin where annotations go.
  const onZeroMediaboxClick = useCallback(async () => {
    if (!version?.file.url) {
      return
    }
    const srcResponse = await fetch(version.file.url)
    const srcBlob = await srcResponse.arrayBuffer()
    const document = await PDFDocument.load(srcBlob)
    const pages = document.getPages()
    for (const page of pages) {
      page.setMediaBox(0, 0, page.getWidth(), page.getHeight())
      page.setCropBox(0, 0, page.getWidth(), page.getHeight())
      page.setBleedBox(0, 0, page.getWidth(), page.getHeight())
      page.setTrimBox(0, 0, page.getWidth(), page.getHeight())
      page.setArtBox(0, 0, page.getWidth(), page.getHeight())
    }
    const out = await document.save()
    const outBlob = new Blob([out], { type: 'application/pdf' })
    saveAs(outBlob, 'zero-mediabox.pdf')
  }, [version?.file.url])

  if (!version || !template) {
    return <Loader />
  }

  return (
    <Page title={template.userVisibleName}>
      <div className={classes.root}>
        <Breadcrumbs sx={{ marginLeft: 4, marginTop: 2 }}>
          <Link component={RouterLink} underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Link component={RouterLink} underline="hover" color="inherit" to="/templates">
            Forms
          </Link>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={`/templates/${template.id}`}
          >
            {template.userVisibleName}
          </Link>
          {versionId && (
            <TemplateVersionSelector
              template={template}
              versionId={versionId}
              onVersionIdChange={setVersionId}
            />
          )}
        </Breadcrumbs>

        {/* Left column (PDF, annotations list, preview if showing at bottom) */}
        <Grid container spacing={2} sx={{ marginTop: 0 }} className={classes.grid}>
          <Grid item xs={columnWidths.left} className="leftColumn">
            {version.file.type === StoredFileType.PDF && (
              <FormTemplatePdfEditor
                previewPosition={previewPosition}
                setPreviewPosition={setPreviewPosition}
                createAnnotation={(input) => createAnnotations([input])}
                onUpdate={handleUpdate}
                formTemplate={template}
                formTemplateVersion={version}
                annotations={annotations}
                overrides={overrides}
                selectedAnnotationIds={selectedAnnotationIds}
                onSelectedAnnotationIdsChange={handleUpdateSelectedAnnotationIds}
                showAllAnnotationNames={showAllAnnotationNames}
                setShowAllAnnotationNames={setShowAllAnnotationNames}
                readOnly={variant !== null && !variant.isDefaultVariant}
              />
            )}
            {isDynamic && (
              <FormTemplateAnnotationList
                createAnnotation={(input) => createAnnotations([input])}
                formTemplate={template}
                formTemplateVersion={version}
                annotations={annotations}
                selectedAnnotationIds={selectedAnnotationIds}
                onSelectedAnnotationIdsChange={handleUpdateSelectedAnnotationIds}
                previewPosition={previewPosition}
                setPreviewPosition={setPreviewPosition}
              />
            )}
            {previewPosition === 'bottom' && versionId && variantId && (
              <TemplatePreview
                templateId={template.id}
                versionId={versionId}
                variantId={variantId}
                showValidateButton={true}
                showEditButtons={false}
                className={classes.previewBottom}
              />
            )}
          </Grid>
          <Grid item xs={columnWidths.middle} className="middleColumn">
            {variantId && (
              <FormTemplateAnnotationEditor
                formTemplate={template}
                annotations={selectedAnnotations}
                override={selectedOverride}
                onUpdate={handleUpdate}
                onClone={onClone}
                onDelete={(annotations) =>
                  handleDelete(annotations.map((annotation) => annotation.id))
                }
                onMatchGeometry={handleMatchGeometry}
                templateVariables={getTemplateVariablesByType(template.type)}
                isDynamic={isDynamic}
                variantId={variantId}
                onVariantIdChange={setVariantId}
                isDefaultVariant={variant?.isDefaultVariant ?? false}
              />
            )}
            {formBuildingInstructions && (
              <Card sx={{ marginTop: 2 }}>
                <CardHeader title="Customer form instructions"></CardHeader>
                <CardContent>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      p: ({ children }) => <Typography variant="body1">{children}</Typography>,
                    }}
                  >
                    {formBuildingInstructions}
                  </ReactMarkdown>
                </CardContent>
              </Card>
            )}
            {template.requestingCompany && (
              <Card sx={{ marginTop: 2 }}>
                <CardHeader title="Images"></CardHeader>
                <CardContent sx={{ marginTop: -2 }}>
                  <Typography>
                    These are the available images for{' '}
                    <Link component={RouterLink} to={`/companies/${template.requestingCompany.id}`}>
                      {template.requestingCompany.name}
                    </Link>
                    , which requested this template. Note that the template can be used for other
                    customers.
                  </Typography>
                </CardContent>
                <Table size="small">
                  <TableRow>
                    <TableCell>Logo</TableCell>
                    <TableCell>
                      {template.requestingCompany.picture ? <CheckIcon /> : <CloseIcon />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Notary stamp</TableCell>
                    <TableCell>
                      {template.requestingCompany.notaryStamp ? <CheckIcon /> : <CloseIcon />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Notary signature</TableCell>
                    <TableCell>
                      {template.requestingCompany.notarySignature ? <CheckIcon /> : <CloseIcon />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Secondary signature</TableCell>
                    <TableCell>
                      {template.requestingCompany.secondarySignature ? (
                        <CheckIcon />
                      ) : (
                        <CloseIcon />
                      )}
                    </TableCell>
                  </TableRow>
                </Table>
              </Card>
            )}

            {!isReadyForManualStoredMaterials && (
              <>
                <Card sx={{ marginTop: 2 }}>
                  <CardContent>
                    <Alert severity="error">
                      This template may not use the correct variables for manual stored materials.
                      Please refer to{' '}
                      <a href="https://www.notion.so/siteline/11b9984fb1948023ae07d4fd555ddf07">
                        the table of manual mode variables
                      </a>{' '}
                      to confirm the correct variables are being used, and click the button below
                      when the form template is ready for use with manual stored materials.
                    </Alert>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() =>
                          updateFormTemplateVersion({
                            variables: {
                              input: { id: version.id, isReadyForManualStoredMaterials: true },
                            },
                          })
                        }
                      >
                        Mark form ready for manual stored materials
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </>
            )}

            {showAlertCard && (
              <Card sx={{ marginTop: 2 }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {isMissingLienWaiverAmountAnnotation && (
                    <Alert severity="error">
                      This lien waiver template is missing a valid amount field. Amount fields must
                      be:
                      <ul>
                        <li>
                          An annotation of type <code>USER_ENTERED_FIELD</code>
                        </li>
                        <li>
                          Have their default key set to <code>currentPaymentDue</code>
                        </li>
                        <li>
                          Have their field type set to <code>LIEN_WAIVER_AMOUNT</code>
                        </li>
                        <li>Must not retain its value when resetting a pay app</li>
                      </ul>
                    </Alert>
                  )}

                  {isMissingNotaryStamp && (
                    <Alert severity="error">
                      Template has a notary signature, but no notary stamp.
                    </Alert>
                  )}

                  {hasMoreThanOneNotarySignature && (
                    <Alert severity="warning">Template has more than 1 notary signature.</Alert>
                  )}

                  {showMediaBoxInfo && (
                    <Alert
                      severity="info"
                      action={
                        <Button variant="text" onClick={() => onZeroMediaboxClick()}>
                          Download
                        </Button>
                      }
                    >
                      Annotations not rendering properly? It might be due to extra margins in the
                      PDF (media box). Try using the following file instead.
                    </Alert>
                  )}
                </CardContent>
              </Card>
            )}
          </Grid>
          {previewPosition === 'right' && (
            <Grid item xs={columnWidths.right} className="rightColumn">
              {versionId && variantId && (
                <TemplatePreview
                  templateId={template.id}
                  versionId={versionId}
                  variantId={variantId}
                  showValidateButton={true}
                  showEditButtons={false}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </Page>
  )
}
