import { gql } from '@apollo/client'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedUser } from '../../common/graphql/Fragments'
import { useProjectsByUserQuery } from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

gql`
  query projectsByUser($id: ID!) {
    projectsByUser(id: $id) {
      ...ProjectProperties
    }
  }
  ${fragments.project}
`

export default function UserDetailsProjects({ user }: { user: DetailedUser }) {
  const classes = useStyles()

  const { data, error, loading } = useProjectsByUserQuery({
    variables: { id: user.id },
  })

  if (loading || error || !data) {
    return null
  }

  const sortedProjects = [...data.projectsByUser].sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardHeader title="Projects" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedProjects.map((project) => (
                <TableRow hover key={project.id}>
                  <TableCell>
                    <Link component={RouterLink} to={`/projects/${project.id}`} underline="hover">
                      {project.name}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  )
}
