import { tlds } from '@hapi/tlds'
import { validate as validateEmail } from 'email-validator'
import _ from 'lodash'

export const MAX_COMMENT_LENGTH = 10_000
export const MAX_NOTES_LENGTH = 10_000
export const MAX_ATTACHMENT_NOTES_LENGTH = 1_000
export const MAX_LINE_ITEM_NAME_LENGTH = 512
export const MAX_VENDOR_NUMBER_LENGTH = 100
export const MAX_SOV_CODE_LENGTH = 100
export const MIN_COMPANY_NICKNAME_LENGTH = 3
export const MAX_EMAIL_MESSAGE_CHARACTERS = 4000
export const MAX_EMAIL_SUBJECT_CHARACTERS = 512

// Collections
export const MAX_COLLECTIONS_REMINDER_INTERVAL_AMOUNT = 100

export const MAX_FILENAME_LENGTH = 100

// Email verification constants for company email aliases
export const EMAIL_VERIFICATION_CODE_LENGTH = 6
export const EMAIL_VERIFICATION_EXPIRATION_HOURS = 2

// Regex for 2-3 letter state code on address forms
export const STATE_CODE_VALIDATION_REGEX = /^[A-Z]{2,3}$/

// Character limits found by typing into corresponding Quickbooks inputs until it maxes out
export const QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT = 41
export const QUICKBOOKS_JOB_MAX_CHAR_COUNT = 41
export const QUICKBOOKS_ITEM_MAX_CHAR_COUNT = 31
export const QUICKBOOKS_DESCRIPTION_MAX_CHAR_COUNT = 4095
export const QUICKBOOKS_INVOICE_NUMBER_MAX_CHAR_COUNT = 20

// ComputerEase file import string lengths are based on the schema in batchinvoices.xsd
export const COMPUTER_EASE_CUSTOMER_ID_MAX_LENGTH = 8
export const COMPUTER_EASE_JOB_ID_MAX_LENGTH = 10
export const COMPUTER_EASE_ACCOUNT_NUMBER_MAX_LENGTH = 9

// Foundation enforces a char limit
export const FOUNDATION_CUSTOMER_NUMBER_MAX_LENGTH = 10

export function isValidEmail(email: string): boolean {
  const isValid = validateEmail(email)
  const domain = _.last(email.split('.'))?.toLowerCase()
  if (!domain) {
    return false
  }
  const isValidDomain = Array.from(tlds).includes(domain)
  return isValid && isValidDomain
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  const regex = /^\+[1-9]\d{1,14}(x\d{1,4})?$/
  return regex.test(phoneNumber)
}
