import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getFilename } from 'siteline-common-all'
import {
  ButtonLabelSpinner,
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  FormTemplateProperties,
  useCloneFormTemplateMutation,
  useCreateFormTemplateVersionMutation,
  useUpdateFormTemplateMutation,
} from '../../common/graphql/apollo-operations'
import { PageRange, copyPdfPagesToPdfs } from '../../common/util/FormTemplate'
import { Document, Page as PdfPage } from '../../pdf'
import { PdfPageSelector } from '../form-template-versions/form-template-version-editor/PdfPageSelector'

const THUMBNAIL_WIDTH = 200

const useStyles = makeStylesFast((theme: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      height: '100%',
      overflow: 'hidden',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    overflow: 'hidden',
  },
  thumbnails: {
    height: '100%',
    width: THUMBNAIL_WIDTH,
    overflow: 'auto',
    '& .thumbnail': {
      position: 'relative',
      overflow: 'hidden',
      height: 160,
      marginBottom: theme.spacing(1),
      border: '2px solid',
      borderColor: colors.grey30,
      borderRadius: theme.spacing(1),
      backgroundColor: colors.grey10,
      textAlign: 'center',
      cursor: 'pointer',
    },
    '& .selectedThumbnail': {
      borderColor: colors.grey70,
    },
    '& .documentImage': {
      height: '80%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      '& > div': {
        boxShadow: `0 0 ${theme.spacing(0.5)} ${colors.grey30}`,
      },
    },
    '& .documentName': {
      backgroundColor: colors.white,
      borderTop: '1px solid',
      borderColor: colors.grey30,
      height: '34px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      '& > *': {
        whiteSpace: 'nowrap',
        textOverflow: 'truncate',
        overflow: 'hidden',
      },
    },
  },
  document: {
    margin: theme.spacing(0, 3),
    '& .pdf': {
      border: `2px solid ${colors.grey30}`,
      borderRadius: theme.spacing(1),
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
    },
    '& .selector': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: theme.spacing(-1),
    },
  },
  sidebar: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& .splitButton': {
      marginBottom: theme.spacing(2),
    },
    '& .templateList': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflowY: 'auto',
      overflowX: 'hidden',
      flexGrow: 1,
      '& .templateRow': {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0, -1),
        '& > *': {
          margin: theme.spacing(0, 1),
        },
        '&:not(:last-child)': {
          marginBottom: theme.spacing(1),
        },
        '& .MuiInputBase-root': {
          height: 40,
        },
        '& .nameInput': {
          flexGrow: 1,
          '&.missingName fieldset': {
            border: `1px solid ${colors.red50}`,
          },
        },
        '& .pageInput': {
          width: 80,
        },
        '& .errorIcon': {
          display: 'flex',
          alignItems: 'center',
          '& .MuiSvgIcon-root': {
            color: colors.red50,
            fontSize: 20,
          },
        },
      },
    },
  },
  actions: {
    padding: theme.spacing(0, 4, 3, 4),
    display: 'flex',
    alignItems: 'center',
    '& .buttons': {
      margin: theme.spacing(0, -1),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
      '& .errorAlert': {
        height: 40,
      },
    },
  },
}))

interface SplitFormTemplateDialogProps {
  open: boolean
  onClose: () => void
  formTemplate: FormTemplateProperties
}

interface AdditionalTemplate {
  id: string
  name: string
  pageRange: PageRange
  // We store page range strings separately from the page range so editing works better. We only
  // save the input value while the user is editing; after a user finishes editing (and blurs the
  //  input), we update the page range and saved inputs based on the input value.
  pageRangeInput: [string, string]
}

function makePageRangeAndInputs(
  fromPage: number,
  toPage: number
): Pick<AdditionalTemplate, 'pageRange' | 'pageRangeInput'> {
  return {
    pageRange: [fromPage, toPage],
    // When a valid page range is set, the inputs should match the page range
    pageRangeInput: [String(fromPage), String(toPage)],
  }
}

// Returns true if a given template's page range includes a specific page
function doesTemplateIncludePage(
  { pageRange: [fromPage, toPage] }: AdditionalTemplate,
  pageNumber: number
) {
  return fromPage <= pageNumber && toPage >= pageNumber
}

/** Dialog for helping split a pdf into multiple form templates */
export function SplitFormTemplateDialog({
  open,
  onClose,
  formTemplate,
}: SplitFormTemplateDialogProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [updateFormTemplate] = useUpdateFormTemplateMutation()
  const [createFormTemplateVersion] = useCreateFormTemplateVersionMutation()
  const [cloneFormTemplate] = useCloneFormTemplateMutation()

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(1)
  const [shouldDownloadZip, setShouldDownloadZip] = useState<boolean>(true)
  // Use state instead of each mutation's loading response so we have one continuous loading
  // state as we progress through multiple mutations
  const [submitting, setSubmitting] = useState<boolean>(false)

  const baseOriginalTemplate = useMemo(
    () => ({
      id: formTemplate.id,
      name: formTemplate.userVisibleName,
      ...makePageRangeAndInputs(1, 1),
    }),
    [formTemplate.id, formTemplate.userVisibleName]
  )
  const [templates, setTemplates] = useState<AdditionalTemplate[]>([baseOriginalTemplate])

  // When the page count loads, update the templates to include the original form template with
  // the full PDF's page range. We only want to update once, as we expect the original form template
  // object to change as we call resolvers and don't want to re-render in between mutations.
  useEffect(() => {
    setTemplates((templates) => {
      const newTemplates = [...templates]
      const originalTemplateIndex = newTemplates.findIndex(
        (template) => template.id === formTemplate.id
      )
      if (originalTemplateIndex === -1) {
        return newTemplates
      }
      const originalTemplate = newTemplates[originalTemplateIndex]
      newTemplates.splice(originalTemplateIndex, 1, {
        ...originalTemplate,
        ...makePageRangeAndInputs(originalTemplate.pageRange[0], pageCount),
      })
      return newTemplates
    })
  }, [formTemplate.id, pageCount])

  const handleSubmit = useCallback(async () => {
    if (!formTemplate.originalFile?.url) {
      return
    }

    setSubmitting(true)

    // First, update the name of this form template, if it was edited
    const originalTemplate = templates.find((template) => template.id === formTemplate.id)
    if (!originalTemplate) {
      snackbar.showError('Something went wrong, cannot find the original template to edit')
      return
    }
    if (originalTemplate.name !== formTemplate.userVisibleName) {
      await updateFormTemplate({
        variables: { input: { id: formTemplate.id, userVisibleName: originalTemplate.name } },
      })
    }

    // Split the PDF into chunks to upload for each form template
    const newPdfs = await copyPdfPagesToPdfs(formTemplate.originalFile, templates)

    // Create new templates for additional forms, and create template versions for each PDF chunk
    const newFormTemplateIds: string[] = []
    for (let index = 0; index < templates.length; index++) {
      const updatingTemplate = templates[index]
      let formTemplateId
      // If not the original form template, create a clone with the user-entered name
      if (updatingTemplate.id !== formTemplate.id) {
        const { data } = await cloneFormTemplate({
          variables: { input: { id: formTemplate.id, userVisibleName: updatingTemplate.name } },
        })
        if (data?.cloneFormTemplate) {
          formTemplateId = data.cloneFormTemplate.id
          newFormTemplateIds.push(formTemplateId)
        }
      } else {
        formTemplateId = updatingTemplate.id
      }
      if (formTemplateId) {
        // Create a template version for each PDF for the corresponding form template
        await createFormTemplateVersion({
          variables: { input: { formTemplateId, file: newPdfs[index] } },
        })
      }
    }

    // If the option is selected, download a zip file with all the split files
    if (shouldDownloadZip) {
      const zip = new JSZip()
      templates.forEach((template, index) => {
        const filename = getFilename([template.name], 'pdf')
        zip.file(filename, newPdfs[index])
      })
      zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, 'SplitFormTemplateFiles.zip'))
    }

    snackbar.showSuccess()

    // Open the new form templates in another window
    newFormTemplateIds.forEach((templateId) => window.open(`/templates/${templateId}`, '_blank'))
    setSubmitting(false)
    onClose()
  }, [
    cloneFormTemplate,
    createFormTemplateVersion,
    formTemplate.id,
    formTemplate.originalFile,
    formTemplate.userVisibleName,
    onClose,
    shouldDownloadZip,
    snackbar,
    templates,
    updateFormTemplate,
  ])

  const handleAddTemplate = useCallback(() => {
    // If we're viewing a page that isn't the first page of another form template's page range,
    // use that as the new template's start page
    const isTemplateFirstPage = templates.some((template) => template.pageRange[0] === pageNumber)
    if (!isTemplateFirstPage) {
      const templateUsingPageIndex = templates.findIndex((template) =>
        doesTemplateIncludePage(template, pageNumber)
      )
      // If a template is using this page, split that template
      if (templateUsingPageIndex !== -1) {
        const templateUsingPage = templates[templateUsingPageIndex]
        const updatedTemplate = {
          ...templateUsingPage,
          ...makePageRangeAndInputs(templateUsingPage.pageRange[0], pageNumber - 1),
        }
        setTemplates((templates) => {
          const newTemplates = [...templates]
          newTemplates.splice(templateUsingPageIndex, 1, updatedTemplate, {
            id: uuidv4(),
            name: '',
            ...makePageRangeAndInputs(pageNumber, templateUsingPage.pageRange[1]),
          })
          return newTemplates
        })
        return
      } else {
        // If no template is using this page, add a template with this page only
        setTemplates((templates) => [
          ...templates,
          { id: uuidv4(), name: '', ...makePageRangeAndInputs(pageNumber, pageNumber) },
        ])
        return
      }
    }

    // If another template already has the current page as its first, either:
    // 1. Create the new template with the first unused page
    // 2. Create the new template with the last available page from another multi-page template
    const firstUnusedPage = _.range(1, pageCount + 1).find(
      (number) => !templates.some((template) => doesTemplateIncludePage(template, number))
    )
    if (firstUnusedPage !== undefined) {
      setTemplates((templates) => [
        ...templates,
        { id: uuidv4(), name: '', ...makePageRangeAndInputs(firstUnusedPage, firstUnusedPage) },
      ])
      return
    }

    const multiPageTemplates = templates.filter(
      (template) => template.pageRange[1] - template.pageRange[0] > 0
    )
    const templateToSplit = _.maxBy(multiPageTemplates, (template) => template.pageRange[1])
    if (templateToSplit) {
      setTemplates((templates) => {
        const newTemplates = templates.filter((template) => template.id !== templateToSplit.id)
        return [
          ...newTemplates,
          {
            ...templateToSplit,
            ...makePageRangeAndInputs(
              templateToSplit.pageRange[0],
              templateToSplit.pageRange[1] - 1
            ),
          },
          {
            id: uuidv4(),
            name: '',
            ...makePageRangeAndInputs(templateToSplit.pageRange[1], templateToSplit.pageRange[1]),
          },
        ]
      })
    }
  }, [pageCount, pageNumber, templates])

  const handleEditTemplate = useCallback(
    (
      templateId: string,
      {
        name,
        fromPageInput,
        toPageInput,
      }: { name?: string; fromPageInput?: string; toPageInput?: string }
    ) => {
      setTemplates((templates) => {
        const editingTemplate = templates.find((template) => template.id === templateId)
        if (!editingTemplate) {
          snackbar.showError('Could not find template to edit the name of')
          return templates
        }
        const updatedTemplate = { ...editingTemplate }
        if (name !== undefined) {
          updatedTemplate.name = name
        }
        if (fromPageInput !== undefined) {
          updatedTemplate.pageRangeInput[0] = fromPageInput
        }
        if (toPageInput !== undefined) {
          updatedTemplate.pageRangeInput[1] = toPageInput
        }
        const filteredTemplates = templates.filter((template) => template.id !== editingTemplate.id)
        return [...filteredTemplates, updatedTemplate]
      })
    },
    [snackbar]
  )

  const handleUpdatePageNumbers = useCallback(() => {
    setTemplates((templates) =>
      templates.map((template) => {
        let fromPage = Number(template.pageRangeInput[0])
        if (fromPage < 1 || fromPage > pageCount) {
          fromPage = template.pageRange[0]
        }
        let toPage = Number(template.pageRangeInput[1])
        if (toPage < 1 || toPage > pageCount) {
          toPage = template.pageRange[1]
        }
        return {
          ...template,
          ...makePageRangeAndInputs(fromPage, toPage),
        }
      })
    )
  }, [pageCount])

  const sortedTemplates = useMemo(
    () => _.orderBy(templates, (template) => template.pageRange[0]),
    [templates]
  )

  const resetDialog = useCallback(() => {
    setPageNumber(1)
    setPageCount(1)
    setTemplates([baseOriginalTemplate])
    setSubmitting(false)
    setShouldDownloadZip(true)
  }, [baseOriginalTemplate])

  const templateIdsWithDuplicatePage = useMemo(() => {
    // Every page in the PDF must belong to at most one template
    const pageNumbers = _.range(1, pageCount + 1)
    const duplicatePages = pageNumbers.filter((number) => {
      const templatesUsingPage = templates.filter((template) =>
        doesTemplateIncludePage(template, number)
      )
      return templatesUsingPage.length > 1
    })
    return templates
      .filter((template) => duplicatePages.some((page) => doesTemplateIncludePage(template, page)))
      .map((template) => template.id)
  }, [pageCount, templates])

  const hasTemplateMissingName = useMemo(
    () => templates.some((template) => !template.name),
    [templates]
  )

  const templateIdsWithInvalidPageRange = useMemo(
    () =>
      templates
        .filter((template) => template.pageRange[0] > template.pageRange[1])
        .map((template) => template.id),
    [templates]
  )

  const scrollToThumbnail = useCallback((el: HTMLElement | null) => {
    if (!el) {
      return
    }
    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  const handleSelectPageNumber = useCallback(
    (pageNumber: number) => {
      scrollToThumbnail(document.getElementById(`thumbnail-${pageNumber}`))
      setPageNumber(pageNumber)
    },
    [scrollToThumbnail]
  )

  // Don't allow the user to create more templates than there are pages in the PDF
  const allowAdditionalTemplates = templates.length < pageCount

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      disableRestoreFocus
      open={open}
      onClose={onClose}
      className={classes.dialog}
      keepMounted={false}
      TransitionProps={{ onEnter: resetDialog, onExited: resetDialog }}
    >
      <DialogTitle>
        <SitelineText variant="body1" bold style={{ fontSize: 22 }}>
          Split form template PDF
        </SitelineText>
        <SitelineText variant="body2" color="grey50">
          If this PDF contains multiple forms, split it into multiple form templates and assign the
          appropriate page range to each template
        </SitelineText>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.thumbnails} id="thumbnailContainer">
          <Document file={formTemplate.originalFile?.url}>
            {_.range(pageCount).map((pageIndex) => (
              <div key={pageIndex} onClick={() => setPageNumber(pageIndex + 1)}>
                <div
                  className={clsx('thumbnail', {
                    selectedThumbnail: pageNumber === pageIndex + 1,
                  })}
                >
                  <div className="documentImage" id={`thumbnail-${pageIndex + 1}`}>
                    <PdfPage
                      pageNumber={pageIndex + 1}
                      width={THUMBNAIL_WIDTH}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </div>
                  <div className="documentName">
                    <SitelineText variant="body2" color="grey90">
                      {
                        templates.find((template) =>
                          doesTemplateIncludePage(template, pageIndex + 1)
                        )?.name
                      }
                    </SitelineText>
                  </div>
                </div>
              </div>
            ))}
          </Document>
        </div>
        <div className={classes.document}>
          <div className="pdf">
            <Document
              file={formTemplate.originalFile?.url}
              // It's possible that this success callback is triggered with a null value
              onLoadSuccess={(document: { numPages: number } | null) => {
                if (document !== null) {
                  setPageCount(document.numPages)
                }
              }}
            >
              <PdfPage height={window.innerHeight * 0.65} pageNumber={pageNumber} />
            </Document>
          </div>
          <div className="selector">
            <PdfPageSelector
              pageNumber={pageNumber}
              setPageNumber={handleSelectPageNumber}
              pageCount={pageCount}
            />
          </div>
        </div>
        <div className={classes.sidebar}>
          <Button
            variant="outlined"
            color="secondary"
            className="splitButton"
            onClick={handleAddTemplate}
            disabled={!allowAdditionalTemplates}
          >
            Add form template
          </Button>
          <div className="templateList">
            {sortedTemplates.map((template) => {
              const hasInvalidPagesError = templateIdsWithInvalidPageRange.includes(template.id)
              const hasDuplicatePageError = templateIdsWithDuplicatePage.includes(template.id)
              return (
                <div key={template.id} className="templateRow">
                  <TextField
                    variant="outlined"
                    value={template.name}
                    onChange={(ev) => handleEditTemplate(template.id, { name: ev.target.value })}
                    className={clsx('nameInput', { missingName: !template.name })}
                    autoFocus={template.id !== formTemplate.id}
                  />
                  <TextField
                    variant="outlined"
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: pageCount } }}
                    value={template.pageRangeInput[0]}
                    onChange={(ev) =>
                      handleEditTemplate(template.id, { fromPageInput: ev.target.value })
                    }
                    onBlur={handleUpdatePageNumbers}
                    className="pageInput"
                  />
                  –
                  <TextField
                    variant="outlined"
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: pageCount } }}
                    value={template.pageRangeInput[1]}
                    onChange={(ev) => {
                      handleEditTemplate(template.id, { toPageInput: ev.target.value })
                    }}
                    onBlur={handleUpdatePageNumbers}
                    className="pageInput"
                  />
                  <Tooltip title="Jump to page">
                    <IconButton
                      size="small"
                      onClick={() => setPageNumber(template.pageRange[0])}
                      disabled={template.pageRange[0] === pageNumber}
                    >
                      <KeyboardReturnIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove template">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setTemplates((templates) =>
                          templates.filter((otherTemplate) => otherTemplate.id !== template.id)
                        )
                      }
                      disabled={template.id === formTemplate.id}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Collapse
                    in={hasInvalidPagesError || hasDuplicatePageError}
                    mountOnEnter
                    unmountOnExit
                    orientation="horizontal"
                  >
                    <div className="errorIcon">
                      <Tooltip
                        title={
                          hasInvalidPagesError
                            ? 'Pages are out of order'
                            : 'Multiple templates include the same page'
                        }
                      >
                        <WarningIcon />
                      </Tooltip>
                    </div>
                  </Collapse>
                </div>
              )
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className="buttons">
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldDownloadZip}
                onChange={(ev) => setShouldDownloadZip(ev.target.checked)}
                size="small"
              />
            }
            label="Download split files"
            style={{ marginRight: 16 }}
          />
          <Button
            onClick={() => {
              onClose()
            }}
            variant="outlined"
            color="secondary"
          >
            <SitelineText variant="button" color="grey70">
              Cancel
            </SitelineText>
          </Button>
          <Button
            disabled={
              submitting ||
              !formTemplate.originalFile ||
              templateIdsWithDuplicatePage.length > 0 ||
              templateIdsWithInvalidPageRange.length > 0 ||
              hasTemplateMissingName
            }
            startIcon={submitting ? <ButtonLabelSpinner /> : undefined}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
