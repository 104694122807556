import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { DetailedUser } from '../../common/graphql/Fragments'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
}))

/**
 * A list of companies that a user belongs to, alongside their role and status.
 */
export default function UserDetailsCompanies({ user }: { user: DetailedUser }) {
  const classes = useStyles()
  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardHeader title="Companies" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableHead>
              <TableCell>Company</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
            </TableHead>
            <TableBody>
              {user.companyUsers.map((companyUser) => (
                <TableRow key={companyUser.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/companies/${companyUser.company.id}`}
                      underline="hover"
                    >
                      {companyUser.company.name}
                    </Link>
                  </TableCell>
                  <TableCell>{companyUser.role}</TableCell>
                  <TableCell>{companyUser.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  )
}
