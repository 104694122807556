import { gql } from '@apollo/client'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import IdentifierRow from '../../common/components/IdentifierRow'
import { DetailedUser } from '../../common/graphql/Fragments'
import { useResendUserInviteMutation } from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

gql`
  mutation resendUserInvite($id: ID!) {
    resendUserInvite(id: $id) {
      id
    }
  }
`

export default function UserDetailsOverview({ user }: { user: DetailedUser }) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [resendUserInviteMutation] = useResendUserInviteMutation()
  const resendInviteEmail = useCallback(() => {
    if (!window.confirm('Are you sure you want to send a new invitation email?')) {
      return
    }

    resendUserInviteMutation({ variables: { id: user.id } })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [resendUserInviteMutation, snackbar, user.id])

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardHeader title="Overview" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableBody>
              <IdentifierRow id={user.id} />
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  {user.firstName} {user.lastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Job title</TableCell>
                <TableCell>{user.jobTitle}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Policies Accepted</TableCell>
                <TableCell>
                  {user.policiesAcceptedAt
                    ? // eslint-disable-next-line momentjs/no-moment-constructor
                      moment(user.policiesAcceptedAt).format('MMMM DD, YYYY h:mma')
                    : 'Not yet accepted'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          <Button color="primary" onClick={resendInviteEmail}>
            Send Invitation Email
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}
