import { gql } from '@apollo/client'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { clsx } from 'clsx'
import _ from 'lodash'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { PatternFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { makeStylesFast, toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { LocationCard } from '../../common/components/LocationCard'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyProperties,
  CompanyType,
  LocationInput,
  Query,
  StoredFileInput,
  StoredFileType,
  useCreateCompanyMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  pictureLabel: {
    marginTop: theme.spacing(1),
  },
  uploadPaper: {
    height: 148,
    padding: theme.spacing(2),
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  fileDrag: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
  },
  addAttachment: {
    height: 116,
    textAlign: 'center',
    '& span': {
      display: 'block',
    },
  },
  picturePreview: {
    height: 300,
    backgroundSize: 'contain',
    marginBottom: theme.spacing(1),
  },
}))

gql`
  mutation createCompany($input: CompanyInput!) {
    createCompany(company: $input) {
      ...CompanyProperties
    }
  }
  ${fragments.company}
`

interface CompanyCreateProps {
  withTitle?: boolean
  onCreate?: (company: CompanyProperties) => void
}

export default function CompanyCreate({ withTitle = true, onCreate }: CompanyCreateProps) {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [expectedNumberOfProjects, setexpectedNumberOfProjects] = useState('')
  const [picture, setPicture] = useState<File | null>(null)
  const [picturePreview, setPicturePreview] = useState<string | null>(null)
  const [companyType, setCompanyType] = useState<CompanyType>(CompanyType.SUBCONTRACTOR)
  const [subtrade, setSubtrade] = useState('')
  const [domainSignup, setDomainSignup] = useState(false)
  const [domain, setDomain] = useState('')
  const [location, setLocation] = useState<LocationInput | null>(null)
  const [createPayAppReminderDate, setCreatePayAppReminderDate] = useState<number>(12)
  const snackbar = useSitelineSnackbar()
  const [createCompanyMutation] = useCreateCompanyMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      const newCompanyRef = cache.writeFragment({
        data: data.createCompany,
        fragment: fragments.company,
        fragmentName: 'CompanyProperties',
      })

      cache.modify<WritableDeep<Query>>({
        id: 'ROOT_QUERY',
        fields: {
          companies(existingCompanyRefs, { toReference }) {
            const refs = toReferences(existingCompanyRefs, toReference)
            return _.compact([...refs, newCompanyRef])
          },
        },
      })
    },
  })
  const navigate = useNavigate()

  const ACCEPTED_FILE_TYPES: Partial<Record<string, StoredFileType>> = {
    'image/jpeg': StoredFileType.JPEG,
    'image/png': StoredFileType.PNG,
  }

  const validateAndSubmit = async () => {
    if (name.length === 0) {
      throw new Error('Company name must be provided')
    }
    if (phoneNumber.length !== 10) {
      throw new Error('Company phone number must be provided')
    }
    if (!location) {
      throw new Error('Company location must be provided')
    }
    if (domainSignup && domain.length === 0) {
      throw new Error('Email domain must be provided if enforced during signup')
    }

    let pictureInput: StoredFileInput | undefined
    if (picture) {
      const attachmentType = ACCEPTED_FILE_TYPES[picture.type]
      if (!attachmentType) {
        throw new Error('Company logo must either be JPEG or PNG')
      }
      pictureInput = {
        name: 'Company logo',
        file: picture,
      }
    }

    const response = await createCompanyMutation({
      variables: {
        input: {
          name,
          phoneNumber: `+1${phoneNumber}`,
          expectedNumberOfProjects: Number(expectedNumberOfProjects),
          locations: [
            {
              ...location,
              nickname: location.nickname || null,
              street1: location.street1 || null,
              street2: location.street2 || null,
              postalCode: location.postalCode || null,
              county: location.county || null,
            },
          ],
          picture: pictureInput,
          type: companyType,
          metadata: {
            domains: domainSignup ? [domain] : [],
            domainSignup,
            subtrade: subtrade.length > 0 ? subtrade : null,
            monthlyCreatePayAppReminderDate: createPayAppReminderDate,
          },
        },
      },
    })

    if (!response.data) {
      throw new Error('No data in response')
    }

    return response.data.createCompany
  }

  const onSubmit = (ev: FormEvent): void => {
    ev.preventDefault()
    snackbar.showLoading()
    validateAndSubmit()
      .then((company: CompanyProperties) => {
        snackbar.showSuccess()
        if (onCreate) {
          onCreate(company)
        } else {
          navigate(`/companies/${company.id}`)
        }
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.onload = (ev) => setPicturePreview(ev.target?.result as string)
    reader.readAsDataURL(file)
    setPicture(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [String(StoredFileType.JPEG)],
      'image/png': [String(StoredFileType.PNG)],
    },
  })

  const handleClearPicture = useCallback(() => {
    setPicture(null)
    setPicturePreview(null)
  }, [setPicture, setPicturePreview])

  // Watch for file rejections and show snackbar error if any are found
  useEffect(() => {
    if (fileRejections.length > 0) {
      const message = ['Please ensure your uploaded file is JPG or PNG']
      snackbar.showErrors(message)
    }
  }, [fileRejections, snackbar])

  const companyTypes = [
    CompanyType.LENDER,
    CompanyType.OWNER_DEVELOPER,
    CompanyType.GENERAL_CONTRACTOR,
    CompanyType.SUBCONTRACTOR,
    CompanyType.ARCHITECT,
  ]

  const title = 'Create a company'
  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          {withTitle && (
            <Grid item xs={12}>
              <Grid item>
                <Typography variant="h4">{title}</Typography>
              </Grid>
            </Grid>
          )}

          <Grid item xs={8}>
            <form onSubmit={onSubmit}>
              <Card>
                <CardHeader title="Company information" />
                <CardContent>
                  {/* Picture */}
                  {!picture && (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Paper
                        elevation={0}
                        className={clsx(classes.uploadPaper, {
                          [classes.fileDrag]: isDragActive,
                        })}
                      >
                        <Grid
                          className={classes.addAttachment}
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                          <Grid item>
                            <AddPhotoAlternateOutlinedIcon />
                          </Grid>
                          <Grid item>
                            {isDragActive && <p>Drop here</p>}
                            {!isDragActive && (
                              <p>Upload a company logo (optional, must be jpg or png)</p>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  )}

                  {picturePreview && (
                    <CardMedia className={classes.picturePreview} image={picturePreview} />
                  )}
                  {picture && (
                    <Button
                      variant="contained"
                      sx={{ marginBottom: 1 }}
                      onClick={handleClearPicture}
                    >
                      Remove photo
                    </Button>
                  )}

                  {/* Name */}
                  <TextField
                    required
                    fullWidth
                    id="company-name"
                    label="Name"
                    className={classes.input}
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                  />

                  {/* Phone number */}
                  <PatternFormat
                    required
                    value={phoneNumber}
                    format="(###) ###-####"
                    onValueChange={(values) => setPhoneNumber(values.value)}
                    customInput={TextField}
                    fullWidth
                    id="company-phonenumber"
                    label="Phone number"
                    className={classes.input}
                    valueIsNumericString
                  />

                  {/* Company type */}
                  <FormControl required fullWidth className={classes.input}>
                    <InputLabel id="company-type-label">Type</InputLabel>
                    <Select
                      labelId="company-type-label"
                      label="Type"
                      id="company-type"
                      variant="outlined"
                      value={companyType}
                      onChange={(event) => {
                        if (event.target.value) {
                          setCompanyType(event.target.value as CompanyType)
                        }
                      }}
                    >
                      {companyTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Expected number of projects */}
                  <TextField
                    fullWidth
                    id="expected-number-projects"
                    label="Expected number of projects"
                    className={classes.input}
                    helperText="How many projects does this company expect to bill? This is used to assess the status of a company's onboarding."
                    value={expectedNumberOfProjects}
                    onChange={(ev) => setexpectedNumberOfProjects(ev.target.value)}
                  />

                  {/* Subtrade */}
                  <TextField
                    fullWidth
                    id="company-subtrade"
                    label="Subtrade"
                    className={classes.input}
                    helperText="E.g.: Welding"
                    value={subtrade}
                    onChange={(ev) => setSubtrade(ev.target.value)}
                  />

                  {/* Email domain signup */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="company-domain-signup"
                        value={domainSignup}
                        checked={domainSignup}
                        onChange={(ev, checked) => setDomainSignup(checked)}
                      />
                    }
                    label="Users must sign up with a specific email domain"
                  />

                  {/* Email domain */}
                  <TextField
                    fullWidth
                    disabled={!domainSignup}
                    id="company-domain"
                    label="Email address domain"
                    className={classes.input}
                    helperText="E.g.: ryancompanies.com"
                    value={domain}
                    onChange={(ev) => setDomain(ev.target.value)}
                  />

                  <TextField
                    required
                    fullWidth
                    type="number"
                    label="Monthly Create Pay App Reminder Date (1-28)"
                    className={classes.input}
                    value={createPayAppReminderDate}
                    onChange={(ev) => setCreatePayAppReminderDate(Number(ev.target.value))}
                    InputProps={{
                      inputProps: {
                        max: 28,
                        min: 1,
                      },
                    }}
                  />
                </CardContent>
                <LocationCard location={location} setLocation={setLocation} />
                <CardActions className={classes.actions}>
                  <Button variant="contained" color="primary" type="submit">
                    Create company
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
