import { gql } from '@apollo/client'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CheckIcon from '@mui/icons-material/Check'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import copy from 'copy-to-clipboard'
import { useCallback, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useRotateExternalApiTokenMutation } from '../../common/graphql/apollo-operations'

gql`
  mutation rotateExternalApiToken($companyId: ID!) {
    rotateExternalApiToken(companyId: $companyId)
  }
`

type CompanyExternalApiTokenDialogProps = {
  companyId: string
  open: boolean
  onClose: () => void
}

/**
 * Dialog for rotating the external API token of a company.
 */
export function CompanyExternalApiTokenDialog({
  companyId,
  open,
  onClose,
}: CompanyExternalApiTokenDialogProps) {
  const [rotate] = useRotateExternalApiTokenMutation()
  const [token, setToken] = useState<string | null>(null)
  const [didCopy, setDidCopy] = useState(false)
  const snackbar = useSitelineSnackbar()

  const handleGenerate = useCallback(() => {
    const confirmation = window.confirm(
      'Are you sure you want to rotate the external API token for this company?'
    )
    if (!confirmation) {
      return
    }
    snackbar.showLoading()
    rotate({
      variables: { companyId },
    })
      .then((result) => {
        snackbar.closeAll()
        setToken(result.data?.rotateExternalApiToken ?? null)
      })
      .catch((err) => snackbar.showError(err))
  }, [companyId, rotate, snackbar])

  const handleCopy = useCallback(() => {
    copy(token ?? '')
    setDidCopy(true)
    setTimeout(() => setDidCopy(false), 5000)
  }, [token])

  return (
    <Dialog open={open} onClose={onClose}>
      <Card>
        <CardHeader title="External API Token"></CardHeader>
        <CardContent>
          <Alert severity="warning">
            Rotating the external API token for this company will immediately invalidate the old
            token and prevent any external API call using the old token. The public-facing API is
            unaffected. The new token will only be shown once. You must save the new token in
            1Password otherwise it will be lost forever.
          </Alert>
          {token && (
            <TextField
              sx={{ marginTop: 2 }}
              fullWidth
              type="password"
              value={token}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopy} edge="end">
                      {didCopy ? <CheckIcon htmlColor="#2ecc71" /> : <AssignmentIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          )}
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={handleGenerate}>
            Generate a new API token
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}
