import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  colors,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  getTemplateVariablesKeys,
  pdfTypes,
  templateVariablesDocumentation,
} from 'siteline-common-all'
import {
  fuseSearch,
  loadTemplateVariables,
  lookupTemplateVariable,
  makeStylesFast,
} from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    '& h4': {
      marginBottom: theme.spacing(1),
    },
    '& .variable-set': {
      marginRight: theme.spacing(2),
      width: 200,
    },
    '& .annotations': {
      color: colors.grey[700],
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
  },
}))

type VariableSet = keyof typeof templateVariablesDocumentation
const variableSetOptions: VariableSet[] = [
  'payAppUnitPrice',
  'payAppLumpSum',
  'payAppQuick',
  'lienWaiver',
  'legalRequirement',
]

export default function FormTemplateVariableLookup() {
  const classes = useStyles()
  const [templateVariables, setTemplateVariables] = useState<string[]>([])
  const [variableSet, setVariableSet] = useState<VariableSet>('payAppLumpSum')
  const [selectedVariable, setSelectedVariable] = useState<string | undefined>()
  const [results, setResults] = useState<pdfTypes.TemplateVariableLookup[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadTemplateVariables()
      .then((vars) => {
        const templateVariables = getTemplateVariablesKeys(vars[variableSet])
        setTemplateVariables(templateVariables)
      })
      .catch(() => setTemplateVariables([]))
  }, [variableSet])

  useEffect(() => {
    if (!selectedVariable) {
      return
    }
    setLoading(true)
    setResults([])
    lookupTemplateVariable(selectedVariable)
      .then((response) => {
        const sortedResults = _.sortBy(response, (entry) => entry.formTemplateVersion.fileType)
        setResults(sortedResults)
      })
      .catch(() => {
        setResults([])
      })
      .finally(() => setLoading(false))
  }, [selectedVariable])

  return (
    <Page className={classes.root} title="Form Template Variable Lookup">
      <Typography variant="h4">Form Template Variable Lookup</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              subheader="Choose a variable set and then a template variable inside that set. It's
              possible that some variables are used across more than one variable set."
            />
            <CardContent>
              <FormControl>
                <Select
                  variant="outlined"
                  value={variableSet}
                  onChange={(ev) => setVariableSet(ev.target.value as VariableSet)}
                  className="variable-set"
                >
                  {variableSetOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <Autocomplete
                  freeSolo
                  options={templateVariables}
                  filterOptions={(options, { inputValue }) => {
                    const results = fuseSearch(options, inputValue)
                    if (results.length === 0) {
                      return [inputValue]
                    }
                    return results
                  }}
                  groupBy={(option: string) => option.split('.').slice(0, -1).join('.')}
                  onChange={(event, newSelectedVariable: string | null) => {
                    setSelectedVariable(newSelectedVariable ?? undefined)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ width: 500 }}
                      label="Choose template variable..."
                    />
                  )}
                />
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        {selectedVariable && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`${selectedVariable} is used on the following forms:`} />
              <CardContent>
                {loading && <Loader />}
                <ol>
                  {results.map(({ formTemplate, formTemplateVersion, formTemplateAnnotations }) => (
                    <li key={formTemplateVersion.id}>
                      <Link
                        component={RouterLink}
                        to={`/templates/${formTemplate.id}/edit`}
                        underline="hover"
                      >
                        {formTemplateVersion.fileType} - {formTemplate.userVisibleName} - v
                        {formTemplateVersion.versionNumber}
                      </Link>
                      {formTemplateAnnotations.length > 0 && (
                        <div className="annotations">
                          Annotation IDs:&nbsp;
                          {formTemplateAnnotations.map((annotation) => annotation.id).join(', ')}
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Page>
  )
}
