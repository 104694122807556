import _ from 'lodash'
import moment from 'moment-timezone'
import {
  BillingType,
  ContractListSubmitViaFilter,
  ImportIntegrationComboJobMethod,
  IntegrationType,
  RetentionTrackingLevel,
  TaxCalculationType,
  WriteSyncOperationStatus,
} from '../enums.js'
import type {
  CompanyIntegrationMetadata,
  CompanyIntegrationMetadataSageIntacct,
} from '../types/company-integration.js'
import type * as integrationTypes from '../types/integration.js'
import {
  ERP_SYNC_FIELD_INTRODUCED_AT,
  MAX_FOUNDATION_INVOICE_NUMBER_LENGTH,
  MAX_SAGE_INVOICE_CODE_LENGTH,
} from './integration-sync.js'
import { normalizeString } from './strings.js'

const DIGITS_REGEX = /^[0-9]*$/

// Vault ID for "Siteline Customer Login" in 1Password.
// This is the only vault that our service account can access.
export const onePasswordIntegrationCredentialsVaultId = '2mivwtq423jypegq5tqsvpjsme'

// This substring is used for identifying an error where the Agave Connector is offline, since we
// have special handling across the app to display this error in a uniform way
export const AGAVE_CONNECTOR_OFFLINE_ERROR_SUBSTRING = 'Agave Connector is offline'

export type LineItemIdentifier = {
  sortOrder?: number
  code: string
  name: string
  totalValue: number
}

export type Score = 'zero' | 'low' | 'medium' | 'high'

export type LineItemScoring = {
  code: Score
  name: Score
  totalValue: Score
}

export function getScoringForIntegration(type: IntegrationType): LineItemScoring {
  switch (type) {
    // Acumatica has a code that either matches the line item position, or is
    // always empty. It is irrelevant to matching and could potentially mess up the algorithm if
    // items were reordered.
    case IntegrationType.ACUMATICA:
      return { code: 'zero', name: 'medium', totalValue: 'low' }

    // When reading line items from Procore, we infer the code from the line number and change order position.
    // When writing back to Procore, we can extract the line number from the invoice.
    // Because of the way we infer the code, it is not very reliable, so we give it a low score.
    // Approved SSOVs cannot be changed, we're not concerned about line numbers changing over time.
    case IntegrationType.PROCORE:
      return { code: 'low', name: 'high', totalValue: 'medium' }

    // Use custom scoring when matching line items in Sage. The "code" isn't as useful because the ERP
    // enforces a 2 alphanumeric character max on codes, so it's common for folks to use 1, 2, 3, etc.
    // and we have many false positives in Siteline.
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return { code: 'low', name: 'high', totalValue: 'medium' }

    // For other integrations, code is usually the most significant because it has less variance
    // in case and special characters. Name can change a lot, and the total value can have a lot of duplicates.
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.VISTA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return { code: 'high', name: 'medium', totalValue: 'low' }
  }
}

export type Match<Source, Destination> = {
  source: Source
  destination: Destination
  score: number
}

export type DuplicateSource<Source, Destination> = {
  sources: Source[]
  destination: Destination
}

export type DuplicateDestination<Source, Destination> = {
  source: Source
  destinations: Destination[]
}

export type MatchResult<Source, Destination> = {
  matches: Match<Source, Destination>[]
  duplicateSources: DuplicateSource<Source, Destination>[]
  duplicateDestinations: DuplicateDestination<Source, Destination>[]
  extraSources: Source[]
  extraDestinations: Destination[]
  emptySources: Source[]
  emptyDestinations: Destination[]
}

export function getLineItemDescriptionForMatching(lineItem: LineItemIdentifier): string {
  return `code '${lineItem.code}' (name '${lineItem.name}')`
}

/**
 * Returns the name of an integration, with an optional `shortName` parameter.
 * In most cases:
 *  - For displaying an integration name within the context of a contract, use `shortName = true`
 *  - For displaying an integration name outside of a contract, use `shortName = false` (the default)
 */
export function getIntegrationBaseName(type: IntegrationType, shortName = false): string {
  switch (type) {
    case IntegrationType.ACUMATICA:
      return 'Acumatica'
    case IntegrationType.GC_PAY:
      return 'GCPay'
    case IntegrationType.TEXTURA:
      return 'Textura'
    case IntegrationType.TEST:
      return 'Test'
    case IntegrationType.PROCORE:
      return 'Procore'
    case IntegrationType.FOUNDATION:
      return 'Foundation'
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      return shortName ? 'Sage' : 'Sage 100'
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return shortName ? 'Sage' : 'Sage 300'
    case IntegrationType.SAGE_INTACCT:
      return shortName ? 'Sage' : 'Sage Intacct'
    case IntegrationType.SPECTRUM:
      return 'Spectrum'
    case IntegrationType.VISTA:
      return 'Vista'
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      return 'QuickBooks'
    case IntegrationType.FOUNDATION_FILE:
      return shortName ? 'Foundation' : 'Foundation (file)'
    case IntegrationType.COMPUTER_EASE_FILE:
      return 'ComputerEase'
  }
}

/**
 * Returns the long/short name of an integration by type and optional label.
 * In most cases, you can just use `integration.shortName` / `integration.longName` returned by the GraphQL API.
 */
export function getIntegrationName(
  type: IntegrationType,
  shortName = false,
  label?: string | null
): string {
  const base = getIntegrationBaseName(type, shortName)
  if (label && !shortName) {
    return `${base} (${label})`
  }
  return base
}

/**
 * This is the default scoring mechanism for each "significance".
 * We want 2 lows > 1 medium, 2 medium > 1 high, 3 lows > 1 high.
 *
 * "This feels right" – Oliver Manheim, Dec 2023.
 */
function scoreToPoints(score: Score): number {
  switch (score) {
    case 'zero':
      return 0
    case 'low':
      return 0.3
    case 'medium':
      return 0.5
    case 'high':
      return 0.7
  }
}

/**
 * Gets the matching score between a source and destination.
 */
function getLineItemScore(
  source: LineItemIdentifier,
  destination: LineItemIdentifier,
  scoring: LineItemScoring
): number {
  const srcCode = normalizeString(source.code)
  const dstCode = normalizeString(destination.code)
  const srcName = normalizeString(source.name)
  const dstName = normalizeString(destination.name)

  // If the item code matches its sort order, its significance should be greatly reduced to allow for
  // line item insertions.
  const srcOrderMatchesCode =
    _.isNumber(source.sortOrder) &&
    normalizeString(source.sortOrder.toString()) === normalizeString(srcCode)
  const dstOrderMatchesCode =
    _.isNumber(destination.sortOrder) &&
    normalizeString(destination.sortOrder.toString()) === normalizeString(dstCode)

  let score = 0

  // If codes match, add to the score
  if (srcCode === dstCode && srcCode.length > 0 && dstCode.length > 0) {
    if (srcOrderMatchesCode && dstOrderMatchesCode) {
      score += scoreToPoints('low')
    } else {
      score += scoreToPoints(scoring.code)
    }
  }

  // If names match, add to the score
  if (srcName === dstName && srcName.length > 0 && dstName.length > 0) {
    score += scoreToPoints(scoring.name)
  }

  // If values match, add to the score
  if (source.totalValue === destination.totalValue) {
    score += scoreToPoints(scoring.totalValue)
  }

  return score
}

/**
 * Gets the group matching score between a source and destination.
 */
function getLineItemGroupScore(
  source: LineItemGroupIdentifier,
  destination: LineItemGroupIdentifier
): number {
  const srcName = normalizeString(source.name)
  const dstName = normalizeString(destination.name)

  const srcCode = normalizeString(source.code ?? '')
  const dstCode = normalizeString(destination.code ?? '')

  let score = 0

  // If names match, add to the score
  if (srcName === dstName && srcName.length > 0 && dstName.length > 0) {
    score += 0.5
  }

  // If codes match, add to the score
  if (srcCode === dstCode && srcCode.length > 0 && dstCode.length > 0) {
    score += 1
  }

  return score
}

type GetMatchesParams<Source, Destination> = {
  sources: Source[]
  destinations: Destination[]
  getScore: (source: Source, destination: Destination) => number
  isEmpty: (item: Source | Destination) => boolean
}

/**
 * Computes the best matches given an array of sources and an array of destinations.
 * This algorithm does not throw. You must handle errors in the caller (eg: if you want to prevent extra sources).
 *
 * The algorithm is described in depth here:
 * https://www.notion.so/siteline/Integration-Line-Item-Matching-d242626db6a14d4e82dba8449d7a1f40
 *
 * Note that we use generics so that each integration can attach more data to the source/destination,
 * like a puppeteer element handle (GCPay) or an invoice data row (Textura).
 */
export function getMatches<Source, Destination>({
  sources,
  destinations,
  getScore,
  isEmpty,
}: GetMatchesParams<Source, Destination>): MatchResult<Source, Destination> {
  const matches: Match<Source, Destination>[] = []
  const duplicateSources: DuplicateSource<Source, Destination>[] = []
  let duplicateDestinations: DuplicateDestination<Source, Destination>[] = []
  const extraDestinations: Destination[] = []

  const emptySources = sources.filter(isEmpty)
  const emptyDestinations = destinations.filter(isEmpty)

  // Filter out empty sources and destinations for the rest of the algorithm
  const filteredSources = _.without(sources, ...emptySources)
  const filteredDestinations = _.without(destinations, ...emptyDestinations)

  // For each line item in Destination, find sources with the highest non-0 score
  for (const dst of filteredDestinations) {
    const maxSourceScore = Math.max(...filteredSources.map((src) => getScore(src, dst)))

    // Find sources with the highest non-0 score.
    // Filter out sources that have a higher destination score.
    const potentialSources = filteredSources
      .filter((src) => getScore(src, dst) === maxSourceScore)
      .filter((src) => !filteredDestinations.some((dst) => getScore(src, dst) > maxSourceScore))

    // If there is no source, it’s an extra destination
    if (maxSourceScore === 0 || potentialSources.length === 0) {
      extraDestinations.push(dst)
      continue
    }

    // If there are multiple sources, they’re duplicate sources
    if (potentialSources.length > 1) {
      duplicateSources.push({
        sources: potentialSources,
        destination: dst,
      })
      continue
    }

    const src = potentialSources[0]

    // If another destination has the same score for the same source, it’s a duplicate destination
    const destinationsWithSameScore = filteredDestinations.filter(
      (dst) => getScore(src, dst) === maxSourceScore
    )
    if (destinationsWithSameScore.length > 1) {
      duplicateDestinations.push({
        source: src,
        destinations: destinationsWithSameScore,
      })
      continue
    }

    // Otherwise, assign source => destination
    matches.push({
      source: src,
      destination: dst,
      score: maxSourceScore,
    })
  }

  // Now that we have all the matches, go back through our list of duplicate destinations. If any
  // of the duplicates matched a different source, we can eliminate it from the list of duplicates.
  // If that leaves only a single destination, then we can assume that destination is the match
  // for the source, and add it to the list of matches.
  for (const duplicateDst of duplicateDestinations) {
    const { source, destinations } = duplicateDst
    // Remove destinations that are matched by a different source
    const unmatchedDestinations = destinations.filter(
      (dst) => !matches.some((match) => match.destination === dst)
    )
    // Remove this duplicateDestination. We'll add it back if there are still multiple duplicates
    // after filtering.
    duplicateDestinations = duplicateDestinations.filter((duplicate) => duplicate !== duplicateDst)
    // If only a single destination remains, consider this a match instead
    if (unmatchedDestinations.length === 1) {
      const remainingDestination = unmatchedDestinations[0]
      matches.push({
        source,
        destination: remainingDestination,
        score: getScore(source, remainingDestination),
      })
    } else {
      duplicateDestinations.push({ source, destinations: unmatchedDestinations })
    }
  }

  // If there are line items in Source that are unmatched, not duplicate sources,
  // and don’t have duplicate destinations, they are extra sources
  const extraSources = filteredSources.filter(
    (src) =>
      !matches.some((match) => match.source === src) &&
      !duplicateSources.some((duplicate) => duplicate.sources.includes(src)) &&
      !duplicateDestinations.some((duplicate) => duplicate.source === src)
  )

  // De-duplicate the duplicate arrays, because we might add them multiple times when processing
  // destinations.
  const finalDuplicateSources = _.uniqBy(duplicateSources, (duplicate) => duplicate.destination)
  const finalDuplicateDestinations = _.uniqBy(
    duplicateDestinations,
    (duplicate) => duplicate.source
  )

  return {
    matches,
    duplicateSources: finalDuplicateSources,
    duplicateDestinations: finalDuplicateDestinations,
    extraSources,
    extraDestinations,
    emptySources,
    emptyDestinations,
  }
}

export type LineItemGroupIdentifier = {
  name: string
  code: string | null
}

type GetLineItemGroupMatchesParams<
  Source extends LineItemGroupIdentifier,
  Destination extends LineItemGroupIdentifier,
> = {
  sources: Source[]
  destinations: Destination[]
}

/**
 * Computes the best matches given an array of source line item groups and an array of destination line item groups.
 */
export function getLineItemGroupMatches<
  Source extends LineItemGroupIdentifier,
  Destination extends LineItemGroupIdentifier,
>({
  sources,
  destinations,
}: GetLineItemGroupMatchesParams<Source, Destination>): MatchResult<Source, Destination> {
  const isEmpty = (group: LineItemGroupIdentifier): boolean => {
    const isNameEmpty = normalizeString(group.name).length === 0
    const isCodeEmpty = !group.code || normalizeString(group.code).length === 0
    return isCodeEmpty && isNameEmpty
  }
  return getMatches({ sources, destinations, isEmpty, getScore: getLineItemGroupScore })
}

/**
 * Computes the best matches given an array of source line items and an array of destination line items.
 * This algorithm does not throw. You must handle errors in the caller (eg: if you want to prevent extra sources).
 *
 * The algorithm is described in depth here:
 * https://www.notion.so/siteline/Integration-Line-Item-Matching-d242626db6a14d4e82dba8449d7a1f40
 *
 * Note that we use generics so that each integration can attach more data to the source/destination,
 * like a puppeteer element handle (GCPay) or an invoice data row (Textura).
 */
export function getLineItemMatches<
  Source extends LineItemIdentifier,
  Destination extends LineItemIdentifier,
>(
  sources: Source[],
  destinations: Destination[],
  scoring: LineItemScoring
): MatchResult<Source, Destination> {
  // Add each empty source and destination to emptySources and emptyDestinations
  const isEmpty = (lineItem: LineItemIdentifier): boolean => {
    const isCodeEmpty = normalizeString(lineItem.code).length === 0 || scoring.code === 'zero'
    const isNameEmpty = normalizeString(lineItem.name).length === 0 || scoring.name === 'zero'
    const isTotalValueEmpty = lineItem.totalValue === 0 || scoring.totalValue === 'zero'
    return isCodeEmpty && isNameEmpty && isTotalValueEmpty
  }

  return getMatches({
    sources,
    destinations,
    isEmpty,
    getScore: (src, dst) => getLineItemScore(src, dst, scoring),
  })
}

/**
 * Integrations in different groups may provide data for and manage different
 * parts of the billing process.
 */
export enum IntegrationTypeFamily {
  GC_PORTAL = 'GC_PORTAL',
  ERP = 'ERP',
}

export function getIntegrationTypeFamily(type: IntegrationType): IntegrationTypeFamily {
  switch (type) {
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.PROCORE:
      return IntegrationTypeFamily.GC_PORTAL
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return IntegrationTypeFamily.ERP
  }
}

type Integration = {
  type: IntegrationType
  // Use Record<K,V> for compatibility with both node and web code. We cast to the correct mappings
  // where this is used anyways.
  mappings: Record<string, unknown>
}
export function getIntegrationProjectId(integration: Integration): string | null {
  switch (integration.type) {
    case IntegrationType.TEXTURA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsTextura
      return mappings.project.texturaProjectId ?? null
    }
    case IntegrationType.PROCORE: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsProcore
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.FOUNDATION: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsFoundation
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE: {
      const mappings = integration.mappings as
        | integrationTypes.IntegrationMappingsSage300Cre
        | integrationTypes.IntegrationMappingsSage100Contractor
      return mappings.project.hh2ProjectId ?? null
    }
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE: {
      const mappings =
        integration.mappings as integrationTypes.IntegrationMappingsSage100ContractorAgave
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.SAGE_300_CRE_AGAVE: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsSage300CreAgave
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.SPECTRUM: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsViewpointSpectrum
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.VISTA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsViewpointVista
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.GC_PAY: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsGcPay
      return mappings.project.gcPayProjectId ?? null
    }
    case IntegrationType.ACUMATICA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsAcumatica
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.SAGE_INTACCT: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsSageIntacct
      return mappings.project.agaveProjectId ?? null
    }
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return null
  }
}

export function getIntegrationContractId(integration: Integration): string | null {
  switch (integration.type) {
    case IntegrationType.TEXTURA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsTextura
      return mappings.contract.texturaContractId ?? null
    }
    case IntegrationType.PROCORE: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsProcore
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE: {
      const mappings = integration.mappings as
        | integrationTypes.IntegrationMappingsSage300Cre
        | integrationTypes.IntegrationMappingsSage100Contractor
      return mappings.contract.hh2ContractId ?? null
    }
    case IntegrationType.GC_PAY: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsGcPay
      return mappings.contract.gcPaySovId ?? null
    }
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE: {
      const mappings =
        integration.mappings as integrationTypes.IntegrationMappingsSage100ContractorAgave
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.SAGE_300_CRE_AGAVE: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsSage300CreAgave
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.VISTA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsViewpointVista
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.SPECTRUM: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsViewpointSpectrum
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.ACUMATICA: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsAcumatica
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.FOUNDATION: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsFoundation
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.SAGE_INTACCT: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsSageIntacct
      return mappings.contract.agaveContractId ?? null
    }
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return null
  }
}

export function getIntegrationAssociatedCompanyId(integration: Integration): string | null {
  switch (integration.type) {
    case IntegrationType.PROCORE: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsProcore
      return mappings.associatedCompanyId ?? null
    }
    case IntegrationType.TEXTURA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.GC_PAY:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return null
  }
}

/**
 * Returns the invoice number from a write sync payload.
 */
export function getInvoiceNumberFromPayload(
  payload: integrationTypes.WriteSyncPayload
): string | null {
  switch (payload.type) {
    case 'payAppFoundation':
      return payload.invoiceNumber ?? null
    case 'payAppSage100':
      return payload.invoiceCode
    case 'payAppLineItemsSage300':
      return payload.invoiceCode
    case 'payAppLineItemsSpectrum':
      return payload.invoiceCode
    case 'payAppLineItemsVista':
      return payload.invoiceCode ?? null
    case 'payAppLineItemsSageIntacct':
      return payload.invoiceCode ?? null
    case 'payAppQuickbooks':
      return payload.invoiceNumber ?? null
    case 'payAppTextura':
    case 'payAppGcPay':
    case 'payAppProcore':
    case 'payAppLineItemsAcumatica':
    case 'payAppManual':
    case 'payAppFoundationFileGenie':
    case 'payAppFoundationFileFsi':
    case 'payAppComputerEase':
    case 'lienWaivers':
    case 'legalRequirement':
      return null
  }
}

type IntegrationCustomer = {
  id: string
  name: string
}

export function getIntegrationCustomer(integration: Integration): IntegrationCustomer | null {
  switch (integration.type) {
    case IntegrationType.SAGE_300_CRE: {
      const mappings = integration.mappings as integrationTypes.IntegrationMappingsSage300Cre
      if (!mappings.customer) {
        return null
      }
      return {
        id: mappings.customer.hh2CustomerId,
        name: mappings.customer.name,
      }
    }
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE: {
      const mappings = integration.mappings as
        | integrationTypes.IntegrationMappingsViewpointVista
        | integrationTypes.IntegrationMappingsViewpointSpectrum
        | integrationTypes.IntegrationMappingsAcumatica
        | integrationTypes.IntegrationMappingsFoundation
        | integrationTypes.IntegrationMappingsSageIntacct
        | integrationTypes.IntegrationMappingsSage100ContractorAgave
        | integrationTypes.IntegrationMappingsSage300CreAgave
      if (!mappings.customer) {
        return null
      }
      return {
        id: mappings.customer.agaveCustomerId,
        name: mappings.customer.name,
      }
    }
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.PROCORE:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return null
  }
}

/**
 * Whether an integration supports the specified billing type.
 */
export function supportsBillingType(type: IntegrationType, billingType: BillingType): boolean {
  switch (type) {
    case IntegrationType.TEXTURA:
      return billingType === BillingType.LUMP_SUM
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.ACUMATICA:
    case IntegrationType.SAGE_INTACCT:
      return [
        BillingType.LUMP_SUM,
        BillingType.UNIT_PRICE,
        BillingType.TIME_AND_MATERIALS,
      ].includes(billingType)
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.FOUNDATION:
      return [BillingType.LUMP_SUM, BillingType.UNIT_PRICE].includes(billingType)
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.TEST:
      return true
  }
}

/**
 * Whether an integration is ready to be used for onboarding new projects.
 */
export function supportsProjectOnboarding(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.ACUMATICA:
    case IntegrationType.PROCORE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return true
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      // File-based systems don't support project onboarding of any billing
      return false
  }
}

/** Whether an integration supports reading tax groups */
export function supportsReadingTaxGroups(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports setting the `taxGroup` field in `Integration.mappings`.
 * We use this field to set the hh2/agave tax group ID when writing an AR invoice.
 * This was used as a workaround before we had proper tax support. Now that we have tax groups in Siteline,
 * these mappings are stored on the Siteline tax groups directly.
 */
export function supportsTaxGroupInIntegrationMappings(type: IntegrationType): boolean {
  switch (type) {
    // Spectrum still uses tax groups on integration mappings
    case IntegrationType.SPECTRUM:
      return true

    // Sage 100/300 now have support for using integration mappings on Siteline tax groups directly
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      return false

    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.PROCORE:
    case IntegrationType.VISTA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports using the mappings from `TaxGroup.integrationMappings`.
 * This is the new way of linking hh2/agave tax groups to Siteline.
 * Instead of having a static hh2/agave tax group ID on the contract, Siteline now has its own
 * tax groups which contain a list of integration mappings (one to many).
 * During a sync, we look for `contract.defaultTaxGroup.integrationMappings` and extract the hh2/agave
 * tax group ID to use on the invoice.
 */
export function supportsLinkingTaxGroup(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.VISTA:
      return true

    // Not yet supported
    case IntegrationType.SPECTRUM:
      return false

    case IntegrationType.ACUMATICA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports reading data from the 3rd-party service.
 */
export function supportsReadSync(
  type: IntegrationType,
  readType: 'changeOrders' | 'sov' | 'vendors'
): boolean {
  switch (readType) {
    case 'changeOrders': {
      switch (type) {
        case IntegrationType.ACUMATICA:
        case IntegrationType.GC_PAY:
        case IntegrationType.TEXTURA:
        case IntegrationType.TEST:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.PROCORE:
        case IntegrationType.SAGE_INTACCT:
          return true
        case IntegrationType.SPECTRUM:
        case IntegrationType.VISTA:
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.FOUNDATION:
        case IntegrationType.COMPUTER_EASE_FILE:
          return false
      }
      break
    }
    case 'sov': {
      switch (type) {
        case IntegrationType.ACUMATICA:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.GC_PAY:
        case IntegrationType.TEXTURA:
        case IntegrationType.TEST:
        case IntegrationType.VISTA:
        case IntegrationType.SPECTRUM:
        case IntegrationType.PROCORE:
        case IntegrationType.FOUNDATION:
        case IntegrationType.SAGE_INTACCT:
          return true
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.COMPUTER_EASE_FILE:
          return false
      }
      break
    }
    case 'vendors': {
      switch (type) {
        case IntegrationType.ACUMATICA:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.TEXTURA:
        case IntegrationType.VISTA:
        case IntegrationType.SPECTRUM:
        case IntegrationType.FOUNDATION:
        case IntegrationType.SAGE_INTACCT:
          return true
        // If any new GC portals add support for reading vendors, update the corresponding switch
        // case in `ProjectSettingsVendorList`
        case IntegrationType.GC_PAY:
        case IntegrationType.TEST:
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.PROCORE:
        case IntegrationType.COMPUTER_EASE_FILE:
          return false
      }
    }
  }
}

/**
 * Whether an integration supports writing data to the 3rd-party service.
 */
export function supportsWriteSync(
  integrationType: IntegrationType,
  syncType: 'payApp' | 'payAppLineItems' | 'lienWaivers' | 'legalRequirement'
): boolean {
  switch (syncType) {
    case 'payApp':
      switch (integrationType) {
        case IntegrationType.TEXTURA:
        case IntegrationType.GC_PAY:
        case IntegrationType.PROCORE:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.FOUNDATION:
          return true
        case IntegrationType.ACUMATICA:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.VISTA:
        case IntegrationType.SPECTRUM:
        case IntegrationType.SAGE_INTACCT:
        case IntegrationType.TEST:
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.COMPUTER_EASE_FILE:
          return false
      }
      break
    case 'payAppLineItems': {
      switch (integrationType) {
        case IntegrationType.ACUMATICA:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.VISTA:
        case IntegrationType.SPECTRUM:
        case IntegrationType.SAGE_INTACCT:
          return true
        case IntegrationType.FOUNDATION:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.GC_PAY:
        case IntegrationType.TEXTURA:
        case IntegrationType.TEST:
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.PROCORE:
        case IntegrationType.COMPUTER_EASE_FILE:
          return false
      }
      break
    }
    case 'lienWaivers': {
      switch (integrationType) {
        case IntegrationType.TEXTURA:
        case IntegrationType.TEST:
        case IntegrationType.GC_PAY:
          return true
        case IntegrationType.ACUMATICA:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.SPECTRUM:
        case IntegrationType.VISTA:
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.FOUNDATION:
        case IntegrationType.PROCORE:
        case IntegrationType.COMPUTER_EASE_FILE:
        case IntegrationType.SAGE_INTACCT:
          return false
      }
      break
    }
    case 'legalRequirement': {
      switch (integrationType) {
        case IntegrationType.TEXTURA:
        case IntegrationType.TEST:
        case IntegrationType.GC_PAY:
          return true
        case IntegrationType.ACUMATICA:
        case IntegrationType.SAGE_100_CONTRACTOR:
        case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        case IntegrationType.SAGE_300_CRE:
        case IntegrationType.SAGE_300_CRE_AGAVE:
        case IntegrationType.SPECTRUM:
        case IntegrationType.VISTA:
        case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
        case IntegrationType.FOUNDATION_FILE:
        case IntegrationType.FOUNDATION:
        case IntegrationType.PROCORE:
        case IntegrationType.COMPUTER_EASE_FILE:
        case IntegrationType.SAGE_INTACCT:
          return false
      }
      break
    }
  }
}

/** Whether an integration supports automatically generating a code when creating invoices */
export function supportsInvoiceAutoCode(
  integrationType: IntegrationType,
  companyIntegrationMetadata: CompanyIntegrationMetadata | undefined
): boolean {
  switch (integrationType) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.VISTA:
      return true
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return false

    // NOTE that Sage Intacct sometimes automatically generates a code and sometimes doesn't
    case IntegrationType.SAGE_INTACCT: {
      if (!companyIntegrationMetadata) {
        throw Error('Missing integration metadata for Sage Intacct integration')
      }
      const sageIntacctMetadata =
        companyIntegrationMetadata as CompanyIntegrationMetadataSageIntacct
      return sageIntacctMetadata.hasAutoNumberingEnabled
    }
  }
}

/** Whether we are able to guess the latest invoice code when creating invoices */
export function supportsGeneratingInvoiceCode(integrationType: IntegrationType): boolean {
  switch (integrationType) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return true

    case IntegrationType.ACUMATICA:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports passing a manual invoice code,
 * as opposed to generating it automatically every time.
 */
export function supportsManualInvoiceCode(
  integrationType: IntegrationType,
  companyIntegrationMetadataSageIntacct?: CompanyIntegrationMetadataSageIntacct
): boolean {
  switch (integrationType) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
      return true

    // NOTE that Sage Intacct sometimes automatically generates a code and sometimes doesn't
    case IntegrationType.SAGE_INTACCT:
      if (!companyIntegrationMetadataSageIntacct) {
        throw Error('Missing integration metadata for Sage Intacct integration')
      }
      return !companyIntegrationMetadataSageIntacct.hasAutoNumberingEnabled

    // Acumatica generates invoice codes automatically and they cannot be manually entered.
    case IntegrationType.ACUMATICA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return false
  }
}

/**
 *  Whether an integration supports reading vendors from the 3rd-party service
 */
export function supportsVendorRefresh(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.TEXTURA:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

export function supportsBillingOptOut(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.GC_PAY:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports reading vendor invoices from the 3rd-party service. This is
 * typically used only on ERP systems.
 *
 * All integrations that return true should also return a boolean below in the corresponding
 * `supportsVendorInvoicesDateRangeFilter` util.
 */
export function supportsVendorInvoices(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * For integrations that support reading vendor invoices, some allow for filtering by date
 * while others only allow querying by job. When querying by date is possible, the API requires
 * a date range be provided to optimize performance. When query by date is not possible, a date
 * range should not be provided so the larger data set isn't re-queried repeatedly.
 */
export function supportsVendorInvoicesDateRangeFilter(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
      return false
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.PROCORE: {
      const name = getIntegrationName(type)
      throw new Error(`Reading vendor invoices is not yet supported with ${name}`)
    }
  }
}

/**
 * For integrations that support reading vendor invoices, some allow for filtering by vendor name
 * when the Siteline vendor isn't linked to a vendor in the integration. When that's possible, we
 * still show the button for calculating the invoice amount from ERP, and search by name. To figure
 * out if an invoice can support this, look through the source data returned for an AP invoice and
 * see if the full vendor name is included anywhere - if so, try including a filter by that source
 * data field in the AP invoices request.
 */
export function supportsVendorInvoicesVendorNameFilter(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SPECTRUM:
      return true
    case IntegrationType.VISTA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return false
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.PROCORE: {
      const name = getIntegrationName(type)
      throw new Error(`Reading vendor invoices is not yet supported with ${name}`)
    }
  }
}

export function supportsMarkAsSynced(integrationType: IntegrationType): boolean {
  const isGcPortalIntegration =
    getIntegrationTypeFamily(integrationType) === IntegrationTypeFamily.GC_PORTAL
  const supportsSync = supportsWriteSync(integrationType, 'payApp')
  return isGcPortalIntegration && supportsSync
}

export function supportsMarkAsSyncedErp(integrationType: IntegrationType): boolean {
  return getIntegrationTypeFamily(integrationType) === IntegrationTypeFamily.ERP
}

export function supportsBulkImport(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.VISTA:
    case IntegrationType.TEXTURA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.PROCORE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return true
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return false
  }
}

export function supportsReadingVendorContacts(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
      return true
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return true

    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.SAGE_INTACCT:
      return false

    // The following integrations can read vendors, but not their contacts.
    // For Textura specifically, it's because contact emails are not exposed.
    case IntegrationType.TEXTURA:
      return false

    // The following integrations don't have support for reading vendors at all
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return false
  }
}

/**
 * Whether a specific integration supports reading cost data for over/under billing.
 */
export function supportsReadingCost(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SPECTRUM:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.GC_PAY:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
    case IntegrationType.PROCORE:
    case IntegrationType.SAGE_INTACCT:
      return false
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      // We should be able to support reading cost data for both Sage integrations via Agave, but
      // need reports from customers with the integration to validate the data
      return false
  }
}

export function requiresCredential(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return true
    case IntegrationType.TEST:
      return false
  }
}

export function supportsRetentionTrackingLevel(
  integrationType: IntegrationType,
  trackingLevel: RetentionTrackingLevel
): boolean {
  switch (integrationType) {
    case IntegrationType.GC_PAY:
      return [
        RetentionTrackingLevel.STANDARD,
        RetentionTrackingLevel.LINE_ITEM,
        RetentionTrackingLevel.PROJECT,
        RetentionTrackingLevel.NONE,
      ].includes(trackingLevel)
    case IntegrationType.PROCORE:
      return [RetentionTrackingLevel.STANDARD, RetentionTrackingLevel.NONE].includes(trackingLevel)
    case IntegrationType.ACUMATICA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return true
  }
}

export function supportsReadingLineItems(integrationType: IntegrationType): boolean {
  switch (integrationType) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
      return true
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return false
  }
}

export function supportsReadingPayments(integrationType: IntegrationType): boolean {
  switch (integrationType) {
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEST:
    case IntegrationType.GC_PAY:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration requires mappings (eg. integrationProjectId, integrationContractId) or not
 */
export function hasRequiredIntegrationMappings({
  type,
  integrationProjectId,
  integrationContractId,
}: {
  type: IntegrationType
  integrationProjectId: string | null
  integrationContractId: string | null
}): boolean {
  switch (type) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.GC_PAY:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.TEST:
    case IntegrationType.PROCORE:
    case IntegrationType.SAGE_INTACCT:
      // These integrations require both a project and contract ID mapping
      return _.isString(integrationProjectId) && _.isString(integrationContractId)
    case IntegrationType.FOUNDATION:
      // Foundation requires only a project ID, as contracts are not required
      return _.isString(integrationProjectId)
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      // Sage 100 requires only a project ID, as contracts are not required
      return _.isString(integrationProjectId)
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      // File-based integrations do not require either mapping
      return true
  }
}

export function isAgaveIntegration(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.ACUMATICA:
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.PROCORE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return true
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return false
  }
}

export function supportsAgaveDebuggerLink(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
      return true
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return false
  }
}

export function usesAgaveConnector(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return true
    case IntegrationType.VISTA:
    case IntegrationType.SPECTRUM:
    case IntegrationType.FOUNDATION:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.ACUMATICA:
    case IntegrationType.TEXTURA:
    case IntegrationType.TEST:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

export function isHh2Integration(
  type: IntegrationType
): type is IntegrationType.SAGE_100_CONTRACTOR | IntegrationType.SAGE_300_CRE {
  switch (type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return false
  }
}

/**
 * Whether or not to expect a delay in data entered in the integration service appearing in the
 * results of Siteline requests
 */
export function doesIntegrationHaveDataDelay(type: IntegrationType): boolean {
  if (isHh2Integration(type)) {
    // Hh2 requests hit a sync client that fetches data from the ERPs' databases on a regular schedule,
    // so there can be a delay in data from the ERP showing up in hh2's results.
    return true
  }
  if (isAgaveIntegration(type)) {
    // Agave communicates directly with the ERPs' databases, so there is no delay.
    return false
  }
  // Other endpoints don't have a known delay because we either interface with the service directly
  // or they are file-based
  return false
}

/**
 * Translate integration type to submit via filter type so that it can be used in
 * the submit via filter.
 */
export function getSubmitViaTypeFromIntegrationType(
  type: IntegrationType
): ContractListSubmitViaFilter | null {
  switch (type) {
    case IntegrationType.GC_PAY:
      return ContractListSubmitViaFilter.GC_PAY
    case IntegrationType.PROCORE:
      return ContractListSubmitViaFilter.PROCORE
    case IntegrationType.TEXTURA:
      return ContractListSubmitViaFilter.TEXTURA
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.VISTA:
    case IntegrationType.SAGE_INTACCT:
      return null
  }
}

/**
 * Whether an integration supports setting a customer mapping.
 */
export function supportsSettingCustomer(type: IntegrationType): boolean {
  switch (type) {
    // The following integrations might not have a default customer on their contract, so we allow
    // setting a customer mapping to ensure invoices can be created.
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
      return true
      return true
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.TEST:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports tax calculation.
 */
export function supportsReadTaxCalculationType(
  type: IntegrationType,
  taxCalculationType: TaxCalculationType
): boolean {
  switch (type) {
    case IntegrationType.VISTA:
      // Vista supports reading both single and multiple tax calculation types
      return true
    case IntegrationType.SAGE_300_CRE_AGAVE:
      // Sage 300 supports reading both single and multiple tax calculation types, but only via
      // Agave (as hh2 does not seem to provide the line item tax detail)
      return true
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
      // Sage currently supports reading only single tax group by contract
      return taxCalculationType !== TaxCalculationType.MULTIPLE_TAX_GROUPS
    case IntegrationType.FOUNDATION:
    case IntegrationType.SPECTRUM:
    case IntegrationType.ACUMATICA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.TEST:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports tax calculation.
 */
export function supportsWriteTaxCalculation(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.VISTA:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      // These integrations support writing invoices with single or multiple tax calculation types
      return true
    case IntegrationType.FOUNDATION:
    case IntegrationType.SPECTRUM:
    case IntegrationType.ACUMATICA:
    case IntegrationType.GC_PAY:
    case IntegrationType.TEXTURA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.PROCORE:
    case IntegrationType.TEST:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether an integration supports credentials auto-rotation.
 */
export function supportsCredentialsAutoRotation(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.TEXTURA:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.VISTA:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether the integration supports reading line item groups
 */
export function supportsLineItemGroups(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.FOUNDATION:
      return true
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      // Sage 100 doesn't natively support line item grouping, but we may group line items by
      // proposal if multiple proposals exist on a contract in Sage
      return true
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      // Sage 300 doesn't natively support line item grouping, but we may group line items by job
      // for combo jobs if the company integration has the corresponding setting enabled
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
    case IntegrationType.SAGE_INTACCT:
      return false
  }
}

/**
 * Whether the integration supports reading SOVs with change orders as separate line items. This
 * corresponds to whether it should be allowed to set the company integration
 * `importChangeOrdersMethod` to 'separate-line-items' (on the company integration metadata).
 */
export function supportsImportingSeparateChangeOrderLineItems(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_INTACCT:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
      return false
  }
}

/**
 * Whether the integration supports reading integration combo jobs using different methods. Combo
 * jobs are integration projects where line items link to multiple jobs. Currently we only support
 * special handling for combo jobs in Sage 300.
 */
export function supportedComboJobImportMethodsForIntegration(
  type: IntegrationType
): ImportIntegrationComboJobMethod[] {
  switch (type) {
    case IntegrationType.SAGE_300_CRE:
      return [
        ImportIntegrationComboJobMethod.SINGLE_PROJECT_FLAT_SOV,
        ImportIntegrationComboJobMethod.SINGLE_PROJECT_GROUPED_SOV,
      ]
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return [
        ImportIntegrationComboJobMethod.SINGLE_PROJECT_FLAT_SOV,
        ImportIntegrationComboJobMethod.SINGLE_PROJECT_GROUPED_SOV,
        ImportIntegrationComboJobMethod.MULTIPLE_PROJECTS,
      ]
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
      return []
  }
}

/**
 * Generates a sequential invoice code based on a given set of invoice codes. We attempt to guess
 * the desired invoice code by taking the highest numeric invoice code and adding one.
 */
export function generateSequentialInvoiceCode(
  invoiceCodes: string[],
  integrationType:
    | IntegrationType.SAGE_100_CONTRACTOR
    | IntegrationType.SAGE_100_CONTRACTOR_AGAVE
    | IntegrationType.SAGE_300_CRE
    | IntegrationType.SAGE_300_CRE_AGAVE
    | IntegrationType.FOUNDATION
): string | null {
  // Determine the invoice code by adding 1 to the highest code recorded so far. Only look at
  // invoices that are pure numbers. Not all companies are consistent and some mix in code-based
  // invoices in addition to manually entering specific names
  const invoicesWithCodes = invoiceCodes
    .filter((invoiceCode) => DIGITS_REGEX.test(invoiceCode))
    .map((invoiceCode) => parseInt(invoiceCode))
  const highestInvoiceCode = _.max(invoicesWithCodes) ?? 0
  const invoiceCode = (highestInvoiceCode + 1).toFixed(0)

  // If the next invoice code would be too long for the integration to accept, return null
  switch (integrationType) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE: {
      if (invoiceCode.length > MAX_SAGE_INVOICE_CODE_LENGTH) {
        return null
      }
      break
    }
    case IntegrationType.FOUNDATION: {
      if (invoiceCode.length > MAX_FOUNDATION_INVOICE_NUMBER_LENGTH) {
        return null
      }
      break
    }
  }
  return invoiceCode
}

/**
 * Returns whether a sync payload is a pay app payload (a simple pay app sync)
 */
export function isPayAppPayload(
  type: integrationTypes.WriteSyncPayload['type']
): type is integrationTypes.WriteSyncPayloadPayApp['type'] {
  switch (type) {
    case 'payAppFoundation':
    case 'payAppSage100':
    case 'payAppTextura':
    case 'payAppGcPay':
    case 'payAppProcore':
    case 'payAppManual':
      return true
    case 'payAppLineItemsAcumatica':
    case 'payAppLineItemsSage300':
    case 'payAppLineItemsSpectrum':
    case 'payAppLineItemsVista':
    case 'payAppLineItemsSageIntacct':
    case 'payAppFoundationFileGenie':
    case 'payAppFoundationFileFsi':
    case 'payAppQuickbooks':
    case 'payAppComputerEase':
    case 'lienWaivers':
    case 'legalRequirement':
      return false
  }
}

/**
 * Returns whether a sync payload is a pay app line items payload (a pay app sync with broken down line items)
 */
export function isPayAppLineItemsPayload(
  type: integrationTypes.WriteSyncPayload['type']
): type is integrationTypes.WriteSyncPayloadPayAppLineItems['type'] {
  switch (type) {
    case 'payAppLineItemsAcumatica':
    case 'payAppLineItemsSage300':
    case 'payAppLineItemsSpectrum':
    case 'payAppLineItemsVista':
    case 'payAppLineItemsSageIntacct':
      return true
    case 'payAppFoundation':
    case 'payAppSage100':
    case 'payAppTextura':
    case 'payAppGcPay':
    case 'payAppProcore':
    case 'payAppQuickbooks':
    case 'payAppFoundationFileGenie':
    case 'payAppFoundationFileFsi':
    case 'payAppComputerEase':
    case 'payAppManual':
    case 'lienWaivers':
    case 'legalRequirement':
      return false
  }
}

/**
 * Returns whether a sync payload is a pay app file payload (file-based export)
 */
export function isPayAppFilePayload(
  type: integrationTypes.WriteSyncPayload['type']
): type is integrationTypes.WriteSyncPayloadPayApp['type'] {
  switch (type) {
    case 'payAppQuickbooks':
    case 'payAppFoundationFileGenie':
    case 'payAppFoundationFileFsi':
    case 'payAppComputerEase':
      return true
    case 'payAppFoundation':
    case 'payAppSage100':
    case 'payAppTextura':
    case 'payAppGcPay':
    case 'payAppProcore':
    case 'payAppManual':
    case 'payAppLineItemsAcumatica':
    case 'payAppLineItemsSage300':
    case 'payAppLineItemsSpectrum':
    case 'payAppLineItemsVista':
    case 'payAppLineItemsSageIntacct':
    case 'lienWaivers':
    case 'legalRequirement':
      return false
  }
}

/**
 * Returns the pay app ID of a write sync payload
 */
export function getPayAppIdFromWriteSyncPayload(
  payload: integrationTypes.WriteSyncPayload
): string | null {
  switch (payload.type) {
    case 'payAppTextura':
    case 'payAppGcPay':
    case 'payAppProcore':
    case 'payAppSage100':
    case 'payAppFoundation':
    case 'payAppQuickbooks':
    case 'payAppFoundationFileGenie':
    case 'payAppFoundationFileFsi':
    case 'payAppComputerEase':
    case 'payAppManual':
    case 'payAppLineItemsSage300':
    case 'payAppLineItemsSpectrum':
    case 'payAppLineItemsVista':
    case 'payAppLineItemsAcumatica':
    case 'payAppLineItemsSageIntacct':
      return payload.payAppId
    case 'legalRequirement':
    case 'lienWaivers':
      return null
  }
}

type WriteSyncOperation = {
  status: WriteSyncOperationStatus
  result: integrationTypes.WriteSyncResult | null
}

/**
 * Returns whether a write sync operation was completed AND successful
 */
export function isWriteSyncSuccessful(sync: WriteSyncOperation): boolean {
  if (sync.status !== WriteSyncOperationStatus.COMPLETED || !sync.result) {
    return false
  }
  return sync.result.type === 'success'
}

export type PayAppWithLastErpSync = {
  createdAt: string
  lastErpSync: WriteSyncOperation | null
}

/**
 * Returns whether a pay app is synced to its ERP with the following logic:
 * 1. If the pay app creation date is before ERP_SYNC_FIELD_INTRODUCED_AT, we consider the pay app
 *    synced to the ERP (this prevents us from flagging outstanding syncs on pay apps where ERP syncs
 *    were not recorded)
 * 2. If `payApp.lastErpSync` is null, we assume it is NOT synced to the ERP
 * 3. If the pay app's last ERP sync has any status other than completed & successful, we consider it
 *    NOT synced to the ERP
 *
 * NOTE: The caller is responsible for checking if the contract is set up with an ERP integration
 */
export function isPayAppSyncedToErp(payApp: PayAppWithLastErpSync): boolean {
  const createdAt = moment.utc(payApp.createdAt)
  const cutoff = moment.utc(ERP_SYNC_FIELD_INTRODUCED_AT)
  if (createdAt.isBefore(cutoff)) {
    return true
  }
  if (!payApp.lastErpSync) {
    return false
  }
  return isWriteSyncSuccessful(payApp.lastErpSync)
}

const ERP_SYNC_FINISHED_STATUSES = [
  WriteSyncOperationStatus.CANCELED,
  WriteSyncOperationStatus.COMPLETED,
]

/**
 * In the UI, we ultimately need to know that the pay app ERP sync status is 1 of 3 statuses:
 * 1. The pay app is synced to the ERP (successfully/completed)
 * 2. The pay app is NOT synced to the ERP (failed/never synced)
 * 3. A sync is pending
 */
export function getPayAppLastErpSyncStatus(
  payApp: PayAppWithLastErpSync
): 'synced' | 'notSynced' | 'pending' {
  if (isPayAppSyncedToErp(payApp)) {
    return 'synced'
  }
  if (!payApp.lastErpSync) {
    return 'notSynced'
  }
  const isErpSyncFinished = ERP_SYNC_FINISHED_STATUSES.includes(payApp.lastErpSync.status)
  if (!isErpSyncFinished) {
    return 'pending'
  }

  return 'notSynced'
}

/**
 * When exporting Quickbooks invoices, we generally give users the option to either include
 * retention as a separate lump sum line item on the invoice, or to deduct it from the progress line
 * items. If retention is tracked at the pay app or progress level though, or if there's a pay-app
 * level retention override, we wouldn't be able to fold it into individual line items, so we
 * require including a separate retention line item. We also require it when no retention is
 * tracked, since it doesn't make sense to show an option in that case either.
 */
export function doesQuickbooksContractRequireIncludeRetentionSeparately(
  hasRetentionOverride: boolean,
  retentionTrackingLevel: RetentionTrackingLevel
): boolean {
  return (
    hasRetentionOverride ||
    retentionTrackingLevel === RetentionTrackingLevel.PAY_APP ||
    retentionTrackingLevel === RetentionTrackingLevel.PROJECT ||
    retentionTrackingLevel === RetentionTrackingLevel.NONE
  )
}

// This should always be true because it supports all retention tracking levels. If it were ever
// switched to false, we'd need to check whether including retention is required for the contract
// and apply a default setting based on the retention level.
export const QUICKBOOKS_DEFAULT_INCLUDE_RETENTION = true
export const QUICKBOOKS_DEFAULT_COMBINE_AS_SINGLE_LINE = false
/**
 * Because Quickbooks doesn't have the concept of contracts or SOVs, we give the option to choose if
 * you want to use your SOV as is or if you want to sum everything up into one line item name. If
 * the latter, this is the name of that "item".
 */
export const QUICKBOOKS_DEFAULT_SINGLE_LINE_ITEM_DESCRIPTION = 'Siteline Billed'

/** Returns whether the integration is a file-based ERP, an api-based ERP, or neither */
export function isFileBasedIntegration(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.VISTA:
      return false
  }
}

export function supportsReleasingRetention(type: IntegrationType): boolean {
  switch (type) {
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.TEXTURA:
      return true
    case IntegrationType.ACUMATICA:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.GC_PAY:
    case IntegrationType.PROCORE:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SPECTRUM:
    case IntegrationType.TEST:
    case IntegrationType.VISTA:
    case IntegrationType.FOUNDATION:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
      return false
  }
}
