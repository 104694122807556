import { gql } from '@apollo/client'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  Link,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import {
  FormTemplateType,
  PaginatedListSortOrder,
  TemplateListSortCriteria,
  TemplateListStatusFilter,
} from 'siteline-common-all'
import {
  SitelineText,
  SitelineTooltip,
  colors,
  makeStylesFast,
  useDebouncedSearch,
  usePaginationState,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { getCurrentUser } from '../../client'
import { CompanyAutocomplete } from '../../common/components/CompanyAutocomplete'
import Page from '../../common/components/Page'
import { ListFormTemplate } from '../../common/graphql/Fragments'
import {
  FormTemplateStatus,
  FormTemplateTag,
  GetPaginatedTemplatesInput,
  useContractForAutocompleteQuery,
  useContractsForAutocompleteQuery,
  usePaginatedTemplatesQuery,
  useSitelineTeamMembersQuery,
  useTemplateBuildStatsQuery,
} from '../../common/graphql/apollo-operations'
import { getTemplateTagPrettyName } from '../../common/util/FormTemplate'
import { FormTemplateListRow } from './FormTemplateListRow'
import { ViewTemplateVersionDialog } from './ViewTemplateVersionDialog'

gql`
  query contractForAutocomplete($id: ID!) {
    contract(id: $id) {
      id
      daysBeforePayAppDue
      company {
        id
        name
      }
      project {
        id
        name
        timeZone
        metadata {
          payAppDueOnDayOfMonth
        }
      }
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  card: {
    marginTop: theme.spacing(4),
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& .shortcuts': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    '& .includeInactive': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: colors.grey50,
      },
    },
  },
  searchBar: {
    width: 300,
    margin: theme.spacing(0),
    ...theme.typography.body1,
  },
}))

export function getWaitingOnUserId(template: ListFormTemplate): string | null {
  switch (template.status) {
    case FormTemplateStatus.WAITING_ON_ORIGINAL_FILE:
      return template.owner.id
    case FormTemplateStatus.PREPARING_FOR_BUILD:
    case FormTemplateStatus.READY_FOR_BUILD:
    case FormTemplateStatus.BUILDING:
      return template.builder.id
    case FormTemplateStatus.READY_FOR_VALIDATION:
      // Template is ready for validation but does not have a validator assigned
      if (!template.validator) {
        return template.owner.id
      } else {
        return template.validator.id
      }
    case FormTemplateStatus.VALIDATED:
      return template.owner.id
    case FormTemplateStatus.COMPLETE:
    case FormTemplateStatus.DUPLICATE:
    case FormTemplateStatus.CANCELED:
      return null
  }
}

gql`
  query paginatedTemplates($input: GetPaginatedTemplatesInput!) {
    paginatedTemplates(input: $input) {
      hasNext
      cursor
      totalCount
      templates {
        id
        type
        createdAt
        userVisibleName
        status
        skippedValidation
        dueDate
        requestingCompany {
          id
          name
        }
        owner {
          id
          firstName
        }
        builder {
          id
          firstName
        }
        validator {
          id
          firstName
        }
      }
    }
  }
`

gql`
  query templateBuildStats {
    templateBuildStats {
      builders {
        user {
          id
          firstName
        }
        waitingOnCount
      }
    }
  }
`

export default function FormTemplateList() {
  const snackbar = useSitelineSnackbar()
  const [includeInactive, setIncludeInactive] = useState<boolean>(false)
  const [refreshedAt, setRefreshedAt] = useState<string>(
    moment.tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm')
  )

  const classes = useStyles()
  const theme = useTheme()
  const currentUser = getCurrentUser()
  const [viewDialogOpen, setViewDialogOpen] = useState(false)
  const [viewTemplateId, setViewTemplateId] = useState<string | undefined>(undefined)
  const { data: adminsData } = useSitelineTeamMembersQuery()

  const {
    search: contractSearch,
    debouncedSearch: contractDebouncedSearch,
    onSearch: onContractSearch,
  } = useDebouncedSearch()
  const { data: contractsData, loading: loadingContracts } = useContractsForAutocompleteQuery({
    variables: {
      input: {
        search: contractDebouncedSearch,
        limit: 10,
      },
    },
    skip: contractDebouncedSearch.length < 3,
  })

  const admins = _.orderBy(adminsData?.sitelineTeamMembers ?? [], (user) => user.firstName, ['asc'])
  const navigate = useNavigate()
  const location = useLocation()

  const search = new URLSearchParams(location.search)
  const status = search.get('status') as TemplateListStatusFilter | null
  const sortCriteria =
    (search.get('sortCriteria') as TemplateListSortCriteria | null) ??
    TemplateListSortCriteria.USER_VISIBLE_NAME
  const sortOrder =
    (search.get('sortOrder') as PaginatedListSortOrder | null) ?? PaginatedListSortOrder.ASC
  const assigneeId = search.get('assigneeId')
  const waitingOnId = search.get('waitingOnId')
  const relatedCompanyId = search.get('relatedCompanyId')
  const type = search.get('type') as FormTemplateType | null
  const tags = search.getAll('tags') as FormTemplateTag[]
  const availableTags = Object.values(FormTemplateTag).sort()
  const availableTypes = Object.values(FormTemplateType).sort()
  const associatedContractId = search.get('associatedContractId')
  const availableStatuses: TemplateListStatusFilter[] = [
    TemplateListStatusFilter.COMPLETE,
    TemplateListStatusFilter.NOT_COMPLETE,
    TemplateListStatusFilter.NOT_VALIDATED,
    TemplateListStatusFilter.READY_FOR_VALIDATION,
    TemplateListStatusFilter.VALIDATED,
  ]

  const { data: contractData } = useContractForAutocompleteQuery({
    variables: {
      id: associatedContractId ?? '',
    },
    skip: !associatedContractId,
  })

  const contractOptions = useMemo(() => {
    const autocompleted = contractsData?.paginatedContracts.contracts ?? []
    if (!contractData) {
      return autocompleted
    }
    return _.uniqBy([...autocompleted, contractData.contract], (contract) => contract.id)
  }, [contractData, contractsData?.paginatedContracts.contracts])

  const contractOptionIds = useMemo(
    () => contractOptions.map((option) => option.id),
    [contractOptions]
  )

  const getContractById = useCallback(
    (id: string) => {
      const found = contractOptions.find((option) => option.id === id)
      return found ?? null
    },
    [contractOptions]
  )

  const baseSearchParams = useMemo(() => {
    const search = new URLSearchParams(location.search)
    search.delete('assigneeId')
    search.delete('status')
    search.delete('waitingOnId')
    search.delete('relatedCompanyId')
    search.delete('type')
    search.delete('tags')
    search.delete('associatedContractId')
    return search
  }, [location.search])

  const handleClearFilters = useCallback(() => {
    const searchParams = new URLSearchParams(baseSearchParams.toString())
    // Set a dummy search param so we don't overwrite the params if reloading form templates (since
    // we have an effect that applies default filters if params are empty on initial load)
    searchParams.set('filters', 'none')
    navigate({ search: searchParams.toString() })
  }, [baseSearchParams, navigate])

  const waitingOnMeSearchParams = useMemo(() => {
    const baseParams = new URLSearchParams(baseSearchParams.toString())
    if (!currentUser) {
      return baseParams
    }
    baseParams.set('waitingOnId', currentUser.uid)
    baseParams.set('status', TemplateListStatusFilter.NOT_COMPLETE)
    return baseParams
  }, [baseSearchParams, currentUser])

  const assignedToMeSearchParams = useMemo(() => {
    const baseParams = new URLSearchParams(baseSearchParams.toString())
    if (!currentUser) {
      return baseParams
    }
    baseParams.set('assigneeId', currentUser.uid)
    baseParams.set('status', TemplateListStatusFilter.NOT_COMPLETE)
    return baseParams
  }, [baseSearchParams, currentUser])

  const input = useMemo(
    (): GetPaginatedTemplatesInput => ({
      limit: 50,
      sortCriteria,
      sortOrder,
      status,
      assigneeId,
      waitingOnId,
      relatedCompanyId,
      associatedContractId,
      tags,
      type,
      includeInactive,
    }),
    [
      includeInactive,
      sortCriteria,
      sortOrder,
      status,
      assigneeId,
      waitingOnId,
      relatedCompanyId,
      associatedContractId,
      tags,
      type,
    ]
  )

  const { data, error, loading, refetch, fetchMore } = usePaginatedTemplatesQuery({
    variables: { input },
  })

  const { data: buildStatsData } = useTemplateBuildStatsQuery()

  const templates = useMemo(() => data?.paginatedTemplates.templates ?? [], [data])
  const cursor = data?.paginatedTemplates.cursor
  const hasNext = data?.paginatedTemplates.hasNext ?? false
  const totalCount = data?.paginatedTemplates.totalCount ?? 0
  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        input: { cursor, ...input },
      },
    })
  }, [cursor, fetchMore, input])

  const { showSkeletons, showLoadingMoreRow, showColumnHeaders, showNoFilterResultPlaceholder } =
    usePaginationState({
      entities: data?.paginatedTemplates.templates ?? null,
      loading,
      hasNext,
      isSearching: false,
      isFiltering: true,
    })

  const onViewTemplate = (templateId: string) => {
    setViewTemplateId(templateId)
    setViewDialogOpen(true)
  }

  const onCloseViewTemplate = () => {
    setViewDialogOpen(false)
    setViewTemplateId(undefined)
  }

  const setSearchParam = (param: string, value: string | null) => {
    const search = new URLSearchParams(location.search)
    if (value) {
      search.set(param, value)
    } else {
      search.delete(param)
    }
    navigate({ search: search.toString() })
  }

  const setStatus = (status: TemplateListStatusFilter | null) => setSearchParam('status', status)
  const setAssigneeId = (assigneeId: string | null) => {
    const search = new URLSearchParams(location.search)
    search.delete('waitingOnId')
    if (assigneeId) {
      search.set('assigneeId', assigneeId)
    } else {
      search.delete('assigneeId')
    }
    navigate({ search: search.toString() })
  }
  const setWaitingOnId = (waitingOnId: string | null) => {
    const search = new URLSearchParams(location.search)
    search.delete('assigneeId')
    if (waitingOnId) {
      search.set('waitingOnId', waitingOnId)
    } else {
      search.delete('waitingOnId')
    }
    navigate({ search: search.toString() })
  }
  const setRelatedCompanyId = (companyId: string | null) =>
    setSearchParam('relatedCompanyId', companyId)
  const setFormType = (type: FormTemplateType | null) => setSearchParam('type', type)
  const setAssociatedContractId = (associatedContractId: string | null) =>
    setSearchParam('associatedContractId', associatedContractId)

  const setTags = (tags: FormTemplateTag[]) => {
    const search = new URLSearchParams(location.search)
    search.delete('tags')
    for (const tag of tags) {
      search.append('tags', tag)
    }
    navigate({ search: search.toString() })
  }

  const handleSort = (newSortCriteria: TemplateListSortCriteria) => {
    let newSortOrder: PaginatedListSortOrder
    if (newSortCriteria !== sortCriteria) {
      newSortOrder = PaginatedListSortOrder.ASC
    } else if (sortOrder === PaginatedListSortOrder.ASC) {
      newSortOrder = PaginatedListSortOrder.DESC
    } else {
      newSortOrder = PaginatedListSortOrder.ASC
    }
    const search = new URLSearchParams(location.search)
    search.set('sortCriteria', newSortCriteria)
    search.set('sortOrder', newSortOrder)
    navigate({ search: search.toString() })
  }

  useEffect(() => {
    if (!currentUser) {
      return
    }

    // If there are no current search params set, default to search for incomplete forms waiting
    // on the current user's action, and sort by due date
    if (location.search.length === 0) {
      navigate({ search: waitingOnMeSearchParams.toString() })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const builderStats = useMemo(
    () => [...(buildStatsData?.templateBuildStats.builders ?? [])],
    [buildStatsData?.templateBuildStats.builders]
  )

  const hasFiltersSelected = useMemo(() => {
    const currentSearchParams = new URLSearchParams(location.search)
    currentSearchParams.delete('sortCriteria')
    currentSearchParams.delete('sortOrder')
    return currentSearchParams.toString() !== ''
  }, [location.search])

  const handleRefetchForms = () => {
    snackbar.showLoading('Refetching forms')
    refetch()
      .then(() => {
        snackbar.showSuccess()
        setRefreshedAt(moment.tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm'))
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const makeSkeletonRow = useCallback(
    (index: number) => (
      <TableRow hover key={index.toString()}>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    ),
    []
  )

  return (
    <Page className={classes.root} title="Forms">
      <Container maxWidth={false}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4">Forms</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2" sx={{ marginRight: 2 }}>
              Refreshed at {refreshedAt}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginRight: 8 }}
              onClick={handleRefetchForms}
            >
              Refresh forms
            </Button>
            <Link component={RouterLink} to="/templates/create" underline="hover">
              <Button variant="contained" color="primary">
                Create a form
              </Button>
            </Link>
          </Grid>
        </Grid>
        {error && <Alert severity="error">{error.message}</Alert>}
        <Card className={classes.card}>
          <CardHeader
            title="Forms"
            subheader={
              data ? (
                <span>{totalCount} records found.</span>
              ) : (
                <Skeleton variant="text" sx={{ width: 100 }} />
              )
            }
            action={
              <div className={classes.actions}>
                <div className="shortcuts">
                  {hasFiltersSelected && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleClearFilters}
                      size="small"
                    >
                      Clear filters
                    </Button>
                  )}
                  {currentUser && (
                    <>
                      {!location.search.match(waitingOnMeSearchParams.toString()) && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate({ search: waitingOnMeSearchParams.toString() })}
                          size="small"
                        >
                          Waiting on me
                        </Button>
                      )}
                      {!location.search.match(assignedToMeSearchParams.toString()) && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate({ search: assignedToMeSearchParams.toString() })}
                          size="small"
                        >
                          Assigned to me
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <div className="includeInactive">
                  <Switch
                    checked={includeInactive}
                    onChange={(ev) => setIncludeInactive(ev.target.checked)}
                  />
                  <SitelineText
                    variant="body1"
                    endIcon={
                      <SitelineTooltip
                        title="Include forms marked CANCELED or DUPLICATE"
                        placement="bottom-end"
                      >
                        <InfoOutlinedIcon />
                      </SitelineTooltip>
                    }
                  >
                    Include canceled forms
                  </SitelineText>
                </div>
              </div>
            }
          />
          <CardContent className={classes.filters}>
            <Grid container>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Autocomplete
                      size="small"
                      disablePortal
                      options={availableStatuses}
                      value={status}
                      onChange={(event, value) => setStatus(value)}
                      sx={{ width: 180 }}
                      getOptionLabel={(filter) => {
                        switch (filter) {
                          case TemplateListStatusFilter.COMPLETE:
                            return 'Complete'
                          case TemplateListStatusFilter.NOT_COMPLETE:
                            return 'Not complete'
                          case TemplateListStatusFilter.NOT_VALIDATED:
                            return 'Not validated'
                          case TemplateListStatusFilter.READY_FOR_VALIDATION:
                            return 'Ready for validation'
                          case TemplateListStatusFilter.VALIDATED:
                            return 'Validated'
                        }
                      }}
                      renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      size="small"
                      disablePortal
                      options={admins.map((user) => user.id)}
                      value={assigneeId}
                      onChange={(event, value) => setAssigneeId(value)}
                      sx={{ width: 250 }}
                      getOptionLabel={(userId) => {
                        const user = admins.find((user) => user.id === userId)
                        return user?.email ?? ''
                      }}
                      renderInput={(params) => <TextField {...params} label="Assignee" />}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      size="small"
                      disablePortal
                      options={admins.map((user) => user.id)}
                      value={waitingOnId}
                      onChange={(event, value) => setWaitingOnId(value)}
                      sx={{ width: 250 }}
                      getOptionLabel={(userId) => {
                        const user = admins.find((user) => user.id === userId)
                        return user?.email ?? ''
                      }}
                      renderInput={(params) => <TextField {...params} label="Waiting on" />}
                    />
                  </Grid>
                  <div style={{ flexBasis: '100%', height: 0 }}></div>
                  <Grid item>
                    <CompanyAutocomplete
                      size="small"
                      companyId={relatedCompanyId}
                      setCompanyId={(companyId) => {
                        setRelatedCompanyId(companyId)
                      }}
                      sx={{ width: 250 }}
                      label="Related company"
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      sx={{ width: 250 }}
                      options={contractOptionIds}
                      loading={loadingContracts}
                      value={associatedContractId ?? null}
                      onChange={(event, value) => setAssociatedContractId(value)}
                      inputValue={contractSearch}
                      onInputChange={(event, value) => onContractSearch(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder="Project"
                        />
                      )}
                      getOptionLabel={(id) => {
                        const contract = getContractById(id)
                        if (!contract) {
                          return ''
                        }
                        return contract.project.name
                      }}
                      // Required to provide an explicit child key
                      // See https://stackoverflow.com/a/69396153
                      renderOption={(props, id) => {
                        const contract = getContractById(id)
                        if (!contract) {
                          return null
                        }
                        return (
                          <li {...props} key={id}>
                            {contract.project.name} • {contract.company.name}
                          </li>
                        )
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((id, index) => {
                          const contract = getContractById(id)
                          if (!contract) {
                            return null
                          }
                          return (
                            <Chip
                              variant="outlined"
                              size="small"
                              label={contract.project.name}
                              {...getTagProps({ index })}
                              key={id}
                            />
                          )
                        })
                      }
                    />
                  </Grid>
                  <div style={{ flexBasis: '100%', height: 0 }}></div>
                  <Grid item>
                    <Autocomplete
                      multiple
                      options={availableTags}
                      value={tags}
                      onChange={(event, value) => setTags(value)}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" variant="outlined" placeholder="Tags" />
                      )}
                      getOptionLabel={(option) => getTemplateTagPrettyName(option)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            size="small"
                            label={getTemplateTagPrettyName(option)}
                            {...getTagProps({ index })}
                            key={option}
                          />
                        ))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      options={availableTypes}
                      value={type}
                      onChange={(event, value) => setFormType(value)}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder="Form Type"
                        />
                      )}
                      getOptionLabel={(option) => getTemplateTagPrettyName(option)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            size="small"
                            label={getTemplateTagPrettyName(option)}
                            {...getTagProps({ index })}
                            key={option}
                          />
                        ))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <ResponsiveContainer width="100%" height={150}>
                  <BarChart height={40} data={builderStats}>
                    <XAxis dataKey="user.firstName" />
                    <Tooltip />
                    <Bar
                      dataKey="waitingOnCount"
                      fill={theme.palette.secondary.main}
                      onClick={(data: (typeof builderStats)[number]) => {
                        setWaitingOnId(data.user.id)
                      }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </CardContent>
          <InfiniteScroll threshold={0} loadMore={loadMore} hasMore={hasNext} useWindow={true}>
            <TableContainer>
              <Table size="small">
                {showColumnHeaders && (
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Button
                          size="small"
                          sx={{ textTransform: 'none' }}
                          style={{
                            marginLeft:
                              sortCriteria === TemplateListSortCriteria.USER_VISIBLE_NAME
                                ? -4
                                : -12,
                          }}
                          endIcon={
                            <>
                              {sortCriteria === TemplateListSortCriteria.USER_VISIBLE_NAME &&
                                sortOrder === PaginatedListSortOrder.ASC && <ArrowDownwardIcon />}
                              {sortCriteria === TemplateListSortCriteria.USER_VISIBLE_NAME &&
                                sortOrder === PaginatedListSortOrder.DESC && <ArrowUpwardIcon />}
                            </>
                          }
                          onClick={() => handleSort(TemplateListSortCriteria.USER_VISIBLE_NAME)}
                        >
                          Name
                        </Button>
                      </TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Requested by</TableCell>
                      <TableCell>Owner</TableCell>
                      <TableCell>Builder</TableCell>
                      <TableCell>Validator</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          sx={{ textTransform: 'none' }}
                          startIcon={
                            <>
                              {sortCriteria === TemplateListSortCriteria.CREATED_AT &&
                                sortOrder === PaginatedListSortOrder.ASC && <ArrowDownwardIcon />}
                              {sortCriteria === TemplateListSortCriteria.CREATED_AT &&
                                sortOrder === PaginatedListSortOrder.DESC && <ArrowUpwardIcon />}
                            </>
                          }
                          onClick={() => handleSort(TemplateListSortCriteria.CREATED_AT)}
                        >
                          Created
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          sx={{ textTransform: 'none' }}
                          style={{
                            marginLeft:
                              sortCriteria === TemplateListSortCriteria.DUE_DATE ? -8 : -16,
                          }}
                          endIcon={
                            <>
                              {sortCriteria === TemplateListSortCriteria.DUE_DATE &&
                                sortOrder === PaginatedListSortOrder.ASC && <ArrowDownwardIcon />}
                              {sortCriteria === TemplateListSortCriteria.DUE_DATE &&
                                sortOrder === PaginatedListSortOrder.DESC && <ArrowUpwardIcon />}
                            </>
                          }
                          onClick={() => handleSort(TemplateListSortCriteria.DUE_DATE)}
                        >
                          Due
                        </Button>
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>Latest version</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {templates.map((template) => (
                    <FormTemplateListRow
                      formTemplate={template}
                      onViewTemplate={() => onViewTemplate(template.id)}
                      key={template.id}
                    />
                  ))}
                  {showSkeletons && _.times(8, (index) => makeSkeletonRow(index))}
                  {showNoFilterResultPlaceholder && (
                    <TableRow>
                      <TableCell colSpan={7}>No template matches these filters.</TableCell>
                    </TableRow>
                  )}
                  {showLoadingMoreRow && makeSkeletonRow(0)}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
          {viewTemplateId && (
            <ViewTemplateVersionDialog
              open={viewDialogOpen}
              onClose={onCloseViewTemplate}
              templateId={viewTemplateId}
            />
          )}
        </Card>
      </Container>
    </Page>
  )
}
