import { Close } from '@mui/icons-material'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MONTH_FORMAT } from 'siteline-common-all'
import {
  useChangeOrderLogUseQuery,
  useComplianceUseQuery,
  useLienWaiversRequestedQuery,
  usePayAppsSubmittedMonthQuery,
} from '../../common/graphql/apollo-operations'
import { ChangeOrderLogChart } from './ChangeOrderLogChart'
import { ComplianceUsageChart } from './ComplianceUsageChart'
import { SubmittedPayAppsChart } from './SubmittedPayAppsChart'
import { VendorLienWaiverChart } from './VendorLienWaiverChart'

/**
 * Allow sorting in 3 ways:
 * companyName: Alphabetical by company name, ascending
 * customerAge: Ordered by company creation date, with newest companies first
 * usageAsc: Ordered by feature usage, with least used first
 * usageDesc: Ordered by feature usage, with most used first
 */
export type FeatureUsageSortBy = 'companyName' | 'customerAge' | 'usageAsc' | 'usageDesc'
/** The number of companies shown on each chart at once */
export const NUM_COMPANIES_PER_FEATURE_CHART_PAGE = 5
export type CompanyForFeatureUsage = {
  companyId: string
  companyName: string
  companyCreatedAt: number
  sitelinePointOfContactId: string | null
  sitelinePointOfContactName: string | null
}

interface FeatureUsageDashboardProps {
  isSmallScreen: boolean
}

/** Dashboard for tracking number of abandoned pay apps per customer */
export function FeatureUsageDashboard({ isSmallScreen }: FeatureUsageDashboardProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const { data: payAppData } = usePayAppsSubmittedMonthQuery({
    variables: { input: { month: moment.tz(moment.tz.guess()).format(MONTH_FORMAT) } },
  })
  const { data: complianceData } = useComplianceUseQuery()
  const { data: changeOrderLogData } = useChangeOrderLogUseQuery()
  const { data: vendorLienWaiverData } = useLienWaiversRequestedQuery()

  const sortBy = useMemo(() => {
    return queryString.parse(location.search).sortBy as FeatureUsageSortBy | null
  }, [location.search])
  const handleSortByChange = useCallback(
    (sortBy: FeatureUsageSortBy, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      search.set('sortBy', sortBy)
      navigate({ search: search.toString() }, { replace })
    },
    [location.search, navigate]
  )
  // Default to sorting by number abandoned
  useEffect(() => {
    if (!sortBy) {
      handleSortByChange('companyName', true)
    }
  }, [handleSortByChange, sortBy])

  const accountOwner = useMemo(() => {
    return queryString.parse(location.search).accountOwner as string | null
  }, [location.search])
  const handleAccountOwnerChange = useCallback(
    (userId: string | null, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      if (userId) {
        search.set('accountOwner', userId)
      } else {
        search.delete('accountOwner')
      }
      navigate({ search: search.toString() }, { replace })
    },
    [location.search, navigate]
  )

  const filterCompanyIds = useMemo(() => {
    const { companies } = queryString.parse(location.search)
    let companyIds: string[] = []
    if (typeof companies === 'string') {
      companyIds = [companies]
    } else if (Array.isArray(companies)) {
      companyIds = _.compact(companies)
    }
    return _.chain(companyIds)
      .flatMap((companyId) => companyId.split(','))
      .map(decodeURIComponent)
      .value()
  }, [location.search])
  const handleFilterCompanyIds = useCallback(
    (companyIds: string[]) => {
      const search = new URLSearchParams(location.search)
      if (companyIds.length > 0) {
        search.set('companies', companyIds.join(','))
      } else {
        search.delete('companies')
      }
      navigate({ search: search.toString() })
    },
    [location.search, navigate]
  )

  const sortedCompanies = useMemo(
    () =>
      _.chain([
        ...(complianceData?.complianceUse ?? []),
        ...(changeOrderLogData?.changeOrderLogUse ?? []),
        ...(vendorLienWaiverData?.lienWaiversRequested ?? []),
        ...(payAppData?.payAppsSubmittedMonth ?? []),
      ])
        .map(
          ({
            companyName,
            companyId,
            companyCreatedAt,
            sitelinePointOfContactId,
            sitelinePointOfContactName,
          }) => ({
            companyName,
            companyId,
            companyCreatedAt: moment.utc(companyCreatedAt).unix(),
            sitelinePointOfContactId,
            sitelinePointOfContactName,
          })
        )
        .uniqBy(({ companyId }) => companyId)
        .orderBy(({ companyName }) => companyName)
        .value(),
    [
      changeOrderLogData?.changeOrderLogUse,
      complianceData?.complianceUse,
      payAppData?.payAppsSubmittedMonth,
      vendorLienWaiverData?.lienWaiversRequested,
    ]
  )

  const accountOwners = useMemo(
    () =>
      _.chain(sortedCompanies)
        .map(({ sitelinePointOfContactId, sitelinePointOfContactName }) => {
          return {
            id: sitelinePointOfContactId,
            name: sitelinePointOfContactName,
          }
        })
        .compact()
        .filter(
          (pointOfContact): pointOfContact is { id: string; name: string } =>
            _.isString(pointOfContact.id) && _.isString(pointOfContact.name)
        )
        .uniqBy(({ name }) => name)
        .sortBy(({ name }) => name)
        .value(),
    [sortedCompanies]
  )

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 16,
          margin: '32px 24px',
        }}
      >
        <FormControl>
          <InputLabel id="sortBy">Sort by</InputLabel>
          <Select
            // Needed to trigger a re-mount when the sortBy value is initially set, so the Select
            // component updates with the correct value
            key={sortBy}
            labelId="sortBy"
            value={sortBy}
            label="Sort by"
            onChange={(ev) => {
              handleSortByChange(ev.target.value as FeatureUsageSortBy)
            }}
          >
            <MenuItem value="companyName">Company name</MenuItem>
            <MenuItem value="customerAge">Customer age</MenuItem>
            <MenuItem value="usageAsc">Feature usage (asc)</MenuItem>
            <MenuItem value="usageDesc">Feature usage (desc)</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="companySelect">
            {filterCompanyIds.length > 0 ? 'Companies' : 'Filter by company'}
          </InputLabel>
          <Select
            style={{ width: 300 }}
            labelId="companySelect"
            value={filterCompanyIds}
            label={filterCompanyIds.length > 0 ? 'Companies' : 'Filter by company'}
            onChange={(ev) => {
              handleFilterCompanyIds(ev.target.value as string[])
            }}
            multiple
            renderValue={() => `${filterCompanyIds.length} selected`}
            endAdornment={
              filterCompanyIds.length > 0 ? (
                <IconButton onClick={() => handleFilterCompanyIds([])} sx={{ marginRight: 1.5 }}>
                  <Close fontSize="small" />
                </IconButton>
              ) : undefined
            }
          >
            {sortedCompanies.map(({ companyName, companyId }) => (
              <MenuItem key={companyId} value={companyId}>
                {companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="accountOwner">Account owner</InputLabel>
          <Select
            key={accountOwner}
            style={{ minWidth: 150 }}
            labelId="accountOwner"
            value={accountOwner ?? 'All'}
            label="Account owner"
            onChange={(ev) => {
              handleAccountOwnerChange(ev.target.value as string)
            }}
            endAdornment={
              accountOwner ? (
                <IconButton
                  onClick={() => handleAccountOwnerChange(null)}
                  sx={{ marginRight: 1.5 }}
                >
                  <Close fontSize="small" />
                </IconButton>
              ) : undefined
            }
          >
            <MenuItem value="All">All</MenuItem>
            {accountOwners.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {sortBy && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SubmittedPayAppsChart
              data={payAppData?.payAppsSubmittedMonth}
              companies={sortedCompanies}
              filterCompanyIds={filterCompanyIds}
              accountOwner={accountOwner}
              sortBy={sortBy}
              isSmallScreen={isSmallScreen}
            />
          </Grid>
          <Grid item xs={6}>
            <ComplianceUsageChart
              data={complianceData?.complianceUse}
              companies={sortedCompanies}
              filterCompanyIds={filterCompanyIds}
              accountOwner={accountOwner}
              sortBy={sortBy}
              isSmallScreen={isSmallScreen}
            />
          </Grid>
          <Grid item xs={6}>
            <ChangeOrderLogChart
              data={changeOrderLogData?.changeOrderLogUse}
              companies={sortedCompanies}
              filterCompanyIds={filterCompanyIds}
              accountOwner={accountOwner}
              sortBy={sortBy}
              isSmallScreen={isSmallScreen}
            />
          </Grid>
          <Grid item xs={6}>
            <VendorLienWaiverChart
              data={vendorLienWaiverData?.lienWaiversRequested}
              companies={sortedCompanies}
              filterCompanyIds={filterCompanyIds}
              accountOwner={accountOwner}
              sortBy={sortBy}
              isSmallScreen={isSmallScreen}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}
